<template>
  <input
    class="block w-auto text-sm h-8 py-0 border-gray-300 rounded-md shadow-sm focus:border-taxis-orange focus:ring focus:ring-taxis-orange/25 focus:ring-opacity-50"
    :type="type"
    :value="value"
    @input="input"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "HtcInputField",
  props: {
    type: {
      type: String,
      required: true,
      default: "text",
    },
    value: {
      required: true,
      default: "",
    },
  },
  emits: ["update:value"],
  methods: {
    input(e) {
      this.$emit("update:value", e.target.value ?? "");
    },
  },
};
</script>
