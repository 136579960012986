import store from "@/store";
import { MsgService } from "../../../backend/src/ws/msgtypes/service";

export default class Service {
  static async handle(msg: MsgService) {
    if (msg.type !== "Service") return;

    if (msg.action === "Current") {
      store.commit("addService", msg.serviceTaxi);
    }

    if (msg.action === "SameStation") {
      store.commit("addServiceSameStation", msg.serviceTaxi);
    }

    if (msg.action === "Reassign") {
      store.commit("addReassignment", msg.serviceTaxi);
    }

    if (msg.action === "Deleted") {
      store.commit("deleteService", msg.serviceTaxi);
    }
  }
}
