<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="create" :capitalize="true" />
          servei
        </template>

        <template v-else> Editar servei </template>
      </h1>

      <div class="mt-10">
        <label>
          <div>
            Nom i llinatges:
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.name"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>
            <translation value="reservation.room" />
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.room"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>
            Telèfon: <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.phone"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>Adreça de correu electrònic:</div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.email"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>
            Nombre de persones (màxim 4):
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <htc-input-field
            type="number"
            min="1"
            max="4"
            v-model:value="form.people"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <div class="my-6"></div>
        <label v-if="original?.reserva">
          <div>
            Data i hora:
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <htc-input-field
            type="datetime-local"
            min="1"
            max="4"
            v-model:value="form.pickupAt"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>
            Lloc de recollida:
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <span class="py-1 px-3 text-sm border rounded-md block bg-gray-100">
            {{ form.pickupName }}
          </span>
        </label>

        <label>
          <div>
            <translation value="reservation.infopickupzone" />
          </div>
          <htc-input-field
            type="text"
            maxlength="1024"
            v-model:value="form.pickupInfo"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>
            Lloc de destinació:
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <span class="py-1 px-3 text-sm border rounded-md block bg-gray-100">
            {{ form.dropoffName }}
          </span>
        </label>

        <label>
          <div>
            <translation value="reservation.infodropoffzone" />
          </div>
          <htc-input-field
            type="text"
            maxlength="1024"
            v-model:value="form.dropoffInfo"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>

        <label>
          <div>Comentaris:</div>
          <htc-input-field
            type="text"
            maxlength="2048"
            v-model:value="form.message"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
          />
        </label>
      </div>

      <h2 class="text-xl mt-4 mb-2 font-medium">
        <translation value="reservation.services" />
      </h2>

      <div class="mt-2 mb-2">
        <label>
          <input
            type="checkbox"
            id="priority"
            :value="form.priority"
            v-model="form.priority"
            class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
          <span class="ml-2">
            <translation value="reservation.priority" />
          </span>
        </label>
      </div>

      <div v-if="form.priority">
        <div><translation value="reservation.priorityinput" />:</div>
        <htc-input-field
          type="text"
          maxlength="2048"
          v-model:value="form.priorityInfo"
          class="w-full disabled:bg-gray-200"
        />
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="save" :capitalize="true" />
          <translation v-else value="create" :capitalize="true" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import moment from "moment";
import { api } from "@/boot/axios";

export default {
  name: "ServicesEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      editMode: false,

      original: null,
      form: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loading = true;
      this.loadInfo();
    }
  },
  methods: {
    loadInfo() {
      api
        .get("/services/" + this.id)
        .then((response) => {
          this.original = { ...response.data };
          this.form = { ...response.data };
          if (this.form.pickupAt) {
            this.form.pickupAt = moment(this.form.pickupAt).format(
              "YYYY-MM-DDTHH:mm",
            );
          }
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "services-index" });
        });
    },

    submit() {
      if (this.editMode) {
        api
          .put("/services/" + this.id, this.form)
          .then(() => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "services-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        api
          .post("/services", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "services-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
  },
};
</script>
