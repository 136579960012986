<template>
  <div class="hidden">
    <div ref="marker">
      <inline-svg
        v-if="!service.cantado"
        :src="require('@/assets/man.svg')"
        class="w-8 h-9 fill-current"
        :class="personColor"
      />
      <img
        v-else
        src="@/assets/speakerphone.png"
        alt="cantado"
        class="w-7 h-7"
      />
    </div>

    <div ref="popup" class="w-44 min-w-min space-y-2 font-sans">
      <h2 class="text-gray-800 text-sm font-bold uppercase">
        <!-- {{ service.full_name }} -->
      </h2>

      <div class="space-y-1">
        <div v-if="isFuturePickup">
          <div>Data i hora de recollida</div>
          <div class="text-sm font-semibold">
            {{ formattedDatetime() || "-" }}
          </div>
          <div class="-mt-1 text-sm">
            {{ formattedTimeago() || "-" }}
          </div>
        </div>

        <div v-else>
          <div>Esperant des de fa</div>
          <div class="text-sm font-semibold">
            {{ timeSinceService }}
          </div>
        </div>

        <div>
          <div>Taxi assignat</div>
          <div class="text-sm font-semibold">
            {{ assignedTaxi }}
          </div>
        </div>

        <div>
          <div>Lloc de recollida</div>
          <div class="text-sm font-semibold">
            {{ service.pickupName }}
          </div>
        </div>

        <div v-if="service.name">
          <div>Nom del client</div>
          <div class="text-sm font-semibold">
            {{ service.name }}
          </div>
        </div>

        <div v-if="service.phone">
          <div>Número de mòbil</div>
          <div class="text-sm font-semibold">
            {{ service.phone }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import InlineSvg from "vue-inline-svg";
import moment from "moment";
import { now } from "lodash";

export default {
  name: "MapPerson",
  components: {
    InlineSvg,
  },
  props: ["map", "service", "filters", "serviceTaxi"],
  data() {
    return {
      marker: null,
      timeSinceService: null,

      intervals: {
        timeSinceService: null,
      },
    };
  },
  computed: {
    personColor() {
      if (this.isInProgess) {
        return "text-blue-700";
      }

      if (this.isFuturePickup) {
        return "text-gray-800";
      }

      if (this.isAssigned) {
        return "text-green-700";
      }

      return "text-red-600";
    },
    assignedTaxi() {
      return this.serviceTaxi?.assignment?.taxi?.code || "?";
    },
    isInProgess() {
      if (this.serviceTaxi?.assignment) {
        if (this.serviceTaxi.assignment.status !== "assigned") {
          return true;
        }
      }

      return false;
    },

    isAssigned() {
      return this.serviceTaxi?.assignment?.assignedAt !== null;
    },

    isFuturePickup() {
      if (this.service.pickupAt) {
        if (moment(this.service.pickupAt).valueOf() > now()) {
          return true;
        }
      }

      return false;
    },

    show() {
      if (this.filters.services) {
        if (this.filters.services.servicesFuture) {
          if (this.isFuturePickup) {
            return true;
          }
        }

        if (this.filters.services.servicesWaiting) {
          if (!this.isFuturePickup && !this.isInProgess) {
            return true;
          }
        }

        if (this.filters.services.servicesInProgress) {
          if (this.isInProgess) {
            return true;
          }
        }
      }

      return false;
    },
  },
  created() {
    this.intervals.timeSinceService = setInterval(
      () => this.updateTimeSinceService(),
      1000,
    );
  },
  mounted() {
    this.draw();
  },
  beforeUnmount() {
    this.marker?.remove();
    clearInterval(this.intervals.timeSinceService);
  },
  watch: {
    show(val) {
      if (val) {
        this.draw();
      } else {
        this.marker?.remove();
        this.marker = null;
      }
    },
  },
  methods: {
    draw() {
      if (this.marker) return;
      if (!this.show) return;
      if (!this.service.pickupLat || !this.service.pickupLng) return;

      const myIcon = L.divIcon({
        className: "",
        html: this.$refs.marker,
        iconAnchor: [16, 18],
        popupAnchor: [0, -20],
      });

      const marker = L.marker(
        [this.service.pickupLat, this.service.pickupLng],
        {
          icon: myIcon,
        },
      );

      marker.bindPopup(this.$refs.popup);

      marker.addTo(this.map);

      this.marker = marker;
    },

    formattedDatetime() {
      return this.formatTimestamp(
        this.service.pickupAt || this.service.createdAt,
      );
    },

    formattedTimeago() {
      return this.timeago(this.service.pickupAt || this.service.createdAt);
    },

    updateTimeSinceService() {
      if (!this.service) return;

      let timestamp = this.service.pickupAt || this.service.createdAt;

      let diff = moment().unix() - moment(timestamp).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      this.timeSinceService =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },
  },
};
</script>
