<template>
  <div class="space-y-4 min-w-min">
    <Datatable
      url="/services"
      :columns="columns"
      :autorefresh="true"
      :initial-sort="{ col: 'service_number', dir: 1 }"
      :pageSize="100"
      :actions-enabled="true"
      :viewable="true"
      detail-page="services-show"
      :removable="true"
      :filterEvent="openFilterModal"
      :filters="filters"
    >
      <template #actions="data">
        <button v-if="data.row.especial" @click="especials(data.row)">
          <PlusCircleIcon class="h-5 w-5 text-blue-500" />
        </button>
      </template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/Datatable/Datatable";
import Modal from "@/modals/Modal";
import SpecialServices from "./SpecialServices";
import ServicesFilter from "./ServicesFilter";
import PriorityInfo from "./PriorityInfo.vue";
import PositionMap from "./PositionMap.vue";
import { PlusCircleIcon } from "@heroicons/vue/outline";

export default {
  name: "ServicesTable",
  components: {
    Datatable,
    PlusCircleIcon,
  },
  data() {
    return {
      search: "",
      filters: null,
      filterModalValues: null,
      columns: [
        {
          key: "service_number",
          label: "services.number",
        },
        {
          key: "status",
          label: "status.status",
          customRender: (value) => {
            if (value.droppedoffAt) {
              return "done";
            }

            if (value.serviceTaxi) {
              switch (value.serviceTaxi.status) {
                case "preassigned":
                case "assigned":
                case "accepted":
                case "reassigned":
                  return value.serviceTaxi.status;
                case "pickedup":
                  return "doing";
              }
            }

            return "waiting";
          },
          translate: true,
          sortable: false,
        },
        {
          key: "createdAt",
          label: "services.date",
          customRender: (value) => {
            return this.formatDate(value.createdAt);
          },
        },
        {
          key: "createdAt",
          label: "services.time",
          customRender: (value) => {
            return this.formatTimeShort(value.createdAt);
          },
        },
        {
          key: "droppedoffAt",
          label: "services.timeEnd",
          customRender: (value) => {
            return this.formatTimeShort(value.droppedoffAt);
          },
        },
        {
          key: "pickupName",
          label: "form.pickup",
        },
        {
          key: "dropoffName",
          label: "form.dropoff",
        },
        {
          key: "pickupLat",
          label: "services.position",
          customRender: (value) => {
            return `[${value.pickupLat}, ${value.pickupLng}]`;
          },
          clickEvent: (value) => {
            Modal.open(PositionMap, [value.pickupLat, value.pickupLng]);
          },
          customClass: "font-semibold cursor-pointer",
        },
        {
          key: "taxi.code",
          label: "generic.taxi",
        },
        {
          key: "serviceType",
          label: "generic.tipus",
          customRender: (value) => {
            let serviceTypes = [
              "services.central",
              "services.cantado",
              "services.live",
            ];
            return serviceTypes[value.serviceType];
          },
          translate: true,
        },
        {
          key: "name",
          label: "form.name",
        },
        {
          key: "room",
          label: "reservation.room",
        },
        {
          key: "createdByUser.clients.code",
          label: "client.code",
        },
        {
          key: "priority",
          label: "reservation.priority",
          customRender: (value) => {
            return value.priority ? "Sí" : "No";
          },
          translate: true,
          clickEvent: (value) => {
            value.priority ? Modal.open(PriorityInfo, value) : null;
          },
        },
        {
          key: "createdByUser.name",
          label: "createdby",
        },
      ],
    };
  },
  methods: {
    especials(row) {
      Modal.open(SpecialServices, row);
    },
    openFilterModal(row) {
      Modal.open(ServicesFilter, {
        row: row,
        modalValues: this.filterModalValues,
      }).then((data) => {
        this.filters = data.filters;
        this.filterModalValues = data.modalValues;
      });
    },
  },
};
</script>
