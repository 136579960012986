<template>
  <client-layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-4xl">
      <div class="flex flex-col justify-start items-center gap-2">
        <h1 class="text-center text-2xl">
          <translation value="generic.service" />:
          {{ formatTimestamp(service.createdAt, false) }}
        </h1>

        <div
          class="px-4 rounded-xl text-gray-700 uppercase"
          :class="{
            'bg-green-400': state === 'done',
            'bg-blue-400': state === 'doing',
            'bg-red-400': state === 'waiting',
          }"
        >
          <template v-if="state === 'done'">
            <translation value="services.ended" />
          </template>

          <template v-else-if="state === 'doing'">
            <translation value="services.in-progress" />
          </template>

          <template v-else-if="state === 'waiting'">
            <translation value="services.pending" />
          </template>
        </div>
      </div>

      <div class="mt-10 space-y-2">
        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="services.date" />
          </div>
          <div>{{ formatDateLong(service.createdAt) }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="services.time" />
          </div>
          <div>{{ formatTimeShort(service.createdAt) }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">Cantado</div>
          <div>{{ service.cantado ? "Sí" : "No" }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.pickup" />
          </div>
          <div>{{ service.pickupName }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.dropoff" />
          </div>
          <div>{{ service.dropoffName }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.pplqty" />
          </div>
          <div>{{ service.people }}</div>
        </div>

        <div v-if="service.message">
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.message" />
          </div>
          <div>{{ service.message }}</div>
        </div>

        <div class="pt-4 text-sm uppercase text-gray-700 font-semibold">
          <translation value="generic.client" />
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.name" />
          </div>
          <div>{{ service.name }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.phone" />
          </div>
          <div>{{ service.phone }}</div>
        </div>

        <div v-if="service.email">
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.email" />
          </div>
          <div>{{ service.email }}</div>
        </div>

        <template v-if="state !== 'waiting'">
          <div class="pt-4 text-sm uppercase text-gray-700 font-semibold">
            <translation value="txt.technical-details" />
          </div>

          <div v-if="service.assignedAt">
            <div class="text-xs uppercase text-gray-500">
              <translation value="services.assignedAt" />
            </div>
            <div>
              {{ formatTimestamp(service.assignedAt, false) }}
            </div>
          </div>

          <div v-if="diff1" class="text-gray-600">
            {{ diff1 }}
          </div>

          <div v-if="service.pickedupAt">
            <div class="text-xs uppercase text-gray-500">
              <translation value="services.pickedupAt" />
            </div>
            <div>{{ formatTimestamp(service.pickedupAt, false) }}</div>
          </div>

          <div v-if="diff2" class="text-gray-600">
            {{ diff2 }}
          </div>

          <div v-if="service.droppedoffAt">
            <div class="text-xs uppercase text-gray-500">
              <translation value="services.droppedoffAt" />
            </div>
            <div>{{ formatTimestamp(service.droppedoffAt, false) }}</div>
          </div>
        </template>
      </div>
    </div>
  </client-layout-main>
</template>

<script>
import ClientLayoutMain from "../layout/ClientLayoutMain.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";
import moment from "moment";

export default {
  name: "ServicesShow",
  components: {
    ClientLayoutMain,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      service: {},
    };
  },
  computed: {
    state() {
      if (this.service) {
        if (this.service.droppedoffAt) {
          return "done";
        }

        // if (this.service.assignationState === "accepted") {
        //   return "doing";
        // }

        return "waiting";
      }

      return null;
    },

    diff1() {
      if (!this.service.assignedAt) return null;
      if (!this.service.pickedupAt) return null;

      const diff = moment(this.service.pickedupAt).diff(
        this.service.assignedAt,
      );

      return Math.round(moment.duration(diff).asMinutes()) + "min";
    },

    diff2() {
      if (!this.service.pickedupAt) return null;
      if (!this.service.droppedoffAt) return null;

      const diff = moment(this.service.droppedoffAt).diff(
        this.service.pickedupAt,
      );

      return Math.round(moment.duration(diff).asMinutes()) + "min";
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.loading = true;
      this.loadInfo();
    }
  },
  methods: {
    loadInfo() {
      api
        .get("/services/" + this.id)
        .then((response) => {
          this.service = response.data || {};
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "services-index" });
        });
    },
  },
};
</script>
