<template>
  <div class="hidden"></div>
</template>

<script>
import L from "leaflet";

export default {
  name: "MapRoute",
  props: ["map", "serviceTaxi", "route", "filters"],
  data() {
    return {
      polyline: null,
      color: null,
    };
  },
  computed: {
    show() {
      if (this.filters.routes) {
        return true;
      }

      return false;
    },
  },
  watch: {
    "route.coordinates"(coordinates) {
      if (coordinates) {
        this.polyline?.setLatLngs(coordinates);
      }
    },

    "serviceTaxi.assignment": {
      deep: true,
      immediate: true,
      handler(assignment) {
        if (!assignment) return;

        if (assignment.status === "pickedup") {
          this.color = "#00cd06";
        } else if (assignment.status === "accepted") {
          this.color = "#ff6a00";
        } else {
          this.color = "gray";
        }

        this.polyline?.setStyle({
          color: this.color,
        });
      },
    },

    show(val) {
      if (val) {
        this.draw();
      } else {
        this.polyline?.remove();
        this.polyline = null;
      }
    },
  },
  mounted() {
    this.draw();
  },
  beforeUnmount() {
    this.polyline?.remove();
  },
  methods: {
    async draw() {
      if (this.polyline) return;

      const polyline = L.polyline(this.route.coordinates, {
        color: this.color ?? "red",
      });

      polyline.addTo(this.map);

      this.polyline = polyline;
    },
  },
};
</script>
