<template>
  <layout-driver :hideHeader="true">
    <router-link
      :to="{ name: 'driver-main' }"
      class="absolute z-20 top-3 left-3 pl-6 pr-8 py-6 text-2xl flex items-center gap-1 bg-taxis-blue rounded-md shadow-md"
      replace
    >
      <ChevronLeftIcon class="w-10 h-10" />
      <translation value="nav.back" />
    </router-link>

    <div size="lg">
      <div id="mapPicker" style="z-index: 1"></div>
      <div class="w-full h-full text-center mt-2">
        <button
          v-if="marker && !disabled"
          class="w-full h-full items-center font-semibold px-4 py-2 bg-green-700 rounded-md text-6xl"
          @click="doManual"
        >
          <translation value="services.accept" />
        </button>
      </div>
    </div>
  </layout-driver>
</template>

<script>
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import { mapGetters, mapState } from "vuex";
import LayoutDriver from "../../LayoutDriver.vue";
import MapComponent from "@/components/Map/MapComponent.vue";
import Translation from "@/translations/Translation.vue";
import L from "leaflet";
import Manual from "@/ws/Manual";
import Modal from "@/modals/Modal";
import Connection from "@/ws/Connection";
import Translate from "@/translations/Translate";

export default {
  name: "DriverMap",
  components: {
    ChevronLeftIcon,
    LayoutDriver,
    MapComponent,
    Translation,
    Modal,
  },
  data() {
    return {
      coords: [],
      zoom: 8,
      showMap: true,
      lat: 39.6137,
      lon: 2.9787,
      map: null,
      marker: null,
      disabled: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),
    ...mapGetters(["isOffline"]),

    taxiState() {
      return !this.isOffline && this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },
  },
  methods: {
    addMarker(event) {
      this.lat = event?.latlng?.lat;
      this.lon = event?.latlng?.lng;
      if (this.marker) this.map.removeLayer(this.marker);
      this.marker = L.marker(event.latlng, { draggable: true }).addTo(this.map);
    },

    async doManual() {
      if (this.disabled) return;
      this.disabled = true;
      console.log(this.taxi);
      if (!this.taxi) return;
      await Manual.create(this.taxi.id, null, null, this.lat, this.lon);
      await this.manualServiceState();
      this.$router.replace({ name: "driver-main" });
    },

    async manualServiceState() {
      this.taxi.state = "online";
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },
  },

  mounted() {
    this.map = L.map("mapPicker").setView([this.lat, this.lon], this.zoom);

    // Añadir la capa de OpenStreetMap
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      maxZoom: 20,
    }).addTo(this.map);

    this.map.on("click", (e) => {
      this.addMarker(e);
    });
  },
};
</script>
<style>
#mapPicker {
  height: 500px;
  width: auto;
  margin-top: 60px;
  margin-bottom: 20px;
}
</style>
