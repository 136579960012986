<template>
  <modals />

  <div
    class="w-screen h-screen flex flex-col overflow-auto overflow-x-hidden bg-black"
  >
    <div v-if="!hideHeader" class="px-1 py-1 flex bg-black">
      <router-link
        :to="{ name: 'driver-main' }"
        class="top-3 left-3 pl-6 pr-8 py-6 text-2xl flex items-center gap-1 bg-taxis-blue rounded-md shadow-md"
        replace
      >
        <ChevronLeftIcon class="w-10 h-10" />
        <translation value="nav.back" />
      </router-link>
    </div>

    <div class="grow overflow-auto relative">
      <slot />
    </div>
  </div>

  <div
    v-if="isOffline"
    class="fixed z-50 bottom-0 w-full p-1 text-center bg-red-500 text-white"
  >
    <translation value="state.offline" />
  </div>

  <service-notification-button />
</template>

<script>
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import Modals from "@/modals/Modals.vue";
import { mapGetters } from "vuex";
import Translation from "@/translations/Translation.vue";
import ServiceNotificationButton from "@/views/Driver/Main/components/ServiceNotificationButton";

export default {
  name: "LayoutDriver",
  components: {
    ChevronLeftIcon,
    Modals,
    Translation,
    ServiceNotificationButton,
  },
  props: ["hideHeader"],
  computed: {
    ...mapGetters(["isOffline"]),
  },
};
</script>
