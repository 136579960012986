<template>
  <div class="space-y-4 min-w-min">
    <Datatable
      url="/status?type=log"
      :columns="columns"
      :autorefresh="true"
      :initial-sort="{ col: 'createdAt', dir: -1 }"
      :pageSize="50"
      :filterEvent="openFilterModal"
      :filters="filters"
    />
  </div>
</template>

<script>
import Datatable from "@/components/Datatable/Datatable";
import Modal from "@/modals/Modal";
import StatusLogFilter from "./StatusLogFilter.vue";
import moment from "moment";

export default {
  name: "StatusLogTable",
  components: {
    Datatable,
  },
  data() {
    return {
      search: "",
      filters: {
        createdAt: {
          $gte: moment().hour(0).minute(0).second(0).toDate(),
          $lte: moment().hour(23).minute(59).second(59).toDate(),
        },
      },
      filterModalValues: {
        createdAt: {
          $gte: moment().hour(0).minute(0).second(0).toDate(),
          $lte: moment().hour(23).minute(59).second(59).toDate(),
        },
      },
      columns: [
        {
          key: "serviceNumber",
          label: "services.number",
        },
        {
          key: "createdAt",
          label: "services.datetime",
          type: "datetime",
        },
        {
          key: "taxiCode",
          label: "status.taxi",
        },
        {
          key: "message",
          label: "status.message",
        },
      ],
    };
  },
  methods: {
    openFilterModal(row) {
      console.log(">> HOLA");
      Modal.open(StatusLogFilter, {
        row: row,
        modalValues: this.filterModalValues,
      }).then((data) => {
        this.filters = data.filters;
        this.filterModalValues = data.modalValues;
      });
    },
  },
};
</script>
