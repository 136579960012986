import boot from "./boot";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast, { POSITION } from "vue-toastification";
import { FocusTrap } from "focus-trap-vue";
import moment from "moment";
import "boxicons";
import "boxicons/css/boxicons.min.css";
import "vue-toastification/dist/index.css";
import "./tailwind.css";
import BoxIcon from "@/components/BoxIcon";
import "leaflet/dist/leaflet.css";

window.moment = moment;

const app = createApp(App);

app.config.devtools = true;

boot(app);

app.use(store).use(router);

app.use(Toast, {
  position: POSITION.BOTTOM_LEFT,
});

app.component("FocusTrap", FocusTrap);
app.component("BoxIcon", BoxIcon);

app.mixin({
  methods: {
    formatNumber: (number, decimals) => {
      let { lang } = store.state;
      lang = lang == "en" ? "en" : "ca";

      return new Intl.NumberFormat(lang, {
        maximumFractionDigits: decimals,
      }).format(number);
    },

    formatDate(datetime, seconds = true) {
      if (datetime == null) return;
      return moment(datetime).locale("ca").format("L");
    },

    formatTimestamp(datetime, seconds = true) {
      if (datetime == null) return;

      if (seconds) {
        return moment(datetime).locale("ca").format("L HH:mm:ss");
      } else {
        return moment(datetime).locale("ca").format("L HH:mm");
      }
    },

    formatDateLong(datetime) {
      if (datetime == null) return;

      const date = new Date(datetime);

      return date.toLocaleDateString("ca-ES", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    formatTimeShort(datetime) {
      if (datetime == null) return;

      const date = new Date(datetime);

      return date.toLocaleTimeString("ca-ES", {
        hour: "numeric",
        minute: "numeric",
      });
    },

    formatTimeShortSeconds(datetime) {
      if (datetime == null) return;

      const date = new Date(datetime);

      return date.toLocaleTimeString("ca-ES");
    },

    timeago(datetime) {
      if (datetime) {
        return moment(datetime).locale("ca").fromNow();
      }
      return null;
    },

    isPropObject(object) {
      return object instanceof Object && !Array.isArray(object);
    },

    resolveCoordsString(coordinates) {
      if (typeof coordinates !== "string") return null;

      if (!coordinates.match(/^[0-9]+(\.[0-9]+)?,\s*[0-9]+(\.[0-9]+)?$/))
        return null;

      return coordinates.split(/,\s+/);
    },
  },
});

app.mount("#app");
