<template>
  <div class="space-y-4 min-w-min">
    <div class="flex justify-between items-center flex-wrap gap-4">
      <div>
        <router-link
          :to="{ name: 'clients-create' }"
          class="flex px-2 py-[2px] text-white bg-emerald-500 rounded-md items-center justify-start font-semibold"
          active-class="font-semibold bg-emerald-700"
        >
          <box-icon name="plus" color="currentColor" class="-ml-1"></box-icon>
          <translation value="button.create" />
        </router-link>
      </div>

      <label class="flex gap-2 items-center">
        <div><translation value="table.search" />:</div>
        <htc-input-field
          type="text"
          :value="search"
          @update:value="updateSearch($event)"
        />
      </label>
    </div>

    <div>
      <table class="w-full table-auto">
        <thead>
          <tr class="text-left bg-gray-50">
            <th scope="col" class="pl-3 py-2 pr-2">
              <button
                class="font-semibold flex items-center gap-1"
                @click="order('code')"
              >
                <translation value="generic.code" />

                <span v-if="sort.col === 'code'">
                  <ChevronUpIcon class="w-3 h-3" v-if="!sort.dir" />
                  <ChevronDownIcon class="w-3 h-3" v-else />
                </span>
              </button>
            </th>

            <th scope="col" class="pl-3 py-2 pr-2">
              <button
                class="font-semibold flex items-center gap-1"
                @click="order('name')"
              >
                <translation value="generic.name" />

                <span v-if="sort.col === 'name'">
                  <ChevronUpIcon class="w-3 h-3" v-if="!sort.dir" />
                  <ChevronDownIcon class="w-3 h-3" v-else />
                </span>
              </button>
            </th>

            <th scope="col" class="pl-3 py-2 pr-2">
              <button
                class="font-semibold flex items-center gap-1"
                @click="order('phone')"
              >
                <translation value="form.phone" />

                <span v-if="sort.col === 'phone'">
                  <ChevronUpIcon class="w-3 h-3" v-if="!sort.dir" />
                  <ChevronDownIcon class="w-3 h-3" v-else />
                </span>
              </button>
            </th>

            <th scope="col" class="pl-3 py-2 pr-2">
              <button
                class="font-semibold flex items-center gap-1"
                @click="order('email')"
              >
                <translation value="form.email" />

                <span v-if="sort.col === 'email'">
                  <ChevronUpIcon class="w-3 h-3" v-if="!sort.dir" />
                  <ChevronDownIcon class="w-3 h-3" v-else />
                </span>
              </button>
            </th>

            <th scope="col" class="text-right pr-3">
              <translation value="table.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, row_index) in rows"
            :key="row.id"
            :class="[
              loading && 'animate-pulse',
              row_index % 2 ? 'bg-gray-50' : 'bg-transparent',
            ]"
          >
            <td class="pl-3 py-2 pr-2">
              <router-link
                :to="{ name: 'clients-edit', params: { id: row.id } }"
                class="hover:underline"
              >
                <div v-if="row.code">
                  {{ row.code }}
                </div>
                <div v-else class="text-xs text-gray-400 italic">-</div>
              </router-link>
            </td>

            <td class="pr-2">
              <div v-if="row.name">
                {{ row.name }}
              </div>
              <div v-else class="text-xs text-gray-400 italic">-</div>
            </td>

            <td class="pr-2">
              <div v-if="row.phone">
                {{ row.phone }}
              </div>
              <div v-else class="text-xs text-gray-400 italic">-</div>
            </td>

            <td class="pr-2">
              <div v-if="row.email">
                {{ row.email }}
              </div>
              <div v-else class="text-xs text-gray-400 italic">-</div>
            </td>

            <td class="pr-3">
              <div class="flex justify-end items-center gap-2">
                <router-link
                  :to="{ name: 'clients-edit', params: { id: row.id } }"
                >
                  <PencilIcon class="h-4 w-4 text-emerald-500" />
                </router-link>

                <button @click="remove(row)">
                  <TrashIcon class="h-4 w-4 text-red-500" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="flex justify-between items-center flex-wrap"
      :class="[loading && 'animate-pulse']"
    >
      <div>
        <translation value="table.showing" /> {{ page * size + 1 }}
        <translation value="table.to" />
        {{ (page + 1) * size > total ? total : (page + 1) * size }}
        <translation value="table.of" /> {{ total }}
      </div>

      <div class="flex rounded-l rounded-r text-gray-700">
        <button
          @click="first"
          class="w-8 h-8 rounded-l grid place-items-center hover:bg-gray-50 border"
        >
          <ChevronDoubleLeftIcon class="h-3 w-3" />
        </button>

        <button
          @click="prev"
          class="w-8 h-8 grid place-items-center hover:bg-gray-50 border-t border-b border-r"
        >
          <ChevronLeftIcon class="h-3 w-3" />
        </button>

        <button
          v-for="_page in pages"
          :key="_page"
          @click="goto(_page)"
          class="w-8 h-8 grid place-items-center hover:bg-gray-50 border-t border-b border-r"
          :class="[_page === page && '!bg-gray-200']"
        >
          {{ _page + 1 }}
        </button>

        <button
          @click="next"
          class="w-8 h-8 grid place-items-center hover:bg-gray-50 border-t border-b border-r"
        >
          <ChevronRightIcon class="h-3 w-3" />
        </button>

        <button
          @click="last"
          class="w-8 h-8 rounded-r grid place-items-center hover:bg-gray-50 border-t border-b border-r"
        >
          <ChevronDoubleRightIcon class="h-3 w-3" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import { debounce } from "lodash";
import HtcButton from "@/components/HtcButton.vue";
import { mapState } from "vuex";
import Translate from "@/translations/Translate";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";

export default {
  name: "ClientTypesTable",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    PencilIcon,
    TrashIcon,
    Translation,
    HtcInputField,
    HtcButton,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      total: null,
      size: 10,
      page: 0,
      sort: {
        col: "name",
        dir: 0,
      },
      search: "",
      rows: [],
      loading: true,
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.lang,
    }),

    maxPage() {
      return Math.floor(this.total / this.size);
    },

    pages() {
      const pages = [];

      for (let i = 0; i <= this.maxPage; i++) {
        pages.push(i);
      }

      if (this.maxPage > 3) {
        if (this.page >= 2 && this.maxPage - this.page > 2) {
          return pages.slice(this.page - 1, this.page + 3);
        } else if (this.page < 2) {
          return pages.slice(0, 4);
        } else {
          return pages.slice(this.maxPage - 3, this.maxPage + 1);
        }
      }

      return pages;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;

      const response = await api
        .get("/clients", {
          params: {
            size: this.size,
            page: this.page,
            sortCol: this.sort.col,
            sortDir: this.sort.dir ? "desc" : "asc",
            search: this.search,
          },
        })
        .catch(() => {});

      if (response?.status !== 200) {
        alert("No se pudo cargar la información del servidor.");
      }

      this.total = response.data.total;
      this.rows = response.data.results;

      this.loading = false;
    },

    first() {
      if (this.loading) return;

      this.page = 0;
      this.loadData();
    },

    prev() {
      if (this.loading) return;

      if (this.page > 0) {
        this.page--;
        this.loadData();
      }
    },

    next() {
      if (this.loading) return;

      if (this.page < this.maxPage) {
        this.page++;
        this.loadData();
      }
    },

    last() {
      if (this.loading) return;

      this.page = this.maxPage;
      this.loadData();
    },

    goto(page) {
      if (this.loading) return;

      if (0 <= page && page <= this.maxPage) {
        this.page = page;
        this.loadData();
      }
    },

    order(col) {
      if (col === this.sort.col) {
        this.sort.dir = +!this.sort.dir;

        this.page = 0;

        this.loadData();
        return;
      }

      this.sort.col = col;
      this.sort.dir = 0;

      this.page = 0;

      this.loadData();
    },

    updateSearch: debounce(function (value) {
      this.search = value;
      this.page = 0;
      this.loadData();
    }, 500),

    remove(row) {
      const confirm = window.confirm(
        Translate.do(this.locale, "Are you sure you want to delete this?").text,
      );

      if (!confirm) return;

      api
        .delete("/clients/" + row.id)
        .then(() => {
          this.toast.success("Eliminat correctament.");
          this.loadData();
        })
        .catch(() => {
          this.toast.error("No s'ha pogut eliminar.");
        });
    },
  },
};
</script>
