import { Capacitor, registerPlugin } from "@capacitor/core";
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
import logger from "@/logger";
import { Geolocation, Position } from "@capacitor/geolocation";
import Connection from "@/ws/Connection";

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>(
  "BackgroundGeolocation",
);

class Tracking {
  static watcherId: string = null;

  static accuracy = 20;

  static lastLocation: Position = null;
  static sentLocation: Position = null;

  static interval = null;

  static watcherInterval = null;

  static async begin() {
    if (this.interval) return;

    await this.current();

    this.interval = setInterval(() => this.current(), 7000);
  }

  static async current() {
    const location = await Geolocation.getCurrentPosition();

    if (location.coords.accuracy <= this.accuracy) {
      this.lastLocation = location;
      // logger("location", location);

      if (Connection.isReady()) {
        Connection.send({
          type: "Location",
          action: "Update",
          location: location,
        });
      }
    } else {
      // logger("ignored", "location", location);
    }
  }

  static finish() {
    clearInterval(this.interval);
  }

  static async sendLastLocation() {
    if (this.lastLocation !== this.sentLocation) {
      if (Connection.isReady()) {
        Connection.send({
          type: "Location",
          action: "Update",
          location: this.lastLocation,
        });
        this.sentLocation = this.lastLocation;
      }
    }
  }

  static async startWatcher() {
    if (this.watcherId) {
      this.stopWatcher();
    }

    if (Capacitor.getPlatform() === "web") {
      this.begin();
      return;
    }

    clearInterval(this.watcherInterval);
    this.watcherInterval = setInterval(() => this.sendLastLocation(), 7000);

    logger("tracking", "started");

    // To start listening for changes in the device's location, add a new watcher.
    // You do this by calling 'addWatcher' with an options object and a callback. A
    // Promise is returned, which resolves to the callback ID used to remove the
    // watcher in the future. The callback will be called every time a new location
    // is available. Watchers can not be paused, only removed. Multiple watchers may
    // exist simultaneously.
    const watcherId = await BackgroundGeolocation.addWatcher(
      {
        // If the "backgroundMessage" option is defined, the watcher will
        // provide location updates whether the app is in the background or the
        // foreground. If it is not defined, location updates are only
        // guaranteed in the foreground. This is true on both platforms.

        // On Android, a notification must be shown to continue receiving
        // location updates in the background. This option specifies the text of
        // that notification.
        backgroundMessage: "Esta app está usando su ubicación.",

        // The title of the notification mentioned above. Defaults to "Using
        // your location".
        backgroundTitle: "Taxis Cala d'Or - Ubicación",

        // Whether permissions should be requested from the user automatically,
        // if they are not already granted. Defaults to "true".
        requestPermissions: true,

        // If "true", stale locations may be delivered while the device
        // obtains a GPS fix. You are responsible for checking the "time"
        // property. If "false", locations are guaranteed to be up to date.
        // Defaults to "false".
        stale: false,

        // The minimum number of metres between subsequent locations. Defaults
        // to 0.
        distanceFilter: 0,
      },
      (location, error) => {
        if (error) {
          if (error.code === "NOT_AUTHORIZED") {
            if (
              window.confirm(
                "This app needs your location, " +
                  "but does not have permission.\n\n" +
                  "Open settings now?",
              )
            ) {
              // It can be useful to direct the user to their device's
              // settings when location permissions have been denied. The
              // plugin provides the 'openSettings' method to do exactly
              // this.
              BackgroundGeolocation.openSettings();
            }
          }
          return console.error(error);
        }

        if (location.accuracy < this.accuracy) {
          this.lastLocation = {
            coords: {
              latitude: location.latitude,
              longitude: location.longitude,
              accuracy: location.accuracy,
              altitude: location.altitude,
              altitudeAccuracy: location.altitudeAccuracy,
              heading: location.bearing,
              speed: location.speed,
            },
            timestamp: location.time,
          };
        }
      },
    );

    logger("watcherId", watcherId);

    this.watcherId = watcherId;
  }

  static async stopWatcher() {
    clearInterval(this.watcherInterval);

    if (this.watcherId) {
      await BackgroundGeolocation.removeWatcher({
        id: this.watcherId,
      });

      logger("tracking", "ended");
    }

    if (Capacitor.getPlatform() === "web") {
      this.finish();
    }

    this.watcherId = null;
  }
}

/** @ts-ignore */
window.Tracking = Tracking;

export default Tracking;
