<template>
  <layout-main>
    <div class="flex flex-row justify-center py-4">
      <div class="flex flex-row justify-center border rounded cursor-pointer">
        <div
          v-on:click="changeTab('RT')"
          class="p-4 hover:bg-gray-100"
          :class="tab == 'RT' ? 'bg-gray-100' : ''"
        >
          <span><translation value="txt.log-header-taxis" /></span>
        </div>
        <div
          v-on:click="changeTab('ET')"
          class="p-4 border-x hover:bg-gray-100"
          :class="tab == 'ET' ? 'bg-gray-100' : ''"
        >
          <span><translation value="txt.status-header-taxis" /></span>
        </div>
        <div
          v-on:click="changeTab('EA')"
          class="p-4 hover:bg-gray-100"
          :class="tab == 'EA' ? 'bg-gray-100' : ''"
        >
          <span><translation value="txt.status-header-areas" /></span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 my-5 mx-auto w-11/12 max-w-6xl">
      <div v-if="tab == 'RT'" class="py-4 px-1 overflow-auto">
        <h1 class="text-center text-2xl">
          <translation value="txt.log-header-taxis" />
        </h1>
        <StatusLogTable />
      </div>

      <div v-if="tab == 'ET'" class="py-4 px-1 overflow-auto">
        <h1 class="text-center text-2xl">
          <translation value="txt.status-header-taxis" />
        </h1>
        <StatusTaxiTable />
      </div>

      <div v-if="tab == 'EA'" class="py-4 px-1 overflow-auto">
        <h1 class="text-center text-2xl pb-4">
          <translation value="txt.status-header-areas" />
        </h1>
        <div class="flex flex-row justify-center py-4">
          <status-areas-table />
        </div>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import StatusTaxiTable from "./components/StatusTaxiTable.vue";
import StatusAreasTable from "./components/StatusAreasTable.vue";
import StatusLogTable from "./components/StatusLogTable.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "StatusIndex",
  components: {
    LayoutMain,
    StatusTaxiTable,
    StatusAreasTable,
    Translation,
    StatusLogTable,
  },
  data() {
    return {
      tab: "RT",
    };
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
  },
};
</script>
