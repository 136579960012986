import { api } from "@/boot/axios";
import { Geolocation } from "@capacitor/geolocation";

export default class Cantar {
  static async create(stationId, times) {
    return new Promise(async (resolve) => {
      const location = await Geolocation.getCurrentPosition();

      api
        .post("/services", {
          cantado: true,
          stationId: stationId,
          times: times,
          pickupLat: location?.coords?.latitude,
          pickupLng: location?.coords?.longitude,
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }
}
