<template>
  <div class="w-full h-full bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2>Selector Mapa</h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <div
      ref="map"
      class="my-3 z-10 w-full h-full select-none cursor-pointer"
    ></div>

    <div class="mt-2 flex justify-end items-center">
      <htc-button
        is="button"
        color="green"
        class="font-normal px-3 h-7"
        @click="save"
      >
        Guardar
      </htc-button>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import logger from "@/logger";
import { mapState } from "vuex";

export default {
  name: "MapSelectorModal",
  components: {
    XIcon,
    HtcButton,
    Translation,
  },
  props: ["modal"],
  data() {
    return {
      map: null,
      marker: null,
      latitude: this.modal.payload?.latitude || null,
      longitude: this.modal.payload?.longitude || null,
    };
  },
  computed: {
    ...mapState({
      mapConfig: (state) => state.mapConfig || null,
    }),
  },
  mounted() {
    this.drawMap();
  },
  methods: {
    drawMap() {
      if (this.map) return;

      const map = L.map(this.$refs.map, {
        center: [39.5183, 3.2238],
        zoom: 11,
        ...(() => {
          const override = {};

          if (this.mapConfig) {
            if (this.mapConfig.latitude && this.mapConfig.longitude) {
              override.center = [
                this.mapConfig.latitude,
                this.mapConfig.longitude,
              ];
            }

            if (this.mapConfig.zoom) {
              override.zoom = this.mapConfig.zoom;
            }
          }

          if (this.latitude && this.longitude) {
            override.center = [this.latitude, this.longitude];
            override.zoom = 19;
          }

          return override;
        })(),
      });

      map.getRenderer(map).options.padding = 10;

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 30,
        maxNativeZoom: 19,
        attribution:
          '&copy; OSM Mapnik <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);

      map.on("click", this.mapClick);

      map.whenReady(() => {
        this.map = map;

        if (this.latitude && this.longitude) {
          this.createMarker(this.latitude, this.longitude);
        }
      });
    },

    mapClick(e) {
      if (this.marker) {
        this.marker.setLatLng(e.latlng);
      } else {
        this.createMarker(e.latlng.lat, e.latlng.lng);
      }
    },

    createMarker(lat, lng) {
      if (this.marker) return;

      this.marker = L.marker([lat, lng], {
        bubblingMouseEvents: false,
      }).addTo(this.map);
    },

    save() {
      if (!this.marker) return this.modal.reject();

      this.modal.resolve({
        latitude: this.marker.getLatLng().lat,
        longitude: this.marker.getLatLng().lng,
      });
    },
  },
};
</script>
