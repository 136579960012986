<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold">Canvis realitzats</h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <div class="mt-4 space-y-2">
      <div v-if="created.length">
        <h2 class="font-semibold text-sm text-gray-700">Creades</h2>

        <div>
          <div v-for="change in created" :key="change.id">
            <span class="font-bold text-emerald-600">+</span>
            <button
              class="ml-1 hover:line-through hover:decoration-red-600 hover:decoration-2"
              @click="revertChange(change.id)"
            >
              {{ change.place.name }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="updated.length">
        <h2 class="font-semibold text-sm text-gray-700">Actualitzades</h2>

        <div>
          <div v-for="change in updated" :key="change.id">
            <span class="font-bold text-orange-500">~</span>
            <button
              class="ml-1 hover:line-through hover:decoration-red-600 hover:decoration-2"
              @click="revertChange(change.id)"
            >
              <template v-if="change.place.name != change.oplace.name">
                <span> {{ change.oplace.name }}</span>
                <span class="text-orange-500"> &#10140; </span>
                <span> {{ change.place.name }}</span>
              </template>

              <template v-else>
                {{ change.place.name }}
              </template>
            </button>
          </div>
        </div>
      </div>

      <div v-if="deleted.length">
        <h2 class="font-semibold text-sm text-gray-700">Eliminades</h2>

        <div>
          <div v-for="change in deleted" :key="change.id">
            <span class="font-bold text-red-600">-</span>
            <button
              class="ml-1 hover:line-through hover:decoration-red-600 hover:decoration-2"
              @click="revertChange(change.id)"
            >
              {{ change.oplace.name }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 flex justify-end items-center">
      <htc-button
        is="button"
        color="green"
        class="font-normal px-3 h-7"
        @click="save"
      >
        Guardar
      </htc-button>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { cloneDeep, keyBy } from "lodash";

export default {
  name: "PlacesChangesModal",
  components: {
    XIcon,
    HtcButton,
    Translation,
  },
  props: ["modal"],
  data() {
    return {
      changes: this.modal.payload?.changes
        ? cloneDeep(this.modal.payload.changes)
        : {},
      revertedProcessor: this.modal.payload?.revertedProcessor,
    };
  },
  computed: {
    created() {
      return Object.values(this.changes).filter(
        (change) => change.type === "created" && !change.reverted,
      );
    },
    updated() {
      return Object.values(this.changes).filter(
        (change) => change.type === "updated" && !change.reverted,
      );
    },
    deleted() {
      return Object.values(this.changes).filter(
        (change) => change.type === "deleted" && !change.reverted,
      );
    },
  },
  methods: {
    revertChange(id) {
      this.changes[id].reverted = true;

      if (typeof this.revertedProcessor === "function") {
        this.revertedProcessor([this.changes[id]]);
      }

      if (Object.values(this.changes).filter((c) => !c.reverted).length == 0) {
        this.save();
      }
    },

    save() {
      this.modal.resolve({
        changes: this.changes,
      });
    },
  },
};
</script>
