<template>
  <div class="hidden">
    <div ref="marker">
      <inline-svg
        :src="require('@/assets/flag.svg')"
        class="w-7 h-7 fill-green-800"
      />
    </div>

    <div ref="popup" class="w-44 min-w-min space-y-2 font-sans">
      <h2 class="text-gray-800 text-sm font-bold uppercase">
        <!-- {{ serviceTaxi?.service.name }} -->
      </h2>

      <div class="space-y-1">
        <div>
          <div>Temps desde recollida</div>
          <div class="text-sm font-semibold">
            {{ timeSincePickup }}
          </div>
        </div>

        <div>
          <div>Lloc de deixada</div>
          <div class="text-sm font-semibold">
            {{ serviceTaxi?.service.dropoffName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import InlineSvg from "vue-inline-svg";
import moment from "moment";

export default {
  name: "MapFlag",
  components: {
    InlineSvg,
  },
  props: ["map", "serviceTaxi", "filters"],
  data() {
    return {
      marker: null,
      timeSincePickup: "-",

      intervals: {
        timeSincePickup: null,
      },
    };
  },
  computed: {
    show() {
      if (this.filters.services) {
        if (this.filters.services.servicesInProgress) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.draw();
      } else {
        this.marker?.remove();
        this.marker = null;
      }
    },
  },
  created() {
    this.intervals.timeSincePickup = setInterval(
      () => this.updateTimeSincePickup(),
      1000,
    );
  },
  mounted() {
    this.draw();
  },
  beforeUnmount() {
    this.marker?.remove();
    clearInterval(this.intervals.timeSincePickup);
  },
  methods: {
    draw() {
      if (this.marker) return;
      if (
        !this.serviceTaxi?.service?.dropoffLat ||
        !this.serviceTaxi?.service?.dropoffLng
      )
        return;

      const myIcon = L.divIcon({
        className: "",
        html: this.$refs.marker,
        iconAnchor: [14, 14],
        popupAnchor: [0, -14],
      });

      const marker = L.marker(
        [
          this.serviceTaxi.service.dropoffLat,
          this.serviceTaxi.service.dropoffLng,
        ],
        {
          icon: myIcon,
        },
      );

      marker.bindPopup(this.$refs.popup);

      marker.addTo(this.map);

      this.marker = marker;
    },

    formattedDatetime() {
      return this.formatTimestamp(this.serviceTaxi?.assignment.pickedupAt);
    },

    formattedTimeago() {
      return this.timeago(this.serviceTaxi?.assignment.pickedupAt);
    },

    updateTimeSincePickup() {
      if (!this.serviceTaxi?.assignment) return;

      let diff =
        moment().unix() - moment(this.serviceTaxi.assignment.pickedupAt).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      this.timeSincePickup =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },
  },
};
</script>
