<template>
  <div class="space-y-4 min-w-min">
    <Datatable
      v-if="loaded"
      url="/status"
      :columns="columns"
      :autorefresh="true"
      :pageSize="50"
      :initial-sort="{ col: 'timestamp', dir: 1 }"
      :filterEvent="openFilterModal"
      :filters="filters"
      :actionsEnabled="true"
    >
      <template v-slot:actions="actionProps">
        <button @click="toggleState(actionProps.row)">
          <LockClosedIcon
            v-if="actionProps?.row.status === 'disabled'"
            class="h-5 w-5 text-red-500"
          />
          <LockOpenIcon v-else class="h-5 w-5 text-green-500" />
        </button>
      </template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/Datatable/Datatable";
import Modal from "@/modals/Modal";
import StatusTaxiFilter from "./StatusTaxiFilter.vue";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/vue/outline";
import { api } from "@/boot/axios";

export default {
  name: "StatusTaxiTable",
  components: {
    Datatable,
    LockClosedIcon,
    LockOpenIcon,
  },
  data() {
    return {
      search: "",
      filters: null,
      filterModalValues: null,
      loaded: true,
      columns: [
        {
          key: "timestamp",
          label: "services.datetime",
          type: "datetime",
        },
        {
          key: "taxi.code",
          label: "status.taxi",
        },
        {
          key: "status",
          label: "status.status",
          translate: true,
        },
        {
          key: "area.code",
          label: "status.area",
        },
        {
          key: "zone.code",
          label: "status.zone",
        },
        {
          key: "station.code",
          label: "status.station",
        },
        {
          key: "latitude",
          label: "status.latitude",
        },
        {
          key: "longitude",
          label: "status.longitude",
        },
      ],
    };
  },
  methods: {
    openFilterModal(row) {
      Modal.open(StatusTaxiFilter, {
        row: row,
        modalValues: this.filterModalValues,
      }).then((data) => {
        this.filters = data.filters;
        this.filterModalValues = data.modalValues;
      });
    },
    async toggleState(row) {
      this.loaded = false;
      await api.get(`/status/${row.taxiId}/toggle`);
      this.loaded = true;
    },
  },
};
</script>
