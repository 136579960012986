<template>
  <div
    class="min-w-[80px] max-w-[80px] h-[530px] rounded-md shadow text-center justify-center"
    :style="{ color: color }"
  >
    <div class="max-w-[60px] h-[530px] h-auto content-center">
      <div class="w-full px-4 content-center">
        <span v-if="services > 50" :style="{ color: color }" class="mb-[4px]">
          +50
        </span>
        <span v-else :style="{ color: color }" class="mb-[4px]">&nbsp;</span>
      </div>
      <div class="w-full px-4 content-center">
        <div
          v-for="i in 50"
          v-bind:key="i"
          :style="{ background: servicesNumber >= 51 - i ? color : '#000' }"
          class="w-full h-[5px] stroke-[5px] mb-[4px] rounded"
        ></div>
      </div>
      <div class="">
        {{ zone.code.substr(0, 3) }}
      </div>
      <div class="text-2xl">
        {{ services }}
      </div>
    </div>
  </div>
</template>

<script>
import { LoginIcon, LogoutIcon, MinusSmIcon } from "@heroicons/vue/outline";
import InlineSvg from "vue-inline-svg";
import { mapState } from "vuex";

export default {
  name: "ZoneDateItem",
  components: {
    LoginIcon,
    LogoutIcon,
    MinusSmIcon,
    InlineSvg,
  },
  data() {
    return {
      count: 0,
    };
  },
  props: ["zone", "color", "services"],
  mounted() {},
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
    }),
    servicesNumber() {
      return this.services < 50 ? this.services : 50;
    },
  },
  methods: {},
};
</script>
