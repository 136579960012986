<template>
  <client-layout-main>
    <client-map-component :editable="false" />
  </client-layout-main>
</template>

<script>
import ClientLayoutMain from "../layout/ClientLayoutMain.vue";
import ClientMapComponent from "../Map/ClientMapComponent.vue";

export default {
  name: "ClientMapIndex",
  components: {
    ClientLayoutMain,
    ClientMapComponent,
  },
};
</script>
