<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="button.create" />
          {{ " " }}
          <translation value="drivers.driver" :lowercase="true" />
        </template>

        <template v-else>
          <translation value="drivers.driver" />:
          {{ original?.email }}
        </template>
      </h1>

      <div class="mt-10">
        <label>
          <div><translation value="drivers.code" />:</div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.dni"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="license" />:</div>
          <select
            v-model="form.taxiId"
            class="w-full block px-3 py-1 border-gray-300 rounded-md shadow-sm focus:border-taxis-orange focus:ring focus:ring-taxis-orange/25 focus:ring-opacity-50 disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          >
            <option :value="null">
              {{ "—" }}
            </option>
            <option v-for="taxi in taxis" :key="taxi.id" :value="taxi.id">
              {{ taxi.code }}
            </option>
          </select>
        </label>

        <label>
          <div>
            <translation value="form.client_name" />:
            <span class="text-gray-500 text-sm"
              >(<translation value="required" />)</span
            >
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.name"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>
            <translation value="form.email" />:
            <span class="text-gray-500 text-sm"
              >(<translation value="required" />)</span
            >
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.email"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>
            <translation value="form.password" />:
            <span v-if="!editMode" class="text-gray-500 text-sm">
              (<translation value="required" />)
            </span>
          </div>
          <htc-input-field
            type="password"
            maxlength="255"
            v-model:value="form.password"
            :placeholder="editMode ? '••••••' : undefined"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="taxi.phone" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.phone"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="generic.municipality" />:</div>
          <select
            class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
            v-model="form.municipalityId"
          >
            <option
              v-for="municipality in municipalities"
              :key="municipality.id"
              :value="municipality.id"
            >
              {{ municipality.code }}
            </option>
          </select>
        </label>

        <div class="mt-2 mb-2">
          <label>
            <input
              type="checkbox"
              id="associated"
              :value="form.associated"
              v-model="form.associated"
              class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
              :disabled="loading"
            />
            <span class="ml-2">
              <translation value="drivers.associated" />
            </span>
          </label>
        </div>

        <div class="mt-2">
          <span> <translation value="taxi.available" />: </span>
        </div>

        <div class="flex items-center gap-4">
          <label>
            <input
              type="checkbox"
              id="day"
              :value="form.day"
              v-model="form.day"
              class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
              :disabled="loading"
            />
            <span class="ml-2">
              <translation value="taxi.day" />
            </span>
          </label>

          <label>
            <input
              type="checkbox"
              id="evening"
              :value="form.evening"
              v-model="form.evening"
              class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
              :disabled="loading"
            />
            <span class="ml-2">
              <translation value="taxi.evening" />
            </span>
          </label>

          <label>
            <input
              type="checkbox"
              id="nigth"
              :value="form.nigth"
              v-model="form.nigth"
              class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
              :disabled="loading"
            />
            <span class="ml-2">
              <translation value="taxi.nigth" />
            </span>
          </label>
        </div>

        <div class="mt-2">
          <span> <translation value="drivers.active" />: </span>
        </div>

        <label class="flex items-center gap-2">
          <input
            type="checkbox"
            v-model="form.enabled"
            class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
          <span>
            <translation value="active" />
          </span>
        </label>

        <label>
          <div><translation value="generic.notes" /></div>
          <textarea
            maxlength="65535"
            v-model="form.notes"
            class="w-full h-32 border-gray-300 rounded-md shadow-sm focus:border-taxis-orange focus:ring focus:ring-taxis-orange/25 focus:ring-opacity-50 disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          >
          </textarea>
        </label>
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="button.save" />
          <translation v-else value="button.create" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";

export default {
  name: "DriversEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      editMode: false,

      taxis: [],
      municipalities: [],
      original: null,
      form: {
        dni: null,
        taxiId: null,
        enabled: true,
        code: null,
        phone: null,
        email: null,
        password: null,
        day: false,
        evening: false,
        nigth: false,
        municipalityId: "fd7c071e-38c1-4522-b6e9-994bd9163575",
        associated: true,
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loading = true;
      this.loadAll();
      this.loadMunicipalities();
    } else {
      this.loadTaxis();
      this.loadMunicipalities();
    }
  },
  methods: {
    loadAll() {
      Promise.all([
        api
          .get("/drivers/" + this.id)
          .then((response) => {
            this.original = { ...response.data };
            this.form = { ...response.data };

            this.form.associated = this.original.associated == 1 ? true : false;
            this.form.day = this.original.day == 1 ? true : false;
            this.form.evening = this.original.evening == 1 ? true : false;
            this.form.nigth = this.original.nigth == 1 ? true : false;
          })
          .catch(() => {
            this.$router.replace({ name: "drivers-index" });
          }),
        api
          .get("/taxis", {
            params: {
              page: 0,
              size: 100,
              sortCol: "code",
              sortDir: "asc",
            },
          })
          .then((response) => {
            this.taxis = [...response.data.results];
          }),
      ]).then(() => {
        this.loading = false;
      });
    },

    loadTaxis() {
      api
        .get("/taxis", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.taxis = [...response.data.results];
          this.loading = false;
        });
    },

    loadInfo() {
      api
        .get("/drivers/" + this.id)
        .then((response) => {
          this.original = { ...response.data };
          this.form = { ...response.data };
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "drivers-index" });
        });
    },

    loadMunicipalities() {
      api
        .get("/municipalities", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.municipalities = [...response.data.results];

          let main = this.municipalities.filter(
            (filter) => filter.code == "SANTANYI",
          );

          this.municipalityId = this.municipalityId
            ? this.municipalityId
            : main[0].id;

          this.loading = false;
        });
    },

    ValidateEmail(mail) {
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    },

    submit() {
      if (!this.ValidateEmail(this.form.email)) {
        this.toast.error("El format del correu electrònic no es correcte.");
        return false;
      }

      this.form.day = this.form.day ? 1 : 0;
      this.form.evening = this.form.evening ? 1 : 0;
      this.form.nigth = this.form.nigth ? 1 : 0;
      this.form.associated = this.form.associated ? 1 : 0;

      if (!this.form.password) delete this.form.password;

      if (this.editMode) {
        api
          .put("/drivers/" + this.id, this.form)
          .then(() => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "drivers-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        api
          .post("/drivers", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "drivers-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
  },
};
</script>
