<template>
  <modals />

  <div class="w-screen h-screen overflow-auto overflow-x-hidden flex">
    <layout-sidebar />

    <div
      class="grow w-full h-full flex flex-col min-w-full lg:min-w-0 overflow-hidden"
    >
      <layout-header />

      <div class="grow overflow-auto relative">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Modals from "@/modals/Modals.vue";
import LayoutHeader from "./LayoutHeader.vue";
import LayoutSidebar from "./LayoutSidebar.vue";

export default {
  name: "LayoutMain",
  components: {
    LayoutHeader,
    LayoutSidebar,
    Modals,
  },
};
</script>
