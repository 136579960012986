<template>
  <i class="bx" :class="icon"></i>
</template>
<script>
export default {
  props: {
    name: String,
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon() {
      const type = this.getType();

      return `bx${type}-${this.name}`;
    },
  },
  methods: {
    getType() {
      switch (this.type) {
        case "solid":
          return "s";
        case "logos":
          return "l";
        default:
          return "";
      }
    },
  },
};
</script>
