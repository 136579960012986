<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="reservation.especials" />
        </h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>
    <div class="p-2 text-gray-700">
      <div v-if="row.entrada">
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.flynumber" />
          </div>
          <div>{{ row.flynumber }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.fullname" />
          </div>
          <div>{{ row.fullname }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.paxn" />
          </div>
          <div>{{ row.paxn }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.paxphone" />
          </div>
          <div>{{ row.paxphone }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.luggage" />
          </div>
          <div>{{ row.luggage ? "sí" : "No" }}</div>
        </div>
      </div>

      <div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.waiting" />
          </div>
          <div>{{ row.waiting }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.babychair" />
          </div>
          <div>{{ row.babychair_number }} {{ row.babychair }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.animals" />
          </div>
          <div>{{ row.animals_number }} {{ row.animals }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.bikes" />
          </div>
          <div>{{ row.bikes_number }} {{ row.bikes }}</div>
        </div>
        <div class="flex flex-row my-2 border-b-2 border-gray-700">
          <div class="w-[200px] font-bold">
            <translation value="reservation.others" />
          </div>
          <div>{{ row.others }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { XIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";

export default {
  name: "SpecialServices",
  components: {
    XIcon,
    Translation,
  },
  props: ["modal"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      row: this.modal.payload,
    };
  },
  computed: {},
  created() {
    //this.loadData();
  },
  methods: {},
};
</script>
