<template>
  <layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-4xl">
      <div class="flex flex-col justify-start items-center gap-2">
        <h1 class="text-center text-2xl">
          <translation value="generic.service" />:
          {{ formatTimestamp(service.createdAt, false) }}
        </h1>

        <div
          class="px-4 rounded-xl text-gray-700 uppercase"
          :class="{
            'bg-green-400': state === 'done',
            'bg-blue-400': state === 'doing',
            'bg-orange-400': state === 'assigned',
            'bg-red-400': state === 'waiting',
          }"
        >
          <template v-if="state === 'done'">
            <translation value="services.ended" />
          </template>

          <template v-else-if="state === 'doing'">
            <translation value="services.in-progress" />
          </template>

          <template v-else-if="state === 'assigned'">
            <translation value="services.assigned" />
          </template>

          <template v-else-if="state === 'waiting'">
            <translation value="services.pending" />
          </template>
        </div>
      </div>

      <div class="mt-10 space-y-2">
        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="services.date" />
          </div>
          <div>{{ formatDateLong(service.createdAt) }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="services.time" />
          </div>
          <div>{{ formatTimeShort(service.createdAt) }}</div>
        </div>

        <template v-if="service.reserva">
          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="form.pickupAt" />
            </div>
            <div>
              {{ formatDateLong(service.pickupAt) }}
              {{ formatTimeShort(service.pickupAt) }}
            </div>
          </div>
        </template>

        <div class="py-4">
          <div class="py-2 text-sm uppercase text-gray-700 font-semibold">
            <translation value="generic.client" />
          </div>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="form.name" />
            </div>
            <div>{{ service.name }}</div>
          </div>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="form.phone" />
            </div>
            <div>{{ service.phone }}</div>
          </div>

          <div v-if="service.email">
            <div class="text-xs uppercase text-gray-500">
              <translation value="form.email" />
            </div>
            <div>{{ service.email }}</div>
          </div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="cantado" />
          </div>
          <div>{{ service.cantado ? "Sí" : "No" }}</div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.pickup" />
          </div>
          <div>{{ service.pickupName }}</div>
          <div
            v-if="service.dropoffInfo"
            class="flex p-1 text-gray-600 text-sm italic"
          >
            {{ service.pickupInfo }}
          </div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.dropoff" />
          </div>
          <div>{{ service.dropoffName }}</div>
          <div
            v-if="service.dropoffInfo"
            class="flex p-1 text-gray-600 text-sm italic"
          >
            {{ service.dropoffInfo }}
          </div>
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.pplqty" />
          </div>
          <div>{{ service.people }}</div>
        </div>

        <div v-if="service.message">
          <div class="text-xs uppercase text-gray-500">
            <translation value="form.message" />
          </div>
          <div>{{ service.message }}</div>
        </div>

        <div class="pt-4 text-sm uppercase text-gray-700 font-semibold">
          <translation value="reservation.services" />
        </div>

        <div>
          <div class="text-xs uppercase text-gray-500">
            <translation value="reservation.priority" />
          </div>
          <div>
            {{ service.priority ? "Sí" : "No" }}
            <span v-if="service.priorityInfo" class="text-xs text-gray-800">
              ({{ service.priorityInfo }})
            </span>
          </div>
        </div>

        <template v-if="service.especial">
          <div class="pt-4 text-sm uppercase text-gray-700 font-semibold">
            <translation value="reservation.especials" />
          </div>

          <div v-if="service.entrada">
            <div class="text-xs uppercase text-gray-500">
              <translation value="reservation.entrada" />
            </div>
            <div>{{ service.entrada ? "Sí" : "No" }}</div>
          </div>
          <template v-if="service.entrada">
            <div>
              <div class="text-xs uppercase text-gray-500">
                <translation value="reservation.flynumber" />
              </div>
              <div>{{ service.flynumber }}</div>
            </div>
            <div>
              <div class="text-xs uppercase text-gray-500">
                <translation value="reservation.fullname" />
              </div>
              <div>{{ service.fullname }}</div>
            </div>
            <div>
              <div class="text-xs uppercase text-gray-500">
                <translation value="form.phone" />
              </div>
              <div>{{ service.paxphone }}</div>
            </div>
            <div>
              <div class="text-xs uppercase text-gray-500">
                <translation value="reservation.paxn" />
              </div>
              <div>{{ service.paxn }}</div>
            </div>
            <div>
              <div class="text-xs uppercase text-gray-500">
                <translation value="reservation.luggage" />
              </div>
              <div>{{ service.luggage ? "Sí" : "No" }}</div>
            </div>
          </template>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="reservation.waiting" />
            </div>
            <div>
              {{ service.waiting_number }}
              <span v-if="service.waiting" class="text-xs text-gray-800">
                {{ service.waiting }}
              </span>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="reservation.bikes" />
            </div>
            <div>
              {{ service.bikes_number }}
              <span v-if="service.bikes" class="text-xs text-gray-800">
                {{ service.bikes }}
              </span>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="reservation.animals" />
            </div>
            <div>
              {{ service.animals_number }}
              <span v-if="service.animals" class="text-xs text-gray-800">
                {{ service.animals }}
              </span>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="reservation.babychair" />
            </div>
            <div>
              {{ service.babychair_number }}
              <span v-if="service.babychair" class="text-xs text-gray-800">
                {{ service.babychair }}
              </span>
            </div>
          </div>

          <div>
            <div class="text-xs uppercase text-gray-500">
              <translation value="reservation.others" />
            </div>
            <div>
              {{ service.others }}
            </div>
          </div>
        </template>

        <template v-if="state !== 'waiting'">
          <div class="pt-4 text-sm uppercase text-gray-700 font-semibold">
            <translation value="txt.technical-details" />
          </div>

          <div v-if="service.taxi">
            <div class="text-xs uppercase text-gray-500">
              <translation value="taxi.assigned" />
            </div>
            <div>
              {{ service.taxi.code }}
            </div>
          </div>

          <div v-if="service.assignedAt">
            <div class="text-xs uppercase text-gray-500">
              <translation value="services.assignedAt" />
            </div>
            <div>
              {{ formatTimestamp(service.assignedAt, false) }}
            </div>
          </div>

          <div v-if="diff1" class="text-gray-600">
            {{ diff1 }}
          </div>

          <div v-if="service.pickedupAt">
            <div class="text-xs uppercase text-gray-500">
              <translation value="services.pickedupAt" />
            </div>
            <div>{{ formatTimestamp(service.pickedupAt, false) }}</div>
          </div>

          <div v-if="diff2" class="text-gray-600">
            {{ diff2 }}
          </div>

          <div v-if="service.droppedoffAt">
            <div class="text-xs uppercase text-gray-500">
              <translation value="services.droppedoffAt" />
            </div>
            <div>{{ formatTimestamp(service.droppedoffAt, false) }}</div>
          </div>
          <div v-if="service.droppedoffAddress">
            <div>
              {{ service.droppedoffAddress }}
              <span class="text-xs font-thin">
                [{{ service.droppedoffLat + ", " + service.droppedoffLng }}]
              </span>
            </div>
          </div>
        </template>
      </div>
      <div class="my-2 flex flex-row justify-end">
        <button
          class="inline-flex items-center justify-center px-4 h-8 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default text-white bg-emerald-600 hover:bg-emerald-700 focus-visible:ring-emerald-500"
          @click="edit"
        >
          <translation value="generic.edit" />
        </button>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";
import moment from "moment";

export default {
  name: "ServicesShow",
  components: {
    LayoutMain,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      service: {},
    };
  },
  computed: {
    state() {
      if (this.service) {
        if (this.service.droppedoffAt) {
          return "done";
        }

        if (this.service.serviceTaxi) {
          switch (this.service.serviceTaxi.status) {
            case "assigned":
              return "waiting";
            case "accepted":
              return "assigned";
            case "pickedup":
              return "doing";
          }
        }

        return "waiting";
      }

      return null;
    },

    diff1() {
      if (!this.service.assignedAt) return null;
      if (!this.service.pickedupAt) return null;

      const diff = moment(this.service.pickedupAt).diff(
        this.service.assignedAt,
      );

      return Math.round(moment.duration(diff).asMinutes()) + "min";
    },

    diff2() {
      if (!this.service.pickedupAt) return null;
      if (!this.service.droppedoffAt) return null;

      const diff = moment(this.service.droppedoffAt).diff(
        this.service.pickedupAt,
      );

      return Math.round(moment.duration(diff).asMinutes()) + "min";
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.loading = true;
      this.loadInfo();
    }
  },
  methods: {
    loadInfo() {
      api
        .get("/services/" + this.id)
        .then((response) => {
          this.service = response.data || {};
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "services-index" });
        });
    },
    edit() {
      this.$router.push({
        name: "services-edit",
        params: { id: this.service.id },
      });
    },
  },
};
</script>
