<template>
  <layout-driver :hideHeader="false">
    <div
      class="w-full h-full absolute bg-black min-h-screen"
      :class="[
        ((taxiState === 'online' || taxiState === 'busy') && 'bg-gray-300') ||
          'bg-red-400',
      ]"
    >
      <template v-if="taxiState != 'offline'">
        <div class="gap-1 text-gray-800 font-semibold mt-2">
          <div>
            <div
              v-if="!stationSelected"
              class="w-full h-full text-center mt-2 mb-2"
            >
              <button
                class="w-full h-full items-center font-semibold px-4 py-2 bg-red-700 rounded-md text-4xl"
                @click="locationMine"
              >
                <translation value="txt.location-mine" />
              </button>
            </div>
            <div
              v-if="!stationSelected"
              class="grid grid-cols-3 gap-2 break-all"
            >
              <button
                class="font-semibold px-4 py-2 rounded-md text-2xl"
                :style="{ 'background-color': getZoneColor(station.zoneId) }"
                v-for="(station, stationId) in stations"
                :key="station.id"
                @click="locationStation(station.id)"
              >
                {{ station.code.substr(0, 3) }}
              </button>
            </div>
            <div
              v-if="!stationSelected"
              class="w-full h-full text-center mt-2 mb-2"
            >
              <button
                class="w-full h-full items-center font-semibold px-4 py-2 bg-red-700 rounded-md text-4xl"
                @click="locationp10"
              >
                <translation value="txt.location-p10" />
              </button>
            </div>
            <div
              v-else-if="stationSelected && stationSelected !== 'p10'"
              class="grid grid-cols-1 gap-2 break-all"
            >
              <button
                class="font-semibold px-4 py-2 rounded-md text-2xl"
                :style="{ 'background-color': '#B' + n + n + n + n + n }"
                v-for="n in this.config?.maxCantadoServices"
                :key="n"
                @click="doCantar(n)"
              >
                {{ n }}
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="h-32 mt-6 flex flex-col justify-center items-center text-gray-800 font-semibold text-2xl"
        >
          <div class="flex items-center gap-1">
            <ExclamationIcon class="w-8 h-8" />
            <translation value="services.offline" />
          </div>

          <div class="mt-4">
            <button
              class="flex items-center font-semibold px-4 py-2 bg-green-700 rounded-md"
              @click="connect"
            >
              <translation value="button.connect" />
            </button>
          </div>
        </div>
      </template>
    </div>
  </layout-driver>
</template>

<script>
import {
  ClockIcon,
  ExclamationIcon,
  ChevronLeftIcon,
} from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapGetters, mapState } from "vuex";
import Connection from "@/ws/Connection";
import Modal from "@/modals/Modal";
import Translate from "@/translations/Translate";
import LayoutDriver from "../../LayoutDriver.vue";
import { api } from "@/boot/axios";
import Cantar from "@/ws/Cantar";

export default {
  name: "ServiceTile",
  components: {
    ChevronLeftIcon,
    ClockIcon,
    ExclamationIcon,
    LayoutDriver,
    Translation,
    Modal,
  },

  data() {
    return {
      stations: {},
      stationTaxis: {},
      listeners: {
        ready: null,
        stations: null,
      },
      config: {},
      zones: {},
      stationSelected: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),
    ...mapGetters(["isOffline"]),

    taxiState() {
      console.log(this.taxi?.state);
      return !this.isOffline && this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },
  },

  created() {
    this.loadZones();
    this.loadConfig();

    this.listeners.ready = Connection.addListener("ready", () => {
      Connection.send({
        type: "Stations",
        action: "Subscribe",
      });
    });

    this.listeners.stations = Connection.addListener(
      { type: "Stations", action: "Data" },
      (msg) => {
        this.processData(msg);
      },
    );
  },

  beforeUnmount() {
    Connection.removeListener(this.listeners.ready);
    Connection.removeListener(this.listeners.stations);

    if (Connection.isReady()) {
      Connection.send({
        type: "Stations",
        action: "Unsubscribe",
      });
    }
  },

  mounted() {
    console.log(this.stations);
  },

  methods: {
    connect() {
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },

    selectZone(id) {
      this.stationSelected = id;
    },

    selectSector(id) {
      this.sectorSelected = id;
      var sector = this.sectors.filter((sector) => sector.id === id);
      this.stationSelected = this.stationSelected
        ? this.stationSelected
        : sector.zoneId;
      (this.dropoffLat = sector[0]?.lat),
        (this.dropoffLng = sector[0]?.lng),
        this.updateDestination();
    },

    getZoneColor(zoneId) {
      if (Array.isArray(this.zones)) {
        var zone = this.zones?.filter((zone) => zone.id === zoneId);
        if (zone[0]?.color) {
          return zone[0].color;
        }

        return "#F54435";
      }
      return;
    },

    loadZones() {
      api
        .get("/zones", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.zones = [...response.data.results];
        });
    },

    loadConfig() {
      api
        .get("/config")
        .then((response) => {
          this.config = response.data || {};
        })
        .catch(() => {
          this.toast.error("No s'ha pogut carregar les dades.");
        });
    },

    processData(msg) {
      if (this.isPropObject(msg.station)) {
        this.stations[msg.station.id] = msg.station;
      }

      if (this.isPropObject(msg.delStation)) {
        delete this.stations[msg.delStation.id];
      }

      if (this.isPropObject(msg.stations)) {
        this.stations = msg.stations;
      }

      if (this.isPropObject(msg.stationTaxi)) {
        this.stationTaxis[msg.stationTaxi.taxiId] = msg.stationTaxi;
      }

      if (this.isPropObject(msg.delStationTaxi)) {
        delete this.stationTaxis[msg.delStationTaxi.taxiId];
      }

      if (this.isPropObject(msg.stationTaxis)) {
        this.stationTaxis = msg.stationTaxis;
      }
    },

    locationMine() {
      this.stationSelected = "location";
    },

    locationp10() {
      this.stationSelected = "location-p10";
      this.doCantar(1);
    },

    locationStation(stationId) {
      this.stationSelected = stationId;
    },

    async doCantar(times) {
      if (!this.taxi) return;

      var text = null;
      if (this.stationSelected == "location-p10") {
        text = Translate.value("txt.confirm-create-cantado-p10").text;
      } else {
        text =
          this.stationSelected == "location"
            ? Translate.value("txt.confirm-create-cantado").text
            : Translate.value("txt.confirm-create-cantado-times").text;
      }

      Modal.openConfirmModal({
        title: "Cantado",
        text: text,
      })
        .then(async () => {
          const cantado = await Cantar.create(this.stationSelected, times);

          this.$router.push({ name: "driver-main" });
        })
        .catch(() => {});
    },
  },
};
</script>
