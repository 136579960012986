import store from "@/store";
import { MsgStations } from "../../../backend/src/ws/msgtypes/stations";

export default class Service {
  static async handle(msg: MsgStations) {
    if (msg.type !== "Stations") return;

    if (msg.action === "Data") {
      if (msg.stationTaxi) {
        const taxiId = msg.stationTaxi.taxiId;
        if (taxiId === store.state.taxi?.id) {
          return store.commit("SET_STATION", msg.stationTaxi.station);
        }
      }

      if (msg.delStationTaxi) {
        const taxiId = msg.delStationTaxi.taxiId;
        if (taxiId === store.state.taxi?.id) {
          return store.commit("SET_STATION", null);
        }
      }
    }
  }
}
