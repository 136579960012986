<template>
  <focus-trap v-for="modal in modals" :key="modal.id">
    <div
      tabindex="-1"
      class="fixed overflow-auto z-30 inset-0 w-full h-full bg-black/25 flex justify-center items-center focus:outline-none"
      @mousedown="mousedown"
      @mouseup="mouseup(modal.closeOutside)"
      @keydown.esc="close"
    >
      <component
        class="m-auto"
        :is="modal.component"
        :modal="modal"
        @mousedown="inside = true"
        @mouseup="inside = true"
      />
    </div>
  </focus-trap>
</template>

<script>
import { mapState } from "vuex";
import MapSettings from "./MapSettings.vue";
import ServiceNewModal from "./ServiceNewModal.vue";
import PolygonCreate from "./PolygonCreate.vue";
import PlaceMarkerModal from "./PlaceMarkerModal.vue";
import PlacesChangesModal from "./PlacesChangesModal.vue";
import PlacesSelector from "./PlacesSelector.vue";
import MapSelectorModal from "./MapSelectorModal.vue";
import ConfirmModal from "./ConfirmModal.vue";

export default {
  name: "Modals",
  components: {
    PolygonCreate,
    MapSettings,
    ServiceNewModal,
    PlaceMarkerModal,
    PlacesChangesModal,
    PlacesSelector,
    MapSelectorModal,
    ConfirmModal,
  },
  data() {
    return {
      inside: false,
      outside: false,
    };
  },
  computed: {
    ...mapState(["modals"]),
  },
  methods: {
    mousedown() {
      if (!this.inside) {
        this.outside = true;
      }
    },

    mouseup(closeOutside) {
      if (closeOutside && this.outside) {
        this.close();
      }

      this.outside = false;
      this.inside = false;
    },

    close() {
      this.$store.state.modals[this.$store.state.modals.length - 1].reject();
    },
  },
};
</script>
