import Modal from "@/modals/Modal";

document.addEventListener("keydown", (e) => {
  if (e.key === "F4") {
    Modal.openServiceNewModal({
      reservaForm: false,
    }).catch((e) => {
      // Nothing to do
    });
  }
});
