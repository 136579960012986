<template>
  <client-layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-6xl">
      <h1 class="text-center text-2xl">
        <translation value="nav.serveis" />
      </h1>

      <div class="py-4 px-1 overflow-auto">
        <services-table />
      </div>
    </div>
  </client-layout-main>
</template>

<script>
import ClientLayoutMain from "../layout/ClientLayoutMain.vue";
import ServicesTable from "./components/ServicesTable.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "ServicesIndex",
  components: {
    ClientLayoutMain,
    ServicesTable,
    Translation,
  },
};
</script>
