<template>
  <div class="w-full h-full flex flex-col justify-between text-3xl">
    <div class="px-2 py-1">
      <div class="font-semibold flex justify-between">
        <translation value="services.waiting-time" />:
        <div>{{ waitTime }}</div>
      </div>
      <div v-if="timeLeft" class="flex justify-between font-semibold">
        <translation value="services.time-left" />:
        <div>{{ timeLeft }}</div>
      </div>
    </div>

    <div v-if="isMine">
      <div class="flex justify-between">
        <button
          v-if="timeLeft != '00:00'"
          class="w-1/2 h-24 flex items-center justify-center font-semibold bg-red-500 text-lg px-1 py-0"
          @click="decline"
        >
          <XIcon class="w-6 h-6" />
          <translation value="services.decline" />
        </button>

        <button
          v-if="timeLeft != '00:00'"
          class="w-1/2 h-24 flex items-center justify-center font-semibold bg-green-700 text-lg px-1 py-0"
          @click="accept"
        >
          <CheckIcon class="w-6 h-6" />
          <translation value="services.accept" />
        </button>
      </div>
    </div>

    <div v-else class="px-2 py-1 flex justify-between font-semibold">
      <div>Assignat a:</div>
      <div>{{ assignment?.taxi?.code }}</div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapState } from "vuex";
import moment from "moment";
import Connection from "@/ws/Connection";
import { NativeAudio } from "@capacitor-community/native-audio";

export default {
  name: "ServiceTileAssigned",
  components: {
    CheckIcon,
    XIcon,
    Translation,
  },
  props: ["taxiConfig"],
  data() {
    return {
      timeLeft: null,
      waitTime: null,
      intervals: {
        timeLeft: null,
        waitTime: null,
      },
    };
  },
  computed: {
    ...mapState(["taxi", "serverTime", "serviceTaxi"]),

    taxiState() {
      return this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },

    isMine() {
      return this.assignment?.taxiId === this.taxi.id;
    },
  },
  watch: {
    status: {
      immediate: true,
      handler(status) {
        if (status === "assigned") {
          if (this.intervals.timeLeft == null) {
            this.updateTimeLeft();
            this.updateWaitTime();

            this.intervals.timeLeft = setInterval(() => {
              this.updateTimeLeft();
            }, 1000);
            this.intervals.waitTime = setInterval(
              () => this.updateWaitTime(),
              1000,
            );
          }
        } else {
          if (this.timeLeft != null || this.intervals.timeLeft != null) {
            clearInterval(this.intervals.timeLeft);
            this.intervals.timeLeft = null;
            this.timeLeft = null;
          }
          if (this.waitTime != null || this.intervals.waitTime != null) {
            clearInterval(this.intervals.waitTime);
            this.intervals.waitTime = null;
            this.waitTime = null;
          }
        }
      },
    },
  },
  beforeUnmount() {
    for (const interval of Object.values(this.intervals)) {
      clearInterval(interval);
    }
  },
  methods: {
    updateTimeLeft() {
      if (!this.assignment) return;

      let diff =
        moment(this.assignment.assignedExpiryAt).unix() -
        moment(this.serverTime).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      // S'executa cada 3 segons
      if (this.taxiConfig.sound == 1 && seconds > 0 && seconds % 3 == 0) {
        NativeAudio.play({
          assetId: "beep",
        });
      }

      this.timeLeft =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },

    updateWaitTime() {
      if (!this.service) return;

      const timestamp = this.service.pickupAt || this.service.createdAt;

      let diff = moment(this.serverTime).unix() - moment(timestamp).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      this.waitTime =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },

    decline() {
      this.timeLeft = "00:00";
      Connection.send({
        type: "Service",
        action: "DriverAction",
        payload: "Decline",
      });
    },

    accept() {
      this.timeLeft = "00:00";
      Connection.send({
        type: "Service",
        action: "DriverAction",
        payload: "Accept",
      });
    },
  },
};
</script>
