<template>
  <layout-main>
    <div ref="map" class="z-10 w-full h-full select-none"></div>

    <template v-if="map">
      <places-changes
        v-model:places="places"
        v-model:originalPlaces="originalPlaces"
      />

      <place-marker
        v-for="place in Object.values(places)"
        :key="place.id"
        :map="map"
        :place="place"
        @update:place="onPlaceUpdated"
        @delete:place="onPlaceDeleted"
      />
    </template>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import { useToast } from "vue-toastification";
import PlaceMarker from "./components/PlaceMarker.vue";
import { api } from "@/boot/axios";
import Modal from "@/modals/Modal";
import { v4 as uuidv4 } from "uuid";
import PlacesChanges from "./components/PlacesChanges.vue";

const L = window.L;

export default {
  name: "PlacesIndex",
  components: {
    LayoutMain,
    PlaceMarker,
    PlacesChanges,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      map: null,
      places: {},
      originalPlaces: {},

      mapConfig: null,
    };
  },
  mounted() {
    this.loadMapConfig();
    this.loadPlaces();
  },
  methods: {
    loadMapConfig() {
      api
        .get("/map")
        .then((response) => {
          this.mapConfig = response.data || {};
          this.drawMap();
        })
        .catch(() => {
          this.toast.error("No s'ha pogut carregar la configuració del mapa.");
          this.drawMap();
        });
    },

    loadPlaces() {
      api
        .get("/places")
        .then((response) => {
          const places = response.data || [];

          for (const place of places) {
            this.places[place.id] = place;
            this.originalPlaces[place.id] = place;
          }
        })
        .catch(() => {
          this.toast.error("No s'ha pogut carregar els llocs.");
        });
    },

    drawMap() {
      if (this.map) return;

      const map = L.map(this.$refs.map, {
        center: [39.5183, 3.2238],
        zoom: 11,
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: "Nou lloc",
            callback: this.createPlace,
          },
          {
            separator: true,
          },
          {
            text: "Centrar el mapa",
            callback: this.centerMap,
          },
        ],
        ...(() => {
          const override = {};

          if (this.mapConfig) {
            if (this.mapConfig.latitude && this.mapConfig.longitude) {
              override.center = [
                this.mapConfig.latitude,
                this.mapConfig.longitude,
              ];
            }

            if (this.mapConfig.zoom) {
              override.zoom = this.mapConfig.zoom;
            }
          }

          return override;
        })(),
      });

      map.getRenderer(map).options.padding = 10;

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 30,
        maxNativeZoom: 19,
        attribution:
          '&copy; OSM Mapnik <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);

      map.whenReady(() => {
        this.map = map;
      });
    },

    createPlace(event) {
      const place = {
        id: uuidv4(),
        latitude: event.latlng.lat,
        longitude: event.latlng.lng,
      };

      Modal.openPlaceMarkerModal({ place })
        .then(({ place }) => {
          if (!place.name) return;
          this.places[place.id] = place;
        })
        .catch();
    },

    onPlaceUpdated(place) {
      this.places[place.id] = place;
    },

    onPlaceDeleted(place) {
      delete this.places[place.id];
    },

    centerMap(e) {
      this.map?.panTo(e.latlng);
    },
  },
};
</script>
