<template>
  <kbd
    class="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg"
  >
    <slot></slot>
  </kbd>
</template>

<script>
export default {
  name: "KeyboardHelper",
};
</script>
