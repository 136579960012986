import { SplashScreen } from "@capacitor/splash-screen";
import { boot as bootAxios } from "./axios";
import "./leaflet";
import "./openServiceModal";

await SplashScreen.hide();

export default (app) => {
  bootAxios(app);
};
