<template>
  <div class="hidden">
    <div
      ref="tooltip"
      class="font-semibold bg-white/70 rounded px-1"
      :style="{ color: entity.color ?? '#3388FF' }"
    >
      {{ entity.code }}
    </div>
  </div>
</template>

<script>
import L from "leaflet";

export default {
  name: "MapPolygon",
  props: ["layer", "entity", "model_uri"],
  data() {
    return {
      polygon: null,
    };
  },
  watch: {
    entity: {
      deep: true,
      handler() {
        if (!this.polygon) {
          this.draw();
          return;
        }

        const coordinates = this.getCoordinates();

        if (coordinates) {
          this.polygon.setLatLngs(coordinates);
        } else {
          this.undraw();
          return;
        }

        this.polygon.setStyle({
          color: this.entity.color,
        });

        this.polygon.unbindTooltip();
        this.addTooltip(this.polygon);
      },
    },
  },
  mounted() {
    if (this.entity?.enabled && this.entity?.polygon) {
      this.draw();
    }
  },
  beforeUnmount() {
    this.undraw();
  },
  methods: {
    draw() {
      if (this.polygon) return;
      if (!this.entity?.enabled) return;

      const coordinates = this.getCoordinates();
      if (!coordinates) return;

      const polygon = L.polygon(coordinates, {
        interactive: true, // necessary to be able to delete
        color: "#3388FF",
        weight: 2,
        ...(this.entity.color ? { color: this.entity.color } : null),
      });

      polygon.properties = {
        model: {
          uri: this.model_uri,
        },
        id: this.entity.id,
      };

      this.addTooltip(polygon);

      polygon.addTo(this.layer);

      this.polygon = polygon;
    },

    undraw() {
      if (this.polygon) {
        this.polygon.remove();
        this.polygon = null;
      }
    },

    getCoordinates() {
      try {
        var coordinates = JSON.parse(this.entity.polygon);
      } catch (error) {}

      if (coordinates && Array.isArray(coordinates)) {
        return coordinates;
      }

      return null;
    },

    addTooltip(polygon) {
      polygon.bindTooltip(this.$refs.tooltip, {
        permanent: true,
        direction: "center",
        className: "bg-transparent shadow-none border-none",
      });
    },
  },
};
</script>
