<template>
  <layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-6xl">
      <h1 class="text-center text-2xl">
        <translation value="nav.confglobal" />
      </h1>

      <div v-for="key in configs" :key="key" class="mt-2 space-y-2">
        <label class="block my-4" v-if="typeof config[key] === 'boolean'">
          <input
            type="checkbox"
            class="focus:ring-blue-500 focus:border-blue-500 p-2 mr-2 border-gray-300 rounded-md disabled:bg-gray-200 disabled:animate-pulse"
            v-model="config[key]"
            @keypress.enter="save"
            :disabled="loading"
          />
          <span class="text-gray-700">
            <translation :value="`config.${key}`" />
          </span>
        </label>
        <label class="block" v-else>
          <span class="text-gray-700">
            <translation :value="`config.${key}`" />
          </span>
          <input
            type="number"
            class="focus:ring-blue-500 focus:border-blue-500 block w-full px-3 py-1 border-gray-300 rounded-md disabled:bg-gray-200 disabled:animate-pulse"
            min="1"
            max="86400"
            v-model="config[key]"
            @keypress.enter="save"
            :disabled="loading"
          />
        </label>
      </div>

      <div class="mt-4 flex justify-end items-center">
        <htc-button
          is="button"
          color="green"
          class="font-normal px-3 h-7"
          @click="save"
        >
          <translation value="button.save" />
        </htc-button>
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";
import { useToast } from "vue-toastification";
import HtcButton from "@/components/HtcButton.vue";

export default {
  name: "ConfigIndex",
  components: {
    LayoutMain,
    Translation,
    HtcButton,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      loading: true,
      config: {},
    };
  },
  computed: {
    configs() {
      return Object.keys(this.config).filter(
        (config) => !["id", "createdAt", "updatedAt"].includes(config),
      );
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      api
        .get("/config")
        .then((response) => {
          this.config = response.data || {};
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("No s'ha pogut carregar les dades.");
        });
    },

    save() {
      api
        .put("/config", this.config)
        .then((response) => {
          this.toast.success("Èxit!");
        })
        .catch(() => {
          this.toast.error("No s'ha pogut guardar.");
        });
    },
  },
};
</script>
