<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2><translation value="nav.llocshabituals" /></h2>
      </div>

      <button class="flex items-center" @click="modal.reject" tabindex="-1">
        <keyboard-helper class="mr-1">Esc</keyboard-helper>
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>
    <div class="mt-2">
      <input
        type="text"
        tabindex="1"
        placeholder="Cerca..."
        v-model="searchQuery"
        @keyup.up="prevInput"
        @keyup.down="nextInput"
        ref="searchPlacesInput"
        class="w-full px-3 py-1 text-left rounded-md hover:bg-stone-100 mb-2"
      />
      <button
        v-for="place in places"
        :key="place.id"
        class="w-full px-3 py-1 text-left flex justify-between rounded-md hover:bg-stone-100 group"
        @click="choose(place)"
        @keyup.enter="choose(place)"
        tabindex="2"
        @keyup.up="prevInput"
        @keyup.down="nextInput"
      >
        <div>{{ place.name }}</div>
        <SelectorIcon class="w-6 h-6 hidden group-focus:block" />
      </button>
    </div>
  </div>
</template>

<script>
import { XIcon, SelectorIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import KeyboardHelper from "@/components/KeyboardHelper.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "PlacesSelector",
  components: {
    XIcon,
    SelectorIcon,
    HtcButton,
    Translation,
    KeyboardHelper,
  },
  props: ["modal"],
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    places() {
      return (this.modal.payload?.places || []).filter((p) => {
        return p.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    choose(place) {
      this.modal.resolve({
        place: place,
      });
    },
    nextInput(e) {
      const next = e.currentTarget.nextElementSibling;
      if (next) {
        next.focus();
      }
    },
    prevInput(e) {
      const prev = e.currentTarget.previousElementSibling;
      if (prev) {
        prev.focus();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.searchPlacesInput.focus();
    });
  },
};
</script>
