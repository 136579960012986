<template>
  <div class="w-[95vw] max-w-xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          {{ title }}
        </h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <p class="mt-2">{{ text }}</p>

    <div class="mt-4 h-8 flex justify-end gap-1">
      <button
        class="px-4 text-white bg-red-500 rounded-lg text-sm"
        @click="cancel"
      >
        <translation value="button.cancel" />
      </button>

      <button
        class="px-4 text-white bg-emerald-500 rounded-lg text-sm"
        @click="confirm"
      >
        <translation value="button.confirm" />
      </button>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import ReservaComponent from "@/components/ReservaComponent.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "ConfirmModal",
  components: {
    XIcon,
    ReservaComponent,
    Translation,
  },
  props: ["modal"],
  computed: {
    title() {
      return this.modal.payload?.title;
    },
    text() {
      return this.modal.payload?.text;
    },
  },
  methods: {
    cancel() {
      this.modal.reject();
    },
    confirm() {
      this.modal.resolve();
    },
  },
};
</script>
