<template>
  <div class="hidden">
    <div ref="tooltip" class="flex flex-col justify-center items-center gap-px">
      <div
        class="text-base font-semibold bg-white/70 rounded px-1"
        :style="{ color: station.color ?? '#3388FF' }"
      >
        {{ station.code }}
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";

export default {
  name: "MapStation",
  props: ["layer", "station", "stationTaxis"],
  data() {
    return {
      polygon: null,
    };
  },
  computed: {
    taxiCount() {
      return Object.values(this.stationTaxis || {}).length;
    },
  },
  watch: {
    station: {
      deep: true,
      handler() {
        if (!this.polygon) {
          this.draw();
          return;
        }

        const coordinates = this.getCoordinates();

        if (coordinates) {
          this.polygon.setLatLngs(coordinates);
        } else {
          this.undraw();
          return;
        }

        this.polygon.setStyle({
          color: this.station.color,
        });

        this.polygon.unbindTooltip();
        this.addTooltip(this.polygon);
      },
    },
  },
  mounted() {
    if (this.station?.enabled && this.station?.polygon) {
      this.draw();
    }
  },
  beforeUnmount() {
    this.undraw();
  },
  methods: {
    draw() {
      if (this.polygon) return;
      if (!this.station?.enabled) return;

      const coordinates = this.getCoordinates();
      if (!coordinates) return;

      const polygon = L.polygon(coordinates, {
        interactive: true, // necessary to be able to delete
        color: "#3388FF",
        weight: 2,
        ...(this.station.color ? { color: this.station.color } : null),
      });

      polygon.properties = {
        model: {
          uri: "stations",
        },
        id: this.station.id,
      };

      this.addTooltip(polygon);

      polygon.addTo(this.layer);

      this.polygon = polygon;
    },

    undraw() {
      if (this.polygon) {
        this.polygon.remove();
        this.polygon = null;
      }
    },

    getCoordinates() {
      try {
        var coordinates = JSON.parse(this.station.polygon);
      } catch (error) {}

      if (coordinates && Array.isArray(coordinates)) {
        return coordinates;
      }

      return null;
    },

    addTooltip(polygon) {
      polygon.bindTooltip(this.$refs.tooltip, {
        permanent: true,
        direction: "center",
        className: "bg-transparent shadow-none border-none",
      });
    },
  },
};
</script>
