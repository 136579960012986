<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2>{{ amEditing ? "Editar" : "Crear" }} lloc habitual</h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <div class="mt-2 space-y-2">
      <label class="block">
        <span class="text-gray-700">
          <translation value="Name" />
        </span>
        <input
          type="text"
          class="focus:ring-blue-500 focus:border-blue-500 block w-full px-3 py-1 border-gray-300 rounded-md"
          v-model.trim="place.name"
          @keypress.enter="save"
        />
      </label>
    </div>

    <div class="mt-4 flex justify-end items-center">
      <htc-button
        is="button"
        color="green"
        class="font-normal px-3 h-7"
        @click="save"
      >
        Guardar
      </htc-button>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "PlaceMarkerModal",
  components: {
    XIcon,
    HtcButton,
    Translation,
  },
  props: ["modal"],
  data() {
    return {
      place: {
        ...this.modal.payload?.place,
      },
    };
  },
  computed: {
    amEditing() {
      return !!this.modal.payload?.place?.name;
    },
  },
  methods: {
    save() {
      this.modal.resolve({
        place: this.place,
      });
    },
  },
};
</script>
