<template>
  <layout-driver :hideHeader="true">
    <router-link
      :to="{ name: 'driver-main' }"
      class="absolute z-20 top-3 left-3 pl-6 pr-8 py-6 text-2xl flex items-center gap-1 bg-taxis-blue rounded-md shadow-md"
      replace
    >
      <ChevronLeftIcon class="w-10 h-10" />
      <translation value="nav.back" />
    </router-link>

    <map-component :clean="true" :coords="coords" :zoom="17" />
  </layout-driver>
</template>

<script>
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import LayoutDriver from "../LayoutDriver.vue";
import MapComponent from "@/components/Map/MapComponent.vue";
import Tracking from "@/tracking/Tracking";
import Translation from "@/translations/Translation.vue";

export default {
  name: "DriverMap",
  components: {
    ChevronLeftIcon,
    LayoutDriver,
    MapComponent,
    Translation,
  },
  data() {
    return {
      coords: [],
      zoom: 17,
    };
  },
  created() {
    const lastLocation = Tracking.lastLocation;

    if (lastLocation) {
      this.coords = [
        lastLocation.coords.latitude,
        lastLocation.coords.longitude,
      ];

      this.zoom = 17;
    }
  },
};
</script>
