<template>
  <layout-driver :hideHeader="true">
    <main-header />
    <div class="relative">
      <router-link
        :to="{ name: 'driver-main' }"
        class="absolute top-2 left-2 pl-6 pr-8 py-6 text-2xl flex items-center gap-1 bg-taxis-blue rounded-md shadow-md"
        replace
      >
        <ChevronLeftIcon class="w-10 h-10" />
        <translation value="nav.back" />
      </router-link>
    </div>
    <div class="bg-gray-300">
      <h1 class="mt-28 mb-5 text-center font-semibold text-2xl text-black">
        <translation value="generic.service" /> nº {{ service.service_number }}
      </h1>

      <div
        v-if="service && status !== 'assigned'"
        class="flex flex-col text-black"
      >
        <div class="mx-auto w-10/12 max-w-md space-y-1">
          <div v-if="service.pickupAt" class="font-semibold">
            <div class="text-base leading-none text-gray-600">
              <translation value="form.pickupAt" />
            </div>
            <div class="text-lg leading-none truncate">
              {{ service.pickupAt }}
            </div>
          </div>

          <div v-if="service.name" class="font-semibold">
            <div class="text-base leading-none text-gray-600">
              <translation value="form.name" />
            </div>
            <div class="text-lg leading-none truncate">{{ service.name }}</div>
          </div>

          <div v-if="service.room" class="font-semibold">
            <div class="text-base leading-none text-gray-600">
              <translation value="reservation.room" />
            </div>
            {{ service.room }}
          </div>

          <div v-if="service.phone" class="font-semibold">
            <div class="text-base leading-none text-gray-600">
              <translation value="form.phone" />
            </div>
            <a
              :href="'tel:' + service.phone"
              class="block text-lg leading-none truncate"
            >
              {{ service.phone }}
            </a>
          </div>

          <div v-if="service.people" class="font-semibold">
            <div class="text-base leading-none text-gray-600">
              <translation value="form.pplqty" />
            </div>
            <div class="text-lg leading-none truncate">
              {{ service.people }}
            </div>
          </div>

          <div v-if="service.message" class="font-semibold">
            <div class="text-base leading-none text-gray-600">
              <translation value="form.message" />
            </div>
            <div class="text-lg leading-none truncate">
              {{ service.message }}
            </div>
          </div>
        </div>

        <div
          class="mt-5 py-5 text-black"
          :class="[status === 'accepted' && 'text-black bg-gray-300']"
        >
          <div class="mx-auto w-10/12 max-w-md">
            <div class="flex justify-between items-start text-xl leading-none">
              <h3 class="font-semibold">
                <translation value="services.phase.pickup" />
              </h3>

              <div class="font-semibold">
                {{ timeSinceAccepted || timeTakenPickup }}
              </div>
            </div>

            <div class="mt-2 font-semibold">
              <div
                v-if="service.pickupAt"
                class="text-lg leading-none text-gray-600"
              >
                <translation value="form.pickupAt" />
              </div>
              {{ service.pickupAt }}

              <div
                v-if="service.pickupInfo"
                class="text-lg leading-none text-gray-600"
              >
                <translation value="reservation.infopickupzone" />
              </div>
              {{ service.pickupInfo }}

              <div class="text-lg leading-none text-gray-600">
                <translation value="form.pickup" />
              </div>
              <a
                :href="pickupUrl"
                target="_blank"
                class="block text-xl leading-none truncate"
              >
                {{ service.pickupName }}
              </a>
            </div>

            <div class="mt-12 flex items-end justify-end">
              <button
                class="flex items-center justify-end gap-1 font-semibold text-xl px-4 py-1 bg-green-700 rounded-md disabled:bg-transparent disabled:text-gray-500"
                :disabled="buttonSafety || status !== 'accepted'"
                @click="pickup"
              >
                <UserIcon class="w-6 h-6" />
                <translation value="button.mark-pickup" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="py-5 text-black"
          :class="[status === 'pickedup' && 'text-black bg-gray-300']"
        >
          <div class="mx-auto w-10/12 max-w-md">
            <div class="flex justify-between items-start text-xl leading-none">
              <h3 class="font-semibold">
                <translation value="services.phase.dropoff" />
              </h3>

              <div class="font-semibold">
                {{ timeSincePickup }}
              </div>
            </div>

            <div class="mt-2 font-semibold">
              <div class="font-semibold">
                <div
                  v-if="service.dropoffInfo"
                  class="text-lg leading-none text-gray-700"
                >
                  <translation value="reservation.infodropoffzone" />
                </div>
                {{ service.dropoffInfo }}

                <div class="text-lg leading-none text-gray-600">
                  <translation value="form.dropoff" />
                </div>
                <a
                  v-if="service.dropoffName"
                  :href="dropoffUrl"
                  target="_blank"
                  class="block text-xl leading-none truncate"
                >
                  {{ service.dropoffName }}
                </a>
                <div v-else class="text-xl leading-none truncate">
                  <translation value="undefined" />
                </div>
              </div>
            </div>

            <div class="mt-12 flex items-end justify-end">
              <button
                class="flex items-center justify-end gap-1 font-semibold text-xl px-4 py-1 bg-green-700 rounded-md disabled:bg-transparent disabled:text-gray-500"
                :disabled="buttonSafety || status !== 'pickedup'"
                @click="handleDropOff"
              >
                <FlagIcon class="w-6 h-6" />
                <translation value="button.mark-dropoff" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-driver>
</template>

<script>
import { ChevronLeftIcon, FlagIcon, UserIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapActions, mapState } from "vuex";
import LayoutDriver from "../LayoutDriver.vue";
import MainHeader from "../Main/components/MainHeader.vue";
import moment from "moment";

export default {
  name: "ServicesCurrent",
  components: {
    ChevronLeftIcon,
    FlagIcon,
    UserIcon,
    Translation,
    LayoutDriver,
    MainHeader,
  },
  data() {
    return {
      buttonSafety: true,
      timeSinceAccepted: null,
      timeSincePickup: null,

      intervals: {
        timeSinceAccepted: null,
        timeSincePickup: null,
      },
    };
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),

    taxiState() {
      return this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },

    pickupUrl() {
      return `https://maps.google.com/?q=${this.service.pickupLat},${this.service.pickupLng}`;
    },

    dropoffUrl() {
      return `https://maps.google.com/?q=${this.service.dropoffLat},${this.service.dropoffLng}`;
    },

    timeTakenPickup() {
      if (this.status !== "pickedup") {
        return "--:--";
      }

      let diff =
        moment(this.assignment.pickedupAt).unix() -
        moment(this.assignment.acceptedAt).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      return (
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0")
      );
    },
  },
  watch: {
    status: {
      immediate: true,
      handler(status) {
        if (status === "accepted") {
          if (this.intervals.timeSinceAccepted == null) {
            this.updateTimeSinceAccepted();

            this.intervals.timeSinceAccepted = setInterval(
              () => this.updateTimeSinceAccepted(),
              1000,
            );
          }
        } else {
          if (this.intervals.timeSinceAccepted != null) {
            clearInterval(this.intervals.timeSinceAccepted);
            this.intervals.timeSinceAccepted = null;
            this.timeSinceAccepted = null;
          }
        }

        if (status === "pickedup") {
          if (this.intervals.timeSincePickup == null) {
            this.updateTimeSincePickup();

            this.intervals.timeSincePickup = setInterval(
              () => this.updateTimeSincePickup(),
              1000,
            );
          }
        } else {
          if (this.intervals.timeSincePickup != null) {
            clearInterval(this.intervals.timeSincePickup);
            this.intervals.timeSincePickup = null;
            this.timeSincePickup = null;
          }
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.buttonSafety = false;
    }, 1000);
  },
  beforeUnmount() {
    for (const interval of Object.values(this.intervals)) {
      clearInterval(interval);
    }
  },
  methods: {
    ...mapActions(["pickup", "dropoff"]),
    handleDropOff() {
      const sent = this.dropoff();

      if (sent) {
        this.$router.replace({ name: "driver-main" });
      }
    },
    updateTimeSinceAccepted() {
      if (!this.assignment) return;

      let diff = moment().unix() - moment(this.assignment.acceptedAt).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      this.timeSinceAccepted =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },

    updateTimeSincePickup() {
      if (!this.assignment) return;

      let diff = moment().unix() - moment(this.assignment.pickedupAt).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      this.timeSincePickup =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },
  },
};
</script>
