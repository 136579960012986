<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 pb-2 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="serviceslog.filter.title" />
        </h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>
    <div class="p-2 text-gray-700">
      <div class="grid grid-cols-3 gap-4 px-3 py-2">
        <div>
          <label>
            <div><translation value="services.status" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.status"
            >
              <option :value="null">-</option>
              <option v-for="st in statusOptions" :key="st" :value="st">
                <translation :value="st" />
              </option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="generic.zone" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.zone"
            >
              <option :value="null">-</option>
              <option v-for="zn in zoneOptions" :key="zn.code" :value="zn.code">
                <translation :value="zn.code" />
              </option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="generic.zoneAlt" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.zoneAlt"
            >
              <option :value="null">-</option>
              <option v-for="zn in zoneOptions" :key="zn.code" :value="zn.code">
                <translation :value="zn.code" />
              </option>
            </select>
          </label>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 px-3 py-2">
        <div>
          <div class="flex items-center">
            <translation value="services.filter.datestart" />:
          </div>
          <htc-input-field
            type="datetime-local"
            class="w-full"
            v-model:value="pickedValues.start"
          />
        </div>
        <div>
          <div class="flex items-center">
            <translation value="services.filter.dateend" />:
          </div>
          <htc-input-field
            type="datetime-local"
            :min="pickedValues.start"
            :disabled="!pickedValues.start"
            class="disabled:bg-gray-200 w-full"
            v-model:value="pickedValues.end"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 px-3 py-2">
        <div>
          <label>
            <div><translation value="taxi.assigned" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.taxi"
            >
              <option :value="null">-</option>
              <option v-for="tx in taxiOptions" :key="tx.code" :value="tx.code">
                {{ tx.code }}
              </option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="status.level" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.level"
            >
              <option :value="null">-</option>
              <option v-for="lv in levelOptions" :key="lv" :value="lv">
                <translation :value="lv" />
              </option>
            </select>
          </label>
        </div>
      </div>

      <div class="flex items-center justify-between pt-6">
        <htc-button is="button" color="dark" class="h-9" @click="clearFilter">
          <translation value="services.filter.reset" />
        </htc-button>
        <htc-button is="button" color="green" class="h-9" @click="save">
          <translation value="services.filter.save" />
        </htc-button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";

export default {
  name: "ServicesFilter",
  components: {
    XIcon,
    Translation,
    HtcButton,
    HtcInputField,
  },
  props: ["modal"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      row: this.modal.payload.row,
      pickedValues: {
        status: null,
        start: null,
        end: null,
        taxi: null,
        zone: null,
        zoneAlt: null,
        level: null,
      },
      filters: {},
      statusOptions: ["ASSIGNED", "INPROGRESS", "FINALIZED"],
      taxiOptions: [],
      zoneOptions: [],
      levelOptions: ["LEVEL_1", "LEVEL_2"],
    };
  },
  methods: {
    makeFilter() {
      let filter = {};

      if (this.pickedValues.status) {
        if (this.pickedValues.status !== "ASSIGNED") {
          filter.status = this.pickedValues.status;
        } else {
          filter.OR = [{ status: "ASSIGNED" }, { status: "ACCEPTED" }];
        }
      }

      if (this.pickedValues.start && this.pickedValues.end) {
        filter.createdAt = {
          lte: new Date(this.pickedValues.end),
          gte: new Date(this.pickedValues.start),
        };
      }

      if (this.pickedValues.taxi) {
        filter.taxi = { is: { code: this.pickedValues.taxi } };
      }

      if (this.pickedValues.zone) {
        filter.zone = { is: { code: this.pickedValues.zone } };
      }

      if (this.pickedValues.zoneAlt) {
        filter.zoneAlt = { is: { code: this.pickedValues.zoneAlt } };
      }

      if (this.pickedValues.level) {
        filter.nivel = this.pickedValues.level;
      }

      if (Object.keys(filter).length > 0) {
        this.filters = filter;
      }
    },
    save() {
      this.makeFilter();
      this.modal.resolve({
        filters: this.filters,
        modalValues: this.pickedValues,
      });
    },
    clearFilter() {
      this.filters = {};
      this.pickedValues = {
        status: null,
        start: null,
        end: null,
        taxi: null,
        zone: null,
        zoneAlt: null,
        level: null,
      };

      this.modal.resolve({
        filters: this.filters,
        modalValues: this.pickedValues,
      });
    },
  },
  created() {
    if (this.modal.payload.modalValues) {
      this.pickedValues = this.modal.payload.modalValues;
    }

    api
      .get("/taxis", {
        params: {
          page: 0,
          size: 100000,
          sortCol: "code",
          sortDir: "asc",
        },
      })
      .then((response) => {
        this.taxiOptions = [...response.data.results];
      });

    api
      .get("/zones", {
        params: {
          page: 0,
          size: 100000,
          sortCol: "code",
          sortDir: "asc",
        },
      })
      .then((response) => {
        this.zoneOptions = [...response.data.results];
      });
  },
};
</script>
