<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="button.create" />
          {{ " " }}
          <translation value="generic.municipality" :lowercase="true" />
        </template>

        <template v-else>
          <translation value="generic.municipality" />: {{ data?.code }}
        </template>
      </h1>

      <div class="mt-10">
        <label>
          <div><translation value="generic.code" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.code"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="Color" />:</div>
          <htc-input-field
            type="color"
            v-model:value="form.color"
            class="w-14 disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label class="mt-3 flex items-center gap-2">
          <input
            type="checkbox"
            v-model="form.enabled"
            class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
          <span>
            <translation value="form.enabled" />
          </span>
        </label>
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="button.save" />
          <translation v-else value="button.create" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";

export default {
  name: "MunicipalitiesEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      editMode: false,

      data: null,
      form: {
        code: "",
        color: "#3388ff",
        enabled: true,
      },
    };
  },
  watch: {
    "form.color"(value) {
      if (!value) {
        this.form.color = "#3388ff";
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loading = true;

      this.loadData();
    }
  },
  methods: {
    loadData() {
      api
        .get("/municipalities/" + this.id)
        .then((response) => {
          this.data = { ...response.data };
          this.form = { ...response.data };
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "municipalities-index" });
        });
    },

    submit() {
      if (this.editMode) {
        api
          .put("/municipalities/" + this.id, this.form)
          .then(() => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "municipalities-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        api
          .post("/municipalities", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "municipalities-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
  },
};
</script>
