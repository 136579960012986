<template>
  <div
    class="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8"
  >
    <div class="flow-root">
      <ul role="list" class="divide-y divide-gray-200">
        <li
          class="py-3 sm:py-4"
          v-for="row in rows"
          :key="row.id"
          :class="[loading && 'animate-pulse']"
        >
          <div class="flex items-center mx-4">
            <div class="flex-1 min-w-0 ms-4">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ row.area.code }}
              </p>
              <p class="text-sm text-gray-500 truncate">
                {{ formatTimestamp(row.updatedAt, false) }}
              </p>
            </div>
            <div
              class="inline-flex items-center text-base font-semibold text-gray-900"
            >
              <translation :value="row.level" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import { debounce } from "lodash";
import HtcButton from "@/components/HtcButton.vue";
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";

export default {
  name: "ServicesTable",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    PencilIcon,
    TrashIcon,
    Translation,
    HtcInputField,
    HtcButton,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      interval: null,
      total: null,
      size: 9999,
      page: 0,
      sort: {
        col: "areaId",
        dir: 1,
      },
      search: "",
      rows: [],
      loading: true,
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.lang,
    }),
  },
  created() {
    this.loadData();
    this.interval = setInterval(this.loadData, 30 * 1000);
  },
  methods: {
    async loadData() {
      this.loading = true;

      const response = await api
        .get("/status?type=area", {
          params: {
            size: this.size,
            page: this.page,
            sortCol: this.sort.col,
            sortDir: this.sort.dir ? "desc" : "asc",
            search: this.search,
          },
        })
        .catch(() => {});

      if (response?.status !== 200) {
        alert("No se pudo cargar la información del servidor.");
      }

      this.total = response.data.total;
      this.rows = response.data.results;

      this.loading = false;
    },

    updateSearch: debounce(function (value) {
      this.search = value;
      this.page = 0;
      this.loadData();
    }, 500),
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>
