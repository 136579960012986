<template>
  <client-layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-xl">
      <h2 class="text-center text-2xl">Reserva Taxi</h2>

      <reserva-component />
    </div>
  </client-layout-main>
</template>

<script>
import ClientLayoutMain from "./layout/ClientLayoutMain.vue";
import ReservaComponent from "./components/ReservaComponent.vue";

export default {
  name: "ClientReserva",
  components: {
    ClientLayoutMain,
    ReservaComponent,
  },
};
</script>
