<template>
  <div
    id="loading-state"
    :style="
      isLoadingActive
        ? 'display: flex; justify-content: center; align-items: center;'
        : 'display: none;'
    "
  >
    <div>
      <span class="loader"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingState",
  props: ["isLoadingActive"],
};
</script>

<style>
#loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  color: white;
}

.loader {
  width: 4rem;
  height: 4rem;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
