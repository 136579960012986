<template>
  <button class="z-10 absolute top-2 right-2" @click="open">
    <BoxIcon name="cog" />
  </button>
</template>

<script>
import Modal from "@/modals/Modal";
import { api } from "@/boot/axios";
import { useToast } from "vue-toastification";
import store from "@/store";

export default {
  name: "MapSettings",
  props: ["map", "config"],
  emits: ["update:config"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  methods: {
    open() {
      Modal.openMapSettings({
        map: this.map,
        config: this.config,
      })
        .then(({ mapConfig }) => {
          if (mapConfig) {
            this.$emit("update:config", mapConfig);
            this.apiSave(mapConfig);
          }
        })
        .catch(() => {});
    },

    apiSave(mapConfig) {
      api
        .put("/map", mapConfig)
        .then(() => {
          this.toast.success("Actualitzat correctament.");
        })
        .catch(() => {
          this.toast.error("No s'ha pogut actualitzar.");
        });

      store.commit("mapConfig", mapConfig);
    },
  },
};
</script>
