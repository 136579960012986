<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="button.create" />
          {{ " " }}
          <translation value="generic.client" :lowercase="true" />
        </template>

        <template v-else>
          <translation value="generic.client" />: {{ original?.name }}
        </template>
      </h1>

      <div class="mt-10">
        <label>
          <div><translation value="generic.code" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.code"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="form.email" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.email"
            required="required"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="generic.password" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.password"
            :placeholder="editMode ? '••••••' : ''"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="generic.name" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.name"
            required="required"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="form.phone" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.phone"
            required="required"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>Direcció:</div>
          <div>
            <htc-input-field
              type="text"
              v-model:value="searchTerm"
              @input="search"
              placeholder="Cercar..."
              required="required"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
            <ul
              v-show="showResults"
              style="
                position: relative;
                background-color: #ffffff;
                border: 2px;
                border-color: #e8f0fe;
              "
            >
              <li
                style="
                  cursor: pointer;
                  padding: 5px;
                  &.sel {
                    font-weight: bolder;
                  }
                "
                v-for="result in results"
                :key="result.place_id"
                @click="selectResult(result)"
              >
                {{ result.display_name }}
              </li>
            </ul>
          </div>
          <div>
            <div class="mt-4 mb-4" v-if="this.showMap">
              Marcar punt de recollida:
            </div>
            <div id="mapEdit"></div>
            <div class="mt-4 mb-4" v-if="this.form.location.lat > 0">
              <div><b>Lat:</b> {{ form.location.lat }}</div>
              <div><b>Lon:</b> {{ form.location.lng }}</div>
            </div>
          </div>
        </label>

        <label>
          <div><translation value="generic.clientType" />:</div>
          <select
            v-model="form.typeId"
            required="required"
            class="w-full block px-3 py-1 border-gray-300 rounded-md shadow-sm focus:border-taxis-orange focus:ring focus:ring-taxis-orange/25 focus:ring-opacity-50 disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          >
            <option :value="null">
              {{ "—" }}
            </option>
            <option v-for="type in types" :key="type.id" :value="type.id">
              {{ type.name }}
            </option>
          </select>
        </label>

        <div class="mt-2">
          <span> <translation value="client.active" />: </span>
        </div>

        <label class="flex items-center gap-2">
          <input
            type="checkbox"
            v-model="form.active"
            class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
          <span>
            <translation value="active" />
          </span>
        </label>
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="button.save" />
          <translation v-else value="button.create" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";
import L from "leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "ClientsEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: true,
      editMode: false,
      original: null,
      types: [],
      searchTerm: "",
      results: [],
      showResults: false,
      showMap: false,
      lat: 39.6137,
      lon: 2.9787,
      map: null,
      zoom: 8,
      form: {
        name: null,
        code: null,
        phone: null,
        email: null,
        password: null,
        address: null,
        location: {
          lat: 0,
          lng: 0,
        },
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loading = true;
      this.zoom = 15;
      this.loadAll();
    } else {
      this.zoom = 8;
      this.loadTypes();
    }
  },
  methods: {
    loadAll() {
      Promise.all([
        api
          .get("/clients/" + this.id)
          .then((response) => {
            response.data.password = "";
            this.original = { ...response.data };
            this.form = { ...response.data };
            this.form.active = this.original.active == 1 ? true : false;
            this.searchTerm = this.original.address;
            this.lat = this.original.location.lat;
            this.lon = this.original.location.lng;
            this.form.location.lat = this.original.location.lat;
            this.form.location.lng = this.original.location.lng;
            this.setMarker();
            this.showMap = true;
          })
          .catch(() => {
            this.$router.replace({ name: "clients-index" });
          }),
        api
          .get("/client-types/", {
            params: {
              page: 0,
              size: 100,
              sortCol: "name",
              sortDir: "asc",
            },
          })
          .then((response) => {
            this.types = [...response.data.results];
          }),
      ]).then(() => {
        this.loading = false;
      });
    },

    loadTypes() {
      api
        .get("/client-types", {
          params: {
            page: 0,
            size: 100,
            sortCol: "name",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.types = [...response.data.results];
          this.loading = false;
        });
    },

    submit() {
      this.form.active = this.form.active ? 1 : 0;
      if (this.editMode) {
        api
          .put("/clients/" + this.id, this.form)
          .then((response) => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "clients-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        api
          .post("/clients", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "clients-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
    search() {
      if (this.searchTerm !== "" && this.loading == false) {
        var url = `https://nominatim.limit.es/search?q=${this.searchTerm}`;
        api.get(url).then((response) => {
          this.results = response.data;
          this.showResults = true;
        });
      } else {
        this.showResults = false;
      }
    },
    selectResult(result) {
      this.searchTerm = result.display_name;
      this.form.address = result.display_name;
      this.showResults = false;
      this.showMap = true;
      this.lat = result.lat;
      this.lon = result.lon;
      this.form.location.lat = result.lat;
      this.form.location.lng = result.lon;

      this.map.setView([this.lat, this.lon], this.zoom);

      // Añadir la marca
      const markerIcon = new Icon({
        iconUrl: require("@/assets/marker-icon.png"),
        iconRetinaUrl: require("@/assets/marker-icon-2x.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [0, -35],
        shadowUrl: require("@/assets/marker-shadow.png"),
        shadowSize: [41, 41],
      });
      const marker = L.marker([this.lat, this.lon], { draggable: true }).addTo(
        this.map,
      );
      marker.on("dragend", (e) => {
        this.form.location = e.target.getLatLng();
      });
      marker.bindPopup(this.searchTerm).openPopup();
    },
    setMarker() {
      this.map = L.map("mapEdit").setView([this.lat, this.lon], this.zoom);

      // Añadir la capa de OpenStreetMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(this.map);

      const marker = L.marker([this.lat, this.lon], {
        draggable: true,
      }).addTo(this.map);
      marker.on("dragend", (e) => {
        this.form.location = e.target.getLatLng();
      });
      marker.bindPopup(this.searchTerm).openPopup();
    },
    initMap() {
      this.map = L.map("mapEdit").setView([this.lat, this.lon], this.zoom);

      // Añadir la capa de OpenStreetMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(this.map);
    },
  },
  mounted() {
    if (!this.id) {
      this.initMap();
    }
    //this.initMap();
  },
};
</script>
<style>
#mapEdit {
  height: 400px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
