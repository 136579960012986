<template>
  <div class="p80 flex items-center justify-center bg-taxis-blue">
    <div class="p-8 w-full max-w-lg shadow-lg rounded-lg bg-white">
      <form
        class="w-full flex flex-col justify-start items-center"
        @submit.prevent="register"
      >
        <img class="h-16" src="@/assets/logo.png" alt="logo" />
        <div class="mb-4 mt-6 w-full space-y-2">
          <div class="text-center text-md">Formulari de registre</div>
          <hr />

          <label>
            <div class="mt-4">
              <translation value="form.email" />:
              <span class="text-gray-500 text-sm"
                >(<translation value="required" />)</span
              >
            </div>
            <htc-input-field
              type="text"
              maxlength="255"
              v-model:value="form.email"
              required="required"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </label>

          <label>
            <div>
              <translation value="form.password" />:
              <span class="text-gray-500 text-sm">
                (<translation value="required" />)
              </span>
            </div>
            <htc-input-field
              type="password"
              maxlength="255"
              v-model:value="form.password"
              required="required"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </label>

          <label>
            <div>
              <translation value="form.client_name" />:
              <span class="text-gray-500 text-sm"
                >(<translation value="required" />)</span
              >
            </div>
            <htc-input-field
              type="text"
              maxlength="255"
              v-model:value="form.name"
              required="required"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </label>

          <label>
            <div><translation value="taxi.phone" />:</div>
            <htc-input-field
              type="text"
              v-model:value="form.phone"
              required="required"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </label>
          <!--
          <label>
            <div>Direcció:</div>
            <htc-input-field
              type="text"
              v-model:value="form.address"
              required="required"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </label>
-->

          <label>
            <div>Direcció:</div>
            <div>
              <htc-input-field
                type="text"
                v-model:value="searchTerm"
                @input="search"
                placeholder="Cercar..."
                required="required"
                class="w-full disabled:bg-gray-200 disabled:animate-pulse"
              />
              <ul
                v-show="showResults"
                style="
                  position: relative;
                  background-color: #ffffff;
                  border: 2px;
                  border-color: #e8f0fe;
                "
              >
                <li
                  style="
                    cursor: pointer;
                    padding: 5px;
                    &.sel {
                      font-weight: bolder;
                    }
                  "
                  v-for="result in results"
                  :key="result.place_id"
                  @click="selectResult(result)"
                >
                  {{ result.display_name }}
                </li>
              </ul>
            </div>
            <div>
              <div class="mt-4 mb-4" v-if="this.showMap">
                Marcar punt de recollida:
              </div>
              <div id="map"></div>
              <div class="mt-4 mb-4" v-if="this.form.location.lat > 0">
                <div><b>Lat:</b> {{ form.location.lat }}</div>
                <div><b>Lon:</b> {{ form.location.lng }}</div>
              </div>
            </div>
          </label>

          <label>
            <div><translation value="generic.clientType" />:</div>
            <select
              v-model="form.typeId"
              required="required"
              class="w-full block px-3 py-1 border-gray-300 rounded-md shadow-sm focus:border-taxis-orange focus:ring focus:ring-taxis-orange/25 focus:ring-opacity-50 disabled:bg-gray-200 disabled:animate-pulse"
            >
              <option :value="null">
                {{ "—" }}
              </option>
              <option v-for="type in types" :key="type.id" :value="type.id">
                {{ type.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="mt-8 w-full flex justify-end">
          <button
            type="submit"
            class="px-5 py-1 text-sm font-semibold rounded-md shadow-sm border outline-none ring-taxis-orange/25 border-transparent bg-blue-100 text-taxis-blue focus:text-taxis-orange focus:bg-transparent focus:ring focus:border-taxis-orange"
          >
            <translation value="Enviar" />
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";
import { useToast } from "vue-toastification";

import L from "leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "Register",
  components: {
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      form: {
        name: null,
        code: null,
        phone: null,
        email: null,
        password: null,
        address: null,
        location: {
          lat: 0,
          lng: 0,
        },
      },
      types: [],
      searchTerm: "",
      results: [],
      showResults: false,
      showMap: false,
      lat: 39.6137,
      lon: 2.9787,
      map: null,
      marker: null,
    };
  },
  created() {
    this.loadTypes();
  },
  methods: {
    loadTypes() {
      api
        .get("/client-types", {
          params: {
            page: 0,
            size: 100,
            sortCol: "name",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.types = [...response.data.results];
        });
    },

    register() {
      api
        .post("/clients", this.form)
        .then((response) => {
          if (response.data.errors) {
            this.validateErrors(response.data.errors);
            return;
          }
          this.toast.success("Registrat correctament.");
          this.$router.replace({ name: "login" });
        })
        .catch((errors) => {
          console.log(errors);
          this.toast.error("No s'ha pogut registrar.");
        });
    },
    validateErrors(errors) {
      console.log(errors);
    },
    search() {
      if (this.searchTerm !== "") {
        var url = `https://nominatim.limit.es/search?q=${this.searchTerm}`;

        api.get(url).then((response) => {
          this.results = response.data;
          this.showResults = true;
        });
      } else {
        this.showResults = false;
      }
    },
    selectResult(result) {
      if (this.marker) this.map.removeLayer(this.marker);

      this.searchTerm = result.display_name;
      this.form.address = result.display_name;
      this.showResults = false;
      this.showMap = true;
      this.lat = result.lat;
      this.lon = result.lon;
      this.form.location.lat = result.lat;
      this.form.location.lng = result.lon;

      this.map.setView([this.lat, this.lon], 17);

      // Añadir la marca
      const markerIcon = new Icon({
        iconUrl: require("@/assets/marker-icon.png"),
        iconRetinaUrl: require("@/assets/marker-icon-2x.png"),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [0, -35],
        shadowUrl: require("@/assets/marker-shadow.png"),
        shadowSize: [41, 41],
      });

      this.marker = L.marker([this.lat, this.lon], { draggable: true }).addTo(
        this.map,
      );
      this.marker.on("dragend", (e) => {
        this.form.location = e.target.getLatLng();
      });
      this.marker.bindPopup(this.searchTerm).openPopup();
    },
  },
  mounted() {
    this.map = L.map("map").setView([this.lat, this.lon], 8);

    // Añadir la capa de OpenStreetMap
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      maxZoom: 18,
    }).addTo(this.map);
  },
};
</script>
<style>
#map {
  height: 200px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.p80 {
  padding: 80px;
}
</style>
