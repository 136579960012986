<template>
  <router-view />
</template>

<script>
import { api } from "./boot/axios";
import Connection from "./ws/Connection";
import { App } from "@capacitor/app";
import router from "./router";
import { NativeAudio } from "@capacitor-community/native-audio";

export default {
  name: "App",
  components: {
    NativeAudio,
  },
  watch: {
    $route(to, from) {
      if (window.innerWidth < 1024) {
        this.$store.commit("toggleSidebar", false);
      }

      if (to.name !== "login" && !this.$store.state.token) {
        if (to.name == "register") {
          this.$router.replace({ name: "register" });
        } else {
          this.$router.replace({ name: "login" });
        }
      }

      if (process.env.NODE_ENV !== "development") {
        if (Array.isArray(to.meta?.allowedRoles)) {
          const isAdmin = this.$store.state.isAdmin;
          const isClient = this.$store.state.isClient;

          if (isAdmin) {
            if (!to.meta.allowedRoles.includes("admin")) {
              this.$router.replace({ name: "home" });
            }
          } else {
            if (isClient) {
              if (!to.meta.allowedRoles.includes("client")) {
                this.$router.replace({ name: "client-main" });
              }
            } else {
              if (!to.meta.allowedRoles.includes("driver")) {
                this.$router.replace({ name: "driver-main" });
              }
            }
          }
        }
      }
    },
  },
  created() {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (!err?.response?.status) {
          return Promise.reject(err);
        }

        if (err.response.status === 401) {
          this.$store.commit("token", null);
          Connection.terminate();
          this.$router.replace({ name: "login" });
        }

        return Promise.reject(err);
      },
    );

    this.$store.dispatch("init");

    App.addListener("backButton", (event) => {
      router.back();
    });

    /* Sounds */
    NativeAudio.preload({
      assetId: "online",
      assetPath: "public/assets/sounds/online_sound.mp3",
      audioChannelNum: 1,
      isUrl: false,
    });

    NativeAudio.preload({
      assetId: "beep",
      assetPath: "public/assets/sounds/notification.mp3",
      audioChannelNum: 1,
      isUrl: false,
    });
    /**/
  },
};
</script>
