<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="button.create" />
          {{ " " }}
          <translation value="generic.client-type" :lowercase="true" />
        </template>

        <template v-else>
          <translation value="generic.client-type" />: {{ original?.name }}
        </template>
      </h1>

      <div class="mt-10">
        <label>
          <div><translation value="generic.code" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.name"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="button.save" />
          <translation v-else value="button.create" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";

export default {
  name: "ClientTypesEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      editMode: false,

      original: null,
      form: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loading = true;
      this.loadAll();
    }
  },
  methods: {
    loadAll() {
      Promise.all([
        api
          .get("/client-types/" + this.id)
          .then((response) => {
            this.original = { ...response.data };
            this.form = { ...response.data };
          })
          .catch(() => {
            this.$router.replace({ name: "client-types-index" });
          }),
      ]).then(() => {
        this.loading = false;
      });
    },

    loadInfo() {
      api
        .get("/client-types/" + this.id)
        .then((response) => {
          this.original = { ...response.data };
          this.form = { ...response.data };
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "client-types-index" });
        });
    },

    submit() {
      if (this.editMode) {
        api
          .put("/client-types/" + this.id, this.form)
          .then(() => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "client-types-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        api
          .post("/client-types", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "client-types-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
  },
};
</script>
