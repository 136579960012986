<template>
  <layout-driver>
    <template v-if="this.queryServices">
      <div class="m-1 h-[90%]">
        <div class="bg-white text-center text-2xl py-4">
          <translation value="config.serviceszone" />
        </div>
        <div class="bg-white text-center text-4xl py-4">
          <h1>Total</h1>
          <div style="margin-top: 30px">{{ this.queryServices?.length }}</div>
        </div>
        <div>
          <div
            class="absolute bottom-26 w-full flex flex-row overflow-x-auto px-4"
          >
            <service-date-item
              v-for="zone in fullzones"
              :key="zone.id"
              :zone="zone"
              :color="zone.color"
              :services="getZoneServices(zone.id)"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="this.queryTaxis">
      <div class="m-1 h-[90%]">
        <div class="text-center text-white text-2xl">
          <translation value="config.taxiszone" />
        </div>
        <div class="bg-white text-center text-4xl py-4">
          <h1>Total</h1>
          <div style="margin-top: 30px">
            {{ this.queryTaxis.reduce((p, v) => p + v.num, 0) }}
          </div>
        </div>
        <div>
          <div
            class="absolute bottom-26 w-full flex flex-row overflow-x-auto px-4"
          >
            <service-date-item
              v-for="zone in fullzones"
              :key="zone.id"
              :zone="zone"
              :color="zone.color"
              :services="getZoneTaxis(zone.id)"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="flex justify-end">
        <button @click="toggleDisconnect" class="w-7 h-7 mr-5">
          <inline-svg
            :src="require('@/assets/power-button.svg')"
            class="w-7 h-7 fill-current"
            :class="{
              'text-green-500': taxi && taxi.state !== 'offline',
              'text-red-500': !taxi || taxi.state === 'offline',
            }"
          />
        </button>

        <button class="mr-5" @click="logout">
          <LogoutIcon class="w-7 h-7 text-white" />
        </button>
      </div>

      <div class="m-4 text-white">
        <h1 class="text-xl">
          <translation value="config.notices" />
        </h1>

        <div class="m-4 ml-2 text-white">
          <label>
            <div v-if="zones"><translation value="config.zones" />:</div>
            <Multiselect
              v-model="form.zones"
              class="text-black"
              :object="true"
              :resolve-on-load="false"
              :options="zones"
              mode="tags"
              :close-on-select="false"
            />
          </label>
        </div>

        <div class="m-4 ml-2 text-white">
          <div class="grid grid-cols-2 gap-4 my-4">
            <div>
              <label>
                <div v-if="zones"><translation value="config.from" />:</div>
                <htc-input-field
                  type="datetime-local"
                  min="1"
                  max="4"
                  v-model:value="form.noticesFrom"
                  class="w-full text-black my-2"
                />
              </label>
            </div>
            <div>
              <label>
                <div v-if="zones"><translation value="config.to" />:</div>
                <htc-input-field
                  type="datetime-local"
                  min="1"
                  max="4"
                  v-model:value="form.noticesTo"
                  class="w-full text-black my-2"
                />
              </label>
            </div>
          </div>

          <htc-button
            is="button"
            color="green"
            class="font-normal px-3 h-10 w-full"
            @click="checkServices()"
          >
            <translation value="button.consult" />
          </htc-button>
          <htc-button
            is="button"
            color="green"
            class="font-normal px-3 my-2 h-10 w-full"
            @click="checkTaxis()"
          >
            <translation value="map.settings.showZones" />
            &nbsp;
            <span class="lowercase"><translation value="generic.taxi" /></span>
          </htc-button>
        </div>

        <h1 class="text-xl">
          <translation value="config.workingDay" />
        </h1>

        <div class="m-4 ml-2 text-white">
          <span class="mr-2"> <translation value="config.endTime" />: </span>
          <htc-input-field
            type="datetime-local"
            v-model:value="form.scheduleTo"
            class="w-full text-black my-2"
          />
        </div>

        <div class="m-4 ml-2 text-white">
          <label class="m-4 ml-2 gap-2">
            <div><translation value="config.endzone" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md text-black"
              v-model="form.end_zone_id"
            >
              <option
                value="null"
                v-bind:selected="form.end_zone_id == null"
              ></option>
              <option
                v-for="zona in zones"
                v-bind:key="zona.value"
                :value="zona.value"
                v-bind:selected="form.end_zone_id == zona.value"
              >
                {{ zona.label }}
              </option>
            </select>
          </label>
        </div>

        <h1 class="text-xl mb-4 mt-4">
          <translation value="config.device" />
        </h1>

        <div>
          <label class="ml-2 my-1 flex items-center gap-2">
            <input type="checkbox" v-model="form.sound" class="rounded p-2" />

            <span>
              <translation value="config.sound" />
            </span>
          </label>

          <label class="ml-2 my-1 flex items-center gap-2">
            <input type="checkbox" v-model="fullscreen" class="rounded p-2" />

            <span>
              <translation value="config.fullscreen" />
            </span>
          </label>

          <label class="ml-2 my-1 flex items-center gap-2">
            <input type="checkbox" v-model="keepAwake" class="rounded p-2" />

            <span>
              <translation value="config.keepawake" />
            </span>
          </label>

          <label class="ml-2 my-2 flex items-center gap-2">
            <div><translation value="config.orientation" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md text-black"
              v-model="orientation"
            >
              <option value="auto">Auto</option>
              <option value="portrait">Vertical</option>
              <option value="landscape">Horizontal</option>
            </select>
          </label>

          <label class="ml-2 my-2 flex items-center gap-2">
            <div>Idioma:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md text-black"
              v-model="lang"
            >
              <option value="ca">Català</option>
              <option value="es">Castellano</option>
            </select>
          </label>
        </div>
      </div>
    </template>
  </layout-driver>
</template>

<script>
import Translation from "@/translations/Translation.vue";
import LayoutDriver from "../LayoutDriver.vue";
import ServiceDateItem from "../Main/components/ServiceDateItem.vue";
import store from "@/store";
import { LogoutIcon } from "@heroicons/vue/outline";
import InlineSvg from "vue-inline-svg";
import { mapState } from "vuex";
import Connection from "@/ws/Connection";
import Modal from "@/modals/Modal";
import Translate from "@/translations/Translate";
import Multiselect from "@vueform/multiselect";
import { api } from "@/boot/axios";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import moment from "moment";
import { useToast } from "vue-toastification";

export default {
  name: "DriverConfig",
  components: {
    LayoutDriver,
    Translation,
    InlineSvg,
    LogoutIcon,
    Multiselect,
    HtcInputField,
    HtcButton,
    ServiceDateItem,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      endTime: null,
      zones: [],
      fullzones: [],
      timeFormat: "HH:mm",
      queryServices: null,
      queryTaxis: null,
      form: {
        noticesFrom: null,
        noticesTo: null,
        zones: [],
        scheduleTo: null,
        end_zone_id: null,
      },
    };
  },
  watch: {
    form: {
      handler: function (v) {
        this.save();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["user", "taxi", "serverTime", "serviceTaxi"]),
    fullscreen: {
      get() {
        return store.state.driver.fullscreen;
      },
      set(value) {
        store.commit("fullscreen", value);
      },
    },

    keepAwake: {
      get() {
        return store.state.driver.keepAwake;
      },
      set(value) {
        store.commit("keepAwake", value);
      },
    },

    orientation: {
      get() {
        return store.state.driver.orientation;
      },
      set(value) {
        store.commit("orientation", value);
      },
    },

    lang: {
      get() {
        return store.state.lang;
      },
      set(lang) {
        store.commit("lang", lang);
      },
    },
  },
  created() {
    this.loadzones();
    this.loadTaxiConfig();
  },
  methods: {
    goToMain() {
      if (this.$route.name !== "driver-main") {
        this.$router.replace({ name: "driver-main" });
      }
    },
    toggleDisconnect() {
      if (!this.taxi) return;

      if (this.taxi.state !== "offline") {
        Connection.send({
          type: "Driver",
          action: "State",
          state: "offline",
        });
      } else {
        Connection.send({
          type: "Driver",
          action: "State",
          state: "online",
        });
      }
    },

    logout() {
      Modal.openConfirmModal({
        title: Translate.value("txt.confirm-logout-title").text,
        text: Translate.value("txt.confirm-logout-text").text,
      })
        .then(() => {
          this.$router.replace({ name: "logout" });
        })
        .catch(() => {});
    },

    loadzones() {
      api
        .get("/zones", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.fullzones = [...response.data.results];
          this.fullzones?.map((item) => {
            this.zones.push({
              value: item.id,
              label: item.code,
            });
          });
        })
        .catch((e) => {
          console.log(e);
          this.$router.replace({ name: "driver-main" });
        });
    },
    loadTaxiConfig() {
      api
        .get("/taxiconfig", {
          params: {
            taxiId: this.taxi?.id,
          },
        })
        .then((response) => {
          this.taxiConfig = response.data;

          this.form.sound = response.data.sound == 1 ? true : false;

          this.form.noticesFrom = moment(response.data.noticesFrom).format(
            "YYYY-MM-DD HH:mm:ss",
          );
          this.form.noticesTo = moment(response.data.noticesTo).format(
            "YYYY-MM-DD HH:mm:ss",
          );
          this.form.scheduleTo = moment(response.data.scheduleTo).format(
            "YYYY-MM-DD HH:mm:ss",
          );

          this.form.end_zone_id = this.taxiConfig.end_zone_id;

          this.taxiConfig?.zones.map((item) => {
            this.form.zones.push({
              value: item.value,
              label: item.label,
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    save() {
      if (!this.taxi?.id) return;
      this.form.taxiId = this.taxi.id;

      console.log(this.form);
      api
        .put("/taxiconfig", this.form)
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
          this.toast.error(Translate.value("config.saveError").text);
        });
    },
    checkServices() {
      api
        .post("/servicesdate", this.form)
        .then((response) => {
          this.queryServices = response.data;
        })
        .catch((e) => {
          this.toast.error(Translate.value("config.datesError").text);
        });
    },
    checkTaxis() {
      api
        .get("/zone/taxis")
        .then((response) => {
          this.queryTaxis = response.data;
        })
        .catch((e) => {
          this.toast.error(Translate.value("config.datesError").text);
        });
    },
    getZoneServices(zoneId) {
      var ZoneServices = Object.values(this.queryServices);

      ZoneServices = ZoneServices?.filter(
        (service) => service.pickupZoneId === zoneId,
      );

      return ZoneServices.length;
    },
    getZoneTaxis(zoneId) {
      for (const zoneTaxis of this.queryTaxis) {
        if (zoneTaxis["id"] === zoneId) {
          return zoneTaxis["num"];
        }
      }
      return 0;
    },
  },
  mounted() {},
};
</script>
