import logger from "@/logger";
import router from "@/router";
import store from "@/store";
import { Msg } from "../../../backend/src/ws/msgtypes";
import { now } from "lodash";
import Driver from "./Driver";
import { Listener } from "./listener_types";
import Service from "./Service";
import Station from "./Station";
import Status from "./Status";
import Tracking from "@/tracking/Tracking";

class Connection {
  static ws?: WebSocket;
  static authenticated: boolean = false;
  static aliveAt?: number = null;
  static establishedAt?: number = null;

  static listeners: Listener[] = [];
  static listenersIds: number = 0;

  static init() {
    setInterval(() => {
      if (!store.state.token) return;

      if (this.ws) return;

      console.log("ws", "reconnecting");

      try {
        this.connect();
      } catch (error) {}
    }, 3000);
  }

  static connect() {
    if (this.ws instanceof WebSocket) {
      this.terminate();
    }

    if (!store.state.token) return;

    let url = process.env.VUE_APP_WS_URL;

    if (!url) {
      url = location.protocol.startsWith("https") ? "wss://" : "ws://";
      url += location.host + "/websocket";
    }

    this.ws = new WebSocket(url);

    this.ws.onopen = () => {
      this.send({
        type: "Auth",
        token: store.state.token,
      });

      this.ws.onmessage = this.onMessage.bind(this);

      this.ws.onclose = (e) => {
        logger("ws", "disconnected");

        this.terminate();
      };

      Tracking.current();
    };

    this.ws.onerror = () => {
      this.terminate();
    };
  }

  static terminate() {
    this.ws?.close();
    this.ws = null;

    this.authenticated = false;
    this.aliveAt = null;
    this.establishedAt = null;

    store.commit("networkState", "offline");
  }

  static onMessage(ev: MessageEvent) {
    this.aliveAt = now();

    let data: any = ev.data;

    try {
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
    } catch (error) {}

    logger("received", data);

    if (typeof data !== "object") return;

    const msg: Msg = data;

    this.fireEvent(msg);

    if (msg.type === "AuthApprove") {
      this.authenticated = true;
      store.commit("networkState", "online");
      this.fireEvent("ready");
    }

    if (msg.type === "AuthReject") {
      store.commit("token", null);
      router.replace("login");
      this.close();
    }

    if (msg.type === "Ping") {
      this.send({
        type: "Pong",
      });
    }

    if (msg.type === "Driver") {
      Driver.handle(msg);
    }

    if (msg.type === "Service") {
      Service.handle(msg);
    }

    if (msg.type === "Stations") {
      Station.handle(msg);
    }

    if (msg.type === "Status") {
      Status.handle(msg);
    }
  }

  static send(msg: Msg): boolean {
    if (
      this.ws?.readyState !== 1 ||
      (!this.authenticated && msg.type !== "Auth")
    ) {
      logger("not send", msg);
      return false;
    }

    // logger("send", msg);
    this.ws.send(JSON.stringify(msg));
    return true;
  }

  static close() {
    this.ws?.close();
    this.ws = null;
    this.aliveAt = null;
    this.establishedAt = null;
  }

  static isReady(): boolean {
    return this.authenticated;
  }

  static addListener(event: Listener["event"], listener: Listener["listener"]) {
    if (event === "ready" && this.isReady()) {
      listener();
    }

    const id = ++this.listenersIds;

    this.listeners.push({
      id: id,
      event: event,
      listener: listener,
    });

    return id;
  }

  static removeListener(id?: number) {
    if (typeof id !== "number") return;

    const index = this.listeners.findIndex((listener) => listener.id === id);
    if (index > -1) this.listeners.splice(index, 1);
  }

  static fireEvent(event: string | Msg) {
    for (const listener of this.listeners) {
      if (listener.event === event) {
        listener.listener(typeof event === "object" ? event : null);
        continue;
      }

      if (typeof event === "object") {
        const props = Object.keys(listener.event);

        let match = true;

        for (const prop of props) {
          if (event[prop] !== listener.event[prop]) {
            match = false;
          }
        }

        if (match) {
          listener.listener(event);
        }
      }
    }
  }
}

Connection.init();

/** @ts-ignore */
window.Connection = Connection;

export default Connection;
