<template>
  <button
    class="flex items-center gap-1 text-gray-700 bg-white p-1 rounded-lg font-semibold shadow"
    @click="open"
  >
    <DocumentAddIcon class="w-6 h-6" />
    <translation :value="translationVal" />
    <keyboard-helper v-if="!reserva" class="ml-2">F4</keyboard-helper>
  </button>
</template>

<script>
import { DocumentAddIcon } from "@heroicons/vue/outline";
import Modal from "@/modals/Modal";
import { useToast } from "vue-toastification";
import KeyboardHelper from "@/components/KeyboardHelper.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "MapNewService",
  components: {
    DocumentAddIcon,
    Translation,
    KeyboardHelper,
  },
  props: {
    map: {},
    mapConfig: {},
    reserva: {
      type: Boolean,
      default: false,
    },
    leftSpace: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      translationVal: "generic.service",
    };
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  methods: {
    open() {
      Modal.openServiceNewModal({
        reservaForm: this.reserva,
      })
        .then(() => {})
        .catch(() => {});
    },
  },
  mounted() {
    if (this.reserva) {
      this.translationVal = "nav.reserva";
    }
  },
};
</script>
