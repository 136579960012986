<template>
  <div class="relative w-full h-full">
    <div ref="map" class="z-10 w-full h-full select-none"></div>

    <template v-if="map">
      <map-settings :map="map" v-model:config="mapConfig" />

      <map-new-service v-if="editable" :map="map" :mapConfig="mapConfig" />

      <template v-for="trip in Object.values(trips)" :key="trip.id">
        <map-trip
          v-if="trip.taxi.state !== 'offline'"
          :map="map"
          :trip="trip"
          :filters="filters"
          :stationTaxi="stationTaxis[trip.taxi.id]"
        />
      </template>

      <map-person
        v-for="service in services"
        :key="service.id"
        :map="map"
        :service="service"
        :filters="filters"
      />
    </template>
  </div>
</template>

<script>
import MapPolygon from "./components/MapPolygon.vue";
import MapPerson from "./components/MapPerson.vue";
import MapTrip from "./components/MapTrip.vue";
import MapSettings from "./components/MapSettings.vue";
import MapStats from "./components/MapStats.vue";
import { useToast } from "vue-toastification";
import MapEditing from "./MapEditing.vue";
import Connection from "@/ws/Connection";
import { api } from "@/boot/axios";
import { cloneDeep, pickBy, uniq } from "lodash";
import MapNewService from "./components/MapNewService.vue";
import MapStation from "./components/MapStation.vue";
import Modal from "@/modals/Modal";

const L = window.L;

export default {
  name: "MapComponent",
  components: {
    MapSettings,
    MapStats,
    MapPolygon,
    MapPerson,
    MapTrip,
    MapEditing,
    MapNewService,
    MapStation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    coords: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
    },
  },
  data() {
    const filtersDefault = {
      taxis: {
        taxisOnline: true,
        taxisAvailable: true,
        taxisInService: true,
        taxisOffline: true,
      },

      services: {
        servicesWaiting: true,
        servicesInProgress: true,
        servicesFuture: true,
      },

      routes: {},
    };

    return {
      id: sessionStorage.userId,
      map: null,
      editableLayer: null,

      municipalities: {},
      areas: {},
      zones: {},
      stations: {},
      services: {},
      cantares: {},
      trips: {},
      stationTaxis: {},

      mapConfig: {},

      filtersDefault: filtersDefault,
      filters: cloneDeep(filtersDefault),

      listeners: {
        ready: null,
        map: null,
      },
    };
  },
  computed: {
    polygonLayer() {
      return this.editable ? this.editableLayer : this.map;
    },

    polygonIds() {
      const polygons = [
        ...Object.values(this.municipalities),
        ...Object.values(this.areas),
        ...Object.values(this.zones),
        ...Object.values(this.stations),
      ];

      const excludedIds = [];

      for (const polygon of polygons) {
        if (polygon.polygon) {
          excludedIds.push(polygon.id);
        }
      }

      return uniq(excludedIds);
    },

    getStationTaxisByStation() {
      return (station) => {
        return pickBy(
          this.stationTaxis,
          (stationTaxi) => stationTaxi.stationId === station?.id,
        );
      };
    },

    getStationTaxisByTaxi() {
      return (taxi) => {
        return pickBy(
          this.stationTaxis,
          (stationTaxi) => stationTaxi.taxiId === taxi?.id,
        );
      };
    },

    pendingServices() {
      return Object.values(this.services).filter(
        (service) => service.createdBy == this.id,
      );
    },
  },
  created() {
    this.loadMapConfig();

    this.listeners.ready = Connection.addListener("ready", () => {
      Connection.send({
        type: "Map",
        action: "Subscribe",
      });
    });

    this.listeners.map = Connection.addListener(
      { type: "Map", action: "Data" },
      (msg) => {
        this.processData(msg);
      },
    );
  },
  beforeUnmount() {
    Connection.removeListener(this.listeners.ready);
    Connection.removeListener(this.listeners.map);

    if (Connection.isReady()) {
      Connection.send({
        type: "Map",
        action: "Unsubscribe",
      });
    }
  },
  methods: {
    loadMapConfig() {
      api
        .get("/map")
        .then((response) => {
          this.mapConfig = response.data || {};
          this.drawMap();
        })
        .catch(() => {
          this.toast.error("No s'ha pogut carregar el mapa.");
          this.drawMap();
        });
    },

    resetFilters() {
      this.filters = cloneDeep(this.filtersDefault);
    },

    drawMap() {
      if (this.map) return;

      const map = L.map(this.$refs.map, {
        center: [39.5183, 3.2238],
        zoom: 11,
        // attributionControl: false,
        contextmenu: true,
        contextmenuWidth: 140,
        contextmenuItems: [
          {
            text: "Centrar el mapa",
            callback: (e) => {
              this.map?.panTo(e.latlng);
            },
          },
          {
            text: "Copiar coordenades",
            callback: (e) => {
              navigator.clipboard.writeText(e.latlng.lat + ", " + e.latlng.lng);
            },
          },
          {
            text: "Nou servei aquí",
            callback: (e) => {
              Modal.openServiceNewModal({
                pickupLatLng: {
                  latitude: e.latlng.lat,
                  longitude: e.latlng.lng,
                },
              }).catch((e) => {
                // Nothing to do
              });
            },
          },
        ],
        ...(() => {
          const override = {};

          if (this.mapConfig) {
            if (this.mapConfig.latitude && this.mapConfig.longitude) {
              override.center = [
                this.mapConfig.latitude,
                this.mapConfig.longitude,
              ];
            }

            if (this.mapConfig.zoom) {
              override.zoom = this.mapConfig.zoom;
            }
          }

          if (this.coords.length === 2) {
            override.center = [...this.coords];

            if (this.zoom) {
              override.zoom = this.zoom;
            }
          }

          return override;
        })(),
      });

      map.getRenderer(map).options.padding = 10;

      /*if (!this.editable)*/ map.zoomControl.setPosition("bottomright");

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 30,
        maxNativeZoom: 19,
        attribution:
          '&copy; OSM Mapnik <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);

      map.whenReady(() => {
        this.map = map;
      });
    },

    processData(msg) {
      if (this.isPropObject(msg.municipality)) {
        this.municipalities[msg.municipality.id] = msg.municipality;
      }

      if (this.isPropObject(msg.municipalities)) {
        this.municipalities = msg.municipalities;
      }

      if (typeof msg.delMunicipality === "string") {
        delete this.municipalities[msg.delMunicipality];
      }

      if (this.isPropObject(msg.area)) {
        this.areas[msg.area.id] = msg.area;
      }

      if (this.isPropObject(msg.areas)) {
        this.areas = msg.areas;
      }

      if (typeof msg.delArea === "string") {
        delete this.areas[msg.delArea];
      }

      if (this.isPropObject(msg.zone)) {
        this.zones[msg.zone.id] = msg.zone;
      }

      if (this.isPropObject(msg.zones)) {
        this.zones = msg.zones;
      }

      if (typeof msg.delZone === "string") {
        delete this.zones[msg.delZone];
      }

      if (this.isPropObject(msg.station)) {
        this.stations[msg.station.id] = msg.station;
      }

      if (this.isPropObject(msg.stations)) {
        this.stations = msg.stations;
      }

      if (typeof msg.delStation === "string") {
        delete this.stations[msg.delStation];
      }

      if (this.isPropObject(msg.service)) {
        this.services[msg.service.id] = msg.service;
      }

      if (this.isPropObject(msg.services)) {
        this.services = msg.services;
      }

      if (typeof msg.delServiceTaxi === "string") {
        delete this.services[msg.delServiceTaxi];
      }

      if (this.isPropObject(msg.cantar)) {
        this.cantares[msg.cantar.id] = msg.cantar;
      }

      if (this.isPropObject(msg.cantares)) {
        this.cantares = msg.cantares;
      }

      if (typeof msg.delCantar === "string") {
        delete this.cantares[msg.delCantar];
      }

      if (this.isPropObject(msg.trip)) {
        this.trips[msg.trip.taxi.id] = msg.trip;
      }

      if (this.isPropObject(msg.trips)) {
        this.trips = msg.trips;
      }

      if (typeof msg.delTrip === "string") {
        delete this.trips[msg.delTrip];
      }

      if (this.isPropObject(msg.stationTaxi)) {
        this.stationTaxis[msg.stationTaxi.taxiId] = msg.stationTaxi;
      }

      if (this.isPropObject(msg.stationTaxis)) {
        this.stationTaxis = msg.stationTaxis;
      }

      if (typeof msg.delStationTaxi === "string") {
        delete this.stationTaxis[msg.delStationTaxi];
      }
    },
  },
};
</script>
