<template>
  <layout-driver :hideHeader="false">
    <div
      class="w-full h-full absolute bg-black min-h-screen"
      :class="[
        ((taxiState === 'online' || taxiState === 'busy') && 'bg-gray-300') ||
          'bg-red-400',
      ]"
    >
      <template v-if="taxiState != 'offline'">
        <div class="gap-1 text-gray-800 font-semibold mt-2">
          <div>
            <div v-if="!zoneSelected" class="grid grid-cols-3 gap-2 break-all">
              <button
                class="font-semibold px-4 py-2 rounded-md text-2xl"
                :style="{ 'background-color': zone.color }"
                v-for="zone in zones"
                :key="zone.id"
                @click="selectZone(zone.id)"
              >
                {{ zone.code }}
              </button>
            </div>
            <div v-else class="grid grid-cols-3 gap-2 break-all">
              <button
                class="font-semibold px-4 py-2 rounded-md text-2xl"
                :style="{ 'background-color': sector.color }"
                v-for="sector in zoneSectors"
                :key="sector.id"
                @click="selectSector(sector.id)"
              >
                {{ sector.code }}
              </button>
            </div>
          </div>
          <div v-if="!zoneSelected" class="w-full h-full text-center mt-2">
            <button
              class="w-full h-full items-center font-semibold px-4 py-2 rounded-md text-4xl"
              style="background-color: #fdadc6"
              @click="airport"
            >
              <translation value="button.airport" />
            </button>
          </div>
          <div v-if="!zoneSelected" class="w-full h-full text-center mt-2">
            <button
              class="w-full h-full items-center font-semibold px-4 py-2 rounded-md text-4xl bg-yellow-400"
            >
              <router-link
                :to="{ name: 'driver-destination-mapservice' }"
                replace
              >
                <translation value="generic.mapa" />
              </router-link>
            </button>
          </div>
          <div class="w-full h-full text-center mt-2">
            <button
              class="w-full h-full items-center font-semibold px-4 py-2 bg-red-700 rounded-md text-6xl"
            >
              <router-link :to="{ name: 'driver-main' }" replace>
                <translation value="button.cancel" />
              </router-link>
            </button>
          </div>
        </div>
      </template>

      <template v-else>
        <div
          class="h-32 mt-6 flex flex-col justify-center items-center text-gray-800 font-semibold text-2xl"
        >
          <div class="flex items-center gap-1">
            <ExclamationIcon class="w-8 h-8" />
            <translation value="services.offline" />
          </div>

          <div class="mt-4">
            <button
              class="flex items-center font-semibold px-4 py-2 bg-green-700 rounded-md"
              @click="connect"
            >
              <translation value="button.connect" />
            </button>
          </div>
        </div>
      </template>
    </div>
  </layout-driver>
</template>

<script>
import {
  ClockIcon,
  ExclamationIcon,
  ChevronLeftIcon,
} from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapGetters, mapState } from "vuex";
import { api } from "@/boot/axios";
import Connection from "@/ws/Connection";
import Destination from "@/ws/Destination";
import Modal from "@/modals/Modal";
import Translate from "@/translations/Translate";
import LayoutDriver from "../../LayoutDriver.vue";

export default {
  name: "ServiceTile",
  components: {
    ChevronLeftIcon,
    ClockIcon,
    ExclamationIcon,
    LayoutDriver,
    Translation,
    Modal,
  },

  data() {
    return {
      zones: {},
      sectors: {},
      zoneSectors: {},
      zoneSelected: null,
      sectorSelected: null,
      dropoffLat: null,
      dropoffLng: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),
    ...mapGetters(["isOffline"]),

    taxiState() {
      return !this.isOffline && this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },
  },

  created() {
    this.loadZones();
    this.loadSectors();
  },

  methods: {
    connect() {
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },

    airport() {
      if (!this.taxi) return;
      this.dropoffLat = 39.54771078468776;
      this.dropoffLng = 2.730735540390015;
      this.updateDestination();
    },

    loadZones() {
      api
        .get("/zones", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.zones = [...response.data.results];
        });
    },

    loadSectors() {
      api
        .get("/sectors", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.sectors = [...response.data.results];
        });
    },

    selectZone(id) {
      this.zoneSectors = this.sectors.filter((sector) => sector.zoneId === id);
      console.log(this.zoneSectors?.length);
      if (this.zoneSectors?.length == 1) {
        this.selectSector(this.zoneSectors[0]?.id);
      } else {
        this.zoneSelected = id;
      }
    },

    selectSector(id) {
      this.sectorSelected = id;
      var sector = this.sectors.filter((sector) => sector.id === id);
      this.zoneSelected = this.zoneSelected ? this.zoneSelected : sector.zoneId;
      (this.dropoffLat = sector[0]?.lat),
        (this.dropoffLng = sector[0]?.lng),
        this.updateDestination();
    },

    async updateDestination() {
      if (!this.taxi) return;

      Modal.openConfirmModal({
        title: Translate.value("nav.serveis").text,
        text: Translate.value("txt.confirm-destination").text,
      })
        .then(async () => {
          const cantado = await Destination.create(
            this.serviceTaxi?.service.id,
            this.taxi.id,
            this.zoneSelected,
            this.sectorSelected,
            this.serviceTaxi?.service.pickupLat,
            this.serviceTaxi?.service.pickupLng,
            this.dropoffLat,
            this.dropoffLng,
          );

          this.$router.push({ name: "driver-main" });
        })
        .catch(() => {});
    },
  },
};
</script>
