import axios from "axios";

window.axios = axios;

const api = axios.create({ baseURL: process.env.VUE_APP_API_URL || "/api" });

const boot = (app) => {
  app.config.globalProperties.$api = api;
};

export { boot, api };
