import store from "@/store";

class Modal {
  static lastId = 0;

  static open(component, payload, closeOutside = true) {
    const id = ++this.lastId;

    return new Promise((resolve, reject) => {
      store.commit("addModal", {
        id,
        component,
        payload,
        closeOutside,
        resolve: (value) => {
          store.commit("removeModal", id);
          resolve(value);
        },
        reject: (value) => {
          store.commit("removeModal", id);
          reject(value);
        },
      });
    });
  }

  static openPolygonCreate(payload) {
    return this.open("PolygonCreate", payload);
  }

  static openMapSettings(payload) {
    return this.open("MapSettings", payload);
  }

  static openServiceNewModal(payload) {
    return this.open("ServiceNewModal", payload, false);
  }

  static openPlaceMarkerModal(payload) {
    return this.open("PlaceMarkerModal", payload);
  }

  static openPlacesChangesModal(payload) {
    return this.open("PlacesChangesModal", payload);
  }

  static openPlacesSelector(payload) {
    return this.open("PlacesSelector", payload);
  }

  static openMapSelectorModal(payload) {
    return this.open("MapSelectorModal", payload);
  }

  static openConfirmModal(payload) {
    return this.open("ConfirmModal", payload);
  }
}

export default Modal;
