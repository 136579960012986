<template>
  <template>
    <map-taxi
      v-if="getTrip"
      :map="map"
      :taxi="getTrip.taxi"
      :location="getTrip.taxiLocation"
      :serviceTaxi="getTrip.serviceTaxi"
      :stationTaxi="stationTaxi"
    />

    <map-flag
      v-if="
        getTrip &&
        trip.serviceTaxi?.assignment &&
        trip.serviceTaxi?.assignment.status === 'pickedup'
      "
      :map="map"
      :serviceTaxi="trip.serviceTaxi"
      :filters="filters"
    />

    <map-person
      v-else-if="getTrip && trip.serviceTaxi?.assignment"
      :map="map"
      :service="trip.serviceTaxi.service"
      :serviceTaxi="trip.serviceTaxi"
      :filters="filters"
    />

    <map-route
      v-if="getTrip && trip.route && showTaxi && !mapConfig?.config?.hideRoutes"
      :map="map"
      :serviceTaxi="trip.serviceTaxi"
      :route="trip.route"
      :filters="filters"
    />
  </template>
</template>

<script>
import MapTaxi from "./MapTaxi.vue";
import MapFlag from "./MapFlag.vue";
import MapPerson from "./MapPerson.vue";
import MapRoute from "./MapRoute.vue";

export default {
  name: "MapTrip",
  components: {
    MapTaxi,
    MapFlag,
    MapPerson,
    MapRoute,
  },
  props: ["map", "trip", "filters", "stationTaxi", "mapConfig"],
  computed: {
    config() {
      return this.mapConfig.config;
    },
    getTrip() {
      if (this.trip.taxi.id == this.config.taxiId || !this.config.taxiId)
        return this.trip;
      return null;
    },
    showTaxi() {
      if (this.trip.taxi.state === "disabled") {
        return false;
      }
      if (this.filters.taxis) {
        if (this.filters.taxis.taxisOnline) {
          if (this.trip.taxi.state !== "offline") {
            return true;
          }
        }

        if (this.filters.taxis.taxisAvailable) {
          if (this.trip.taxi.state !== "offline") {
            if (
              !this.trip.serviceTaxi ||
              this.trip.serviceTaxi.assignment.status === "assigned"
            ) {
              return true;
            }
          }
        }

        if (this.filters.taxis.taxisInService) {
          if (this.trip.taxi.state !== "offline") {
            if (
              this.trip.serviceTaxi ||
              this.trip.serviceTaxi.assignment.status !== "assigned"
            ) {
              return true;
            }
          }
        }

        if (this.filters.taxis.taxisOffline) {
          if (this.trip.taxi.state === "offline") {
            return true;
          }
        }
      }

      return false;
    },
  },
};
</script>
