<template>
  <div v-if="Object.keys(changes).length" class="absolute z-10 top-2 right-2">
    <htc-button
      is="button"
      color="green"
      class="font-normal px-3 h-7"
      @click="open"
    >
      <translation value="generic.changes" />
    </htc-button>
  </div>
</template>

<script>
import HtcButton from "@/components/HtcButton.vue";
import { cloneDeep, isEqual, union } from "lodash";
import Modal from "@/modals/Modal";
import { api } from "@/boot/axios";
import { useToast } from "vue-toastification";
import Translation from "@/translations/Translation.vue";

export default {
  name: "PlacesChanges",
  components: {
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  props: ["places", "originalPlaces"],
  emits: ["update:places", "update:originalPlaces"],
  computed: {
    changes() {
      const changes = {};

      const ids = union(
        Object.keys(this.places),
        Object.keys(this.originalPlaces),
      );

      for (const id of ids) {
        if (isEqual(this.places[id], this.originalPlaces[id])) {
          continue;
        }

        if (this.places[id] && this.originalPlaces[id]) {
          changes[id] = {
            id: id,
            type: "updated",
            place: this.places[id],
            oplace: this.originalPlaces[id],
          };
          continue;
        }

        if (this.places[id] && !this.originalPlaces[id]) {
          changes[id] = {
            id: id,
            type: "created",
            place: this.places[id],
          };
          continue;
        }

        if (!this.places[id] && this.originalPlaces[id]) {
          changes[id] = {
            id: id,
            type: "deleted",
            oplace: this.originalPlaces[id],
          };
          continue;
        }
      }

      return changes;
    },
  },
  methods: {
    open() {
      Modal.openPlacesChangesModal({
        changes: this.changes,
        revertedProcessor: this.processReverted,
      })
        .then(({ changes }) => {
          const reverted = Object.values(changes).filter((c) => c.reverted);
          if (reverted.length) this.processReverted(reverted);

          const toSave = Object.values(changes).filter((c) => !c.reverted);
          if (toSave.length) this.save(toSave);
        })
        .catch(() => {});
    },

    processReverted(changes) {
      const places = cloneDeep(this.places);

      for (const change of changes) {
        if (change.type === "created") {
          delete places[change.id];
        } else {
          places[change.id] = change.oplace;
        }
      }

      this.$emit("update:places", places);
    },

    save(changes) {
      api
        .put("/places", changes)
        .then((response) => {
          this.toast.success("Guardat correctament.");

          this.$emit("update:places", response.data);
          this.$emit("update:originalPlaces", response.data);
        })
        .catch(() => {
          this.toast.error("No s'ha pogut guardar.");
        });
    },
  },
};
</script>
