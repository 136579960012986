<template>
  {{ text }}
</template>

<script>
import Translate from "./Translate";

export default {
  props: {
    value: String,
    attributes: Array,
    lowercase: Boolean,
    uppercase: Boolean,
    capitalize: Boolean,
    multicapitalize: Boolean,
    colon: Boolean,
    parentheses: Boolean,
    ellipsis: Boolean,
  },
  computed: {
    text() {
      const lang = this.$store.state.lang;

      const translation = Translate.do(lang, this.value);

      if (this.attributes) translation.attributes(...this.attributes);

      if (this.lowercase) translation.lowercase();

      if (this.uppercase) translation.uppercase();

      if (this.capitalize) translation.capitalize();

      if (this.multicapitalize) translation.multicapitalize();

      if (this.ellipsis) translation.ellipsis();

      let text = translation.text;

      if (this.colon) {
        text += ":";
      }

      if (this.parentheses) {
        text = "(" + text + ")";
      }

      return text;
    },
  },
};
</script>
