<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 pb-2 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="services.filter.title" />
        </h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>
    <div class="p-2 text-gray-700">
      <div class="grid grid-cols-4 gap-4 px-3 py-2">
        <div>
          <label>
            <div><translation value="services.status" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.status"
            >
              <option :value="null">-</option>
              <option v-for="st in statusOptions" :key="st" :value="st">
                <translation :value="st" />
              </option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="generic.tipus" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.serviceType"
            >
              <option :value="null">-</option>
              <option v-for="(tp, idx) in typeOptions" :key="idx" :value="idx">
                <translation :value="tp" />
              </option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="reservation.priority" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.priority"
            >
              <option :value="null">-</option>
              <option :value="true"><translation value="Sí" /></option>
              <option :value="false"><translation value="No" /></option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="services.filter.special" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.especial"
            >
              <option :value="null">-</option>
              <option :value="true"><translation value="Sí" /></option>
              <option :value="false"><translation value="No" /></option>
            </select>
          </label>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 px-3 py-2">
        <div>
          <div class="flex items-center">
            <translation value="services.filter.datestart" />:
          </div>
          <htc-input-field
            type="datetime-local"
            class="w-full"
            v-model:value="pickedValues.start"
          />
        </div>
        <div>
          <div class="flex items-center">
            <translation value="services.filter.dateend" />:
          </div>
          <htc-input-field
            type="datetime-local"
            :min="pickedValues.start"
            :disabled="!pickedValues.start"
            class="disabled:bg-gray-200 w-full"
            v-model:value="pickedValues.end"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 px-3 py-2">
        <div>
          <label>
            <div><translation value="taxi.assigned" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.taxi"
            >
              <option :value="null">-</option>
              <option v-for="tx in taxiOptions" :key="tx.code" :value="tx.code">
                {{ tx.code }}
              </option>
            </select>
          </label>
        </div>
        <div>
          <label>
            <div><translation value="generic.client" />:</div>
            <select
              class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
              v-model="pickedValues.client"
            >
              <option :value="null">-</option>
              <option
                v-for="ct in clientOptions"
                :key="ct.code"
                :value="ct.code"
              >
                <translation :value="ct.code" />
              </option>
            </select>
          </label>
        </div>
      </div>

      <div class="flex items-center justify-between pt-6">
        <htc-button is="button" color="dark" class="h-9" @click="clearFilter">
          <translation value="services.filter.reset" />
        </htc-button>
        <htc-button is="button" color="green" class="h-9" @click="save">
          <translation value="services.filter.save" />
        </htc-button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";

export default {
  name: "ServicesFilter",
  components: {
    XIcon,
    Translation,
    HtcButton,
    HtcInputField,
  },
  props: ["modal"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      row: this.modal.payload.row,
      pickedValues: {
        status: null,
        serviceType: null,
        start: null,
        end: null,
        priority: null,
        especial: null,
        taxi: null,
        client: null,
      },
      filters: {},
      statusOptions: ["waiting", "assigned", "doing", "done"],
      typeOptions: ["services.central", "services.cantado", "services.live"],
      taxiOptions: [],
      clientOptions: [],
    };
  },
  methods: {
    makeFilter() {
      let filter = {};

      switch (this.pickedValues.status) {
        case "done":
          filter.droppedoffAt = { not: null };
          break;
        case "waiting":
          filter.serviceTaxi = { is: { status: "assigned" } };
          break;
        case "assigned":
          filter.serviceTaxi = { is: { status: "accepted" } };
          break;
        case "doing":
          filter.serviceTaxi = { is: { status: "pickedup" } };
          break;
      }

      if (this.pickedValues.serviceType !== null) {
        filter.serviceType = this.pickedValues.serviceType;
      }

      if (this.pickedValues.start && this.pickedValues.end) {
        filter.createdAt = {
          lte: new Date(this.pickedValues.end),
          gte: new Date(this.pickedValues.start),
        };
      }

      if (this.pickedValues.priority !== null) {
        filter.priority = this.pickedValues.priority;
      }

      if (this.pickedValues.especial !== null) {
        filter.especial = this.pickedValues.especial;
      }

      if (this.pickedValues.taxi) {
        filter.taxi = { is: { code: this.pickedValues.taxi } };
      }

      if (this.pickedValues.client) {
        filter.createdByUser = {
          clients: { is: { code: this.pickedValues.client } },
        };
      }

      if (Object.keys(filter).length > 0) {
        this.filters = filter;
      }
    },
    save() {
      this.makeFilter();
      this.modal.resolve({
        filters: this.filters,
        modalValues: this.pickedValues,
      });
    },
    clearFilter() {
      this.filters = {};
      this.pickedValues = {
        status: null,
        serviceType: null,
        start: null,
        end: null,
        priority: false,
        especial: false,
        taxi: null,
        client: null,
      };

      this.modal.resolve({
        filters: this.filters,
        modalValues: this.pickedValues,
      });
    },
  },
  created() {
    if (this.modal.payload.modalValues) {
      this.pickedValues = this.modal.payload.modalValues;
    }

    api
      .get("/taxis", {
        params: {
          page: 0,
          size: 100000,
          sortCol: "code",
          sortDir: "asc",
        },
      })
      .then((response) => {
        this.taxiOptions = [...response.data.results];
      });

    api
      .get("/clients", {
        params: {
          page: 0,
          size: 100000,
          sortCol: "code",
          sortDir: "asc",
        },
      })
      .then((response) => {
        this.clientOptions = [...response.data.results];
      });
  },
};
</script>
