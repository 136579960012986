<template>
  <div
    class="absolute bg-gray-200/80 rounded-md px-2 z-10 bottom-0 select-none"
  >
    <div class="flex gap-2">
      <div
        v-for="area in areas"
        :key="area.id"
        class="flex border gap-1 px-2"
        :class="levelColor(area.level?.level)"
      >
        <div>{{ area.code }}:</div>
        <translation :value="area.level?.level" />
      </div>
    </div>

    <div class="flex gap-5">
      <!-- <button>{{ taxisOnlineQty }} taxis en línia</button> -->

      <button
        :class="
          filter === 'taxisAvailable' ? ['text-blue-500', 'font-semibold'] : ''
        "
        @click="toggleTaxisAvailable"
      >
        {{ taxisAvailableQty }}/{{ taxisOnlineQty }} taxis disponibles
      </button>

      <button
        :class="
          filter === 'servicesWaiting' ? ['text-blue-500', 'font-semibold'] : ''
        "
        @click="toggleServicesWaiting"
      >
        {{ servicesWaitingQty }} serveis pendents
      </button>

      <button
        :class="
          filter === 'servicesInProgress'
            ? ['text-blue-500', 'font-semibold']
            : ''
        "
        @click="toggleServicesInProgress"
      >
        {{ servicesInProgessQty }} serveis en curs
      </button>

      <button
        :class="
          filter === 'servicesFuture' ? ['text-blue-500', 'font-semibold'] : ''
        "
        @click="toggleServicesFuture"
      >
        {{ servicesFutureQty }} serveis futurs
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { now } from "lodash";
import { api } from "@/boot/axios";
import Translation from "@/translations/Translation";

export default {
  name: "MapStats",
  components: { Translation },
  props: ["map", "services", "trips", "filters"],
  emits: ["update:filters", "reset:filters"],
  data() {
    return {
      filter: null,
      areas: [],
    };
  },
  methods: {
    levelColor(level) {
      switch (level) {
        case "LEVEL_1":
          return {
            "bg-green-100": true,
          };
        case "LEVEL_2":
          return {
            "bg-red-100": true,
          };
      }
    },
    checkAreaLevels() {
      api
        .get("/areas", { params: { sortCol: "code", sortDir: "asc" } })
        .then(({ data }) => {
          this.areas = data.results;

          setTimeout(this.checkAreaLevels, 30 * 1000);
        });
    },
    toggleTaxisAvailable() {
      if (this.filter !== "taxisAvailable") {
        this.filter = "taxisAvailable";
        this.$emit("update:filters", {
          taxis: {
            taxisAvailable: true,
          },
        });
      } else {
        this.filter = null;
        this.$emit("reset:filters");
      }
    },

    toggleServicesWaiting() {
      if (this.filter !== "servicesWaiting") {
        this.filter = "servicesWaiting";
        this.$emit("update:filters", {
          services: {
            servicesWaiting: true,
          },
        });
      } else {
        this.filter = null;
        this.$emit("reset:filters");
      }
    },

    toggleServicesInProgress() {
      if (this.filter !== "servicesInProgress") {
        this.filter = "servicesInProgress";
        this.$emit("update:filters", {
          services: {
            servicesInProgress: true,
          },
        });
      } else {
        this.filter = null;
        this.$emit("reset:filters");
      }
    },

    toggleServicesFuture() {
      if (this.filter !== "servicesFuture") {
        this.filter = "servicesFuture";
        this.$emit("update:filters", {
          services: {
            servicesFuture: true,
          },
        });
      } else {
        this.filter = null;
        this.$emit("reset:filters");
      }
    },
  },
  computed: {
    taxisOnlineQty() {
      let qty = 0;

      for (const trip of Object.values(this.trips)) {
        if (trip.taxi.state === "online") {
          qty++;
        }
      }

      return qty;
    },

    taxisAvailableQty() {
      let qty = 0;

      for (const trip of Object.values(this.trips)) {
        if (
          trip.taxi.state === "online" &&
          (!trip.serviceTaxi?.assignment ||
            trip.serviceTaxi.assignment.status === "assigned")
        ) {
          qty++;
        }
      }

      return qty;
    },

    servicesWaitingQty() {
      let qty = 0;

      for (const service of Object.values(this.services)) {
        if (!service.pickupAt || moment(service.pickupAt).valueOf() <= now()) {
          qty++;
        }
      }

      for (const trip of Object.values(this.trips)) {
        if (
          trip.serviceTaxi &&
          trip.serviceTaxi.assignment.status === "assigned"
        ) {
          qty++;
        }
      }

      return qty;
    },

    servicesInProgessQty() {
      let qty = 0;

      for (const trip of Object.values(this.trips)) {
        if (
          trip.serviceTaxi &&
          trip.serviceTaxi.assignment.status !== "assigned"
        ) {
          qty++;
        }
      }

      return qty;
    },

    servicesFutureQty() {
      let qty = 0;

      for (const service of Object.values(this.services)) {
        if (service.pickupAt && moment(service.pickupAt).valueOf() > now()) {
          qty++;
        }
      }

      return qty;
    },
  },
  mounted() {
    this.checkAreaLevels();
  },
};
</script>
