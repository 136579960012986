<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="generic.nouPoligon" />
        </h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <div class="mt-2">
      <label>
        <div><translation value="generic.tipus" />:</div>
        <select
          class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
          v-model="model_uri"
        >
          <option value="municipalities" v-if="!config.hideMunicipalities">
            <translation value="generic.municipality" />
          </option>
          <option value="areas" v-if="!config.hideAreas">
            <translation value="generic.area" />
          </option>
          <option value="zones" v-if="!config.hideZones">
            <translation value="generic.zone" />
          </option>
          <option value="stations" v-if="!config.hideStations">
            <translation value="generic.station" />
          </option>
          <option value="sectors" v-if="!config.hideSectors">
            <translation value="generic.sector" />
          </option>
        </select>
      </label>

      <label>
        <div><translation value="generic.tria" />:</div>
        <select
          class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
          v-model="item"
        >
          <option :value="null" disabled>
            {{ "—" }}
          </option>

          <template v-for="item in list" :key="item.id">
            <option v-if="!excludeIds.includes(item.id)" :value="item">
              {{ item.code }}
            </option>
          </template>
        </select>
      </label>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          color="green"
          :disabled="!item"
          @click="create"
          class="disabled:bg-transparent disabled:border-gray-600 disabled:text-gray-600"
        >
          <translation value="button.create" />
        </htc-button>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { api } from "@/boot/axios";

export default {
  name: "PolygonCreate",
  components: {
    XIcon,
    HtcButton,
    Translation,
  },
  props: ["modal"],
  data() {
    return {
      loading: false,
      model_uri: null,
      item: null,

      list: [],
    };
  },
  computed: {
    config() {
      return this.modal.payload?.config || {};
    },

    excludeIds() {
      return this.modal.payload?.excludeIds || [];
    },
  },
  watch: {
    model_uri() {
      this.item = null;
      this.list = [];

      this.loadList();
    },
  },
  methods: {
    loadList() {
      if (!this.model_uri) return;

      const uri = "/" + this.model_uri;

      api
        .get(uri, {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.list = [...response.data.results];
          this.loading = false;
        });
    },

    create() {
      if (!this.item) return;

      this.modal.resolve({
        model_uri: this.model_uri,
        item: {
          ...this.item,
          model: {
            uri: this.model_uri,
          },
        },
      });
    },
  },
};
</script>
