<template>
  <div class="my-5 mx-auto w-11/12 max-w-8xl space-y-4 min-w-min">
    <div class="flex justify-between items-center flex-wrap gap-4">
      <div class="flex justify-center items-center">
        <select
          name="rowsPerPage"
          id="rowsPerPage"
          class="w-24 px-2 py-1 focus:ring-blue-500 focus:border-blue-500 block border-gray-300 rounded-md"
          v-model="size"
        >
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
          <option :value="250">250</option>
          <option :value="500">500</option>
          <option :value="1000000"><translation value="txt.all" /></option>
        </select>
        <label class="pl-2" for="rowsPerPage">
          <translation value="rowsPerPage" />
        </label>
      </div>

      <div class="flex items-center">
        <div class="px-2">
          <htc-button
            is="button"
            :color="filters && Object.keys(filters).length !== 0 ? 'green' : ''"
            class="h-9"
            v-if="filterEvent"
            @click="filterEvent ? filterEvent() : null"
          >
            <FilterIcon class="w-5 h-5" />
          </htc-button>
        </div>
        <div>
          <label
            for="search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            <translation value="table.search" />
          </label>
          <div class="relative">
            <div
              class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
            >
              <SearchIcon class="w-4 h-4 text-gray-500" />
            </div>
            <input
              v-model="search"
              @input="updateSearch()"
              type="search"
              id="search"
              class="block w-full px-2 py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              style="padding-inline-start: 2.05rem"
            />
          </div>
        </div>
      </div>
    </div>

    <table class="w-full table-auto">
      <thead>
        <tr class="text-left bg-gray-50">
          <th
            v-for="column in columns"
            :key="column.key"
            scope="col"
            class="font-semibold px-1"
          >
            <Translation
              v-if="column.sortable === false"
              :value="column.label"
            />

            <button
              v-else
              class="font-semibold flex items-center gap-1 text-left"
              @click="order(column.key)"
            >
              <Translation :value="column.label" />

              <span v-if="sort.col === column.key">
                <ChevronUpIcon v-if="!sort.dir" class="w-3 h-3" />
                <ChevronDownIcon v-else class="w-3 h-3" />
              </span>
            </button>
          </th>
          <th v-if="actionsEnabled" class="px-1">
            <translation value="table.actions" />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(row, row_index) in rows"
          :key="row.id"
          :class="[
            loading && 'animate-pulse',
            row_index % 2 ? 'bg-gray-50' : 'bg-transparent',
          ]"
        >
          <td
            v-for="column in columns"
            :key="column.key"
            class="px-2 py-2"
            :class="column.customClass ? column.customClass : ''"
            @click="column.clickEvent ? column.clickEvent(row) : null"
          >
            <template v-if="column.translate">
              <template v-if="column.customRender">
                <div
                  :class="statusColor(column.customRender(row))"
                  class="px-2 rounded-xl"
                >
                  <!-- Gets return value of custom render for translation too. Change if needed -->
                  <translation :value="column.customRender(row)" />
                </div>
              </template>
              <template v-else>
                <div
                  :class="statusColor(getProp(row, column.key, column.type))"
                  class="px-2 rounded-xl"
                >
                  <translation :value="getProp(row, column.key, column.type)" />
                </div>
              </template>
            </template>
            <template v-else>
              <template v-if="column.customRender">
                {{ column.customRender(row) }}
              </template>
              <template v-else>
                {{ getProp(row, column.key, column.type) }}
              </template>
            </template>
          </td>
          <td class="p-2 pl-1" v-if="actionsEnabled">
            <div class="flex justify-end items-center gap-2">
              <slot name="actions" :row="row"></slot>
              <button v-if="viewable" @click="goToDetailPage(row)">
                <DocumentSearchIcon class="h-5 w-5 text-green-500" />
              </button>
              <button v-if="removable" @click="remove(row)">
                <TrashIcon class="h-5 w-5 text-red-500" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="flex justify-between items-center flex-wrap"
      :class="[loading && 'animate-pulse']"
    >
      <div>
        <translation value="table.showing" /> {{ page * size + 1 }}
        <translation value="table.to" />
        {{ (page + 1) * size > total ? total : (page + 1) * size }}
        <translation value="table.of" /> {{ total }}
      </div>

      <div class="flex rounded-l rounded-r text-gray-700">
        <button
          @click="first"
          class="w-8 h-8 rounded-l grid place-items-center hover:bg-gray-50 border"
        >
          <ChevronDoubleLeftIcon class="h-3 w-3" />
        </button>

        <button
          @click="prev"
          class="w-8 h-8 grid place-items-center hover:bg-gray-50 border-t border-b border-r"
        >
          <ChevronLeftIcon class="h-3 w-3" />
        </button>

        <button
          v-for="_page in pages"
          :key="_page"
          @click="goto(_page)"
          class="px-2 h-8 grid place-items-center hover:bg-gray-50 border-t border-b border-r"
          :class="[_page === page && '!bg-gray-200']"
        >
          {{ _page + 1 }}
        </button>

        <button
          @click="next"
          class="w-8 h-8 grid place-items-center hover:bg-gray-50 border-t border-b border-r"
        >
          <ChevronRightIcon class="h-3 w-3" />
        </button>

        <button
          @click="last"
          class="w-8 h-8 rounded-r grid place-items-center hover:bg-gray-50 border-t border-b border-r"
        >
          <ChevronDoubleRightIcon class="h-3 w-3" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import { api } from "@/boot/axios";
import { debounce } from "lodash";
import { get } from "lodash";
import { useToast } from "vue-toastification";
import Translation from "@/translations/Translation";
import Translate from "@/translations/Translate";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  TrashIcon,
  DocumentSearchIcon,
  SearchIcon,
  FilterIcon,
} from "@heroicons/vue/outline";

export default {
  name: "Datatable",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    Translation,
    ChevronUpIcon,
    ChevronDownIcon,
    HtcInputField,
    TrashIcon,
    DocumentSearchIcon,
    SearchIcon,
    FilterIcon,
    HtcButton,
  },
  props: {
    url: String,
    columns: Array,
    autorefresh: {
      type: Boolean,
      default: true,
    },
    actionsEnabled: {
      type: Boolean,
      default: false,
    },
    viewable: {
      type: Boolean,
      default: false,
    },
    detailPage: String,
    editable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    filterEvent: {
      type: Function,
      default: null,
    },
    filters: {
      type: Object,
      default: null,
    },
    initialSort: {
      type: Object,
      default: () => ({
        col: "",
        dir: 1,
      }),
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      interval: null,
      loading: true,
      search: "",
      rows: [],
      total: null,
      size: this.pageSize,
      page: 0,
      sort: this.initialSort,
    };
  },
  computed: {
    maxPage() {
      return Math.floor(this.total / this.size);
    },
    pages() {
      const pages = [];

      for (let i = 0; i <= this.maxPage; i++) {
        pages.push(i);
      }

      if (this.maxPage > 3) {
        if (this.page >= 2 && this.maxPage - this.page > 2) {
          return pages.slice(this.page - 1, this.page + 3);
        } else if (this.page < 2) {
          return pages.slice(0, 4);
        } else {
          return pages.slice(this.maxPage - 3, this.maxPage + 1);
        }
      }

      return pages;
    },
  },
  watch: {
    size: {
      handler() {
        this.page = 0;
        this.loadData();
      },
      immediate: true,
    },
    filters: {
      handler() {
        this.page = 0;
        this.loadData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getProp(object, path, type = "string") {
      const value = get(object, path);
      switch (type) {
        case "datetime":
          return this.formatTimestamp(value);
        default:
          return value;
      }
    },
    handleSearch(search) {
      this.search = search;
      this.page = 0;
      this.loadData();
    },
    handleChangePage(page) {
      this.page = page;
      this.loadData();
    },
    async loadData() {
      this.loading = true;

      const response = await api
        .get(this.url, {
          params: {
            size: this.size,
            page: this.page,
            sortCol: this.sort.col,
            sortDir: this.sort.dir ? "desc" : "asc",
            search: this.search,
            filters: this.filters,
          },
        })
        .catch(() => {});

      if (response?.status !== 200) {
        alert("No se pudo cargar la información del servidor.");
      }

      this.total = response.data.total;
      this.rows = response.data.results;
      this.loading = false;
    },
    updateSearch: debounce(function () {
      this.page = 0;
      this.loadData();
    }, 500),
    order(col) {
      if (col === this.sort.col) {
        this.sort.dir = +!this.sort.dir;

        this.page = 0;

        this.loadData();
        return;
      }

      this.sort.col = col;
      this.sort.dir = 0;

      this.page = 0;

      this.loadData();
    },
    // Temporal solution
    statusColor(status) {
      switch (status) {
        case "offline":
          return "bg-red-500 uppercase";
        case "waiting":
          return "bg-red-400 uppercase";
        case "doing":
          return "bg-blue-400 uppercase";
        case "done":
          return "bg-green-400 uppercase";
        case "busy":
          return "bg-yellow-500 uppercase";
        case "free":
          return "bg-green-500 uppercase";
        case "preassigned":
        case "assigned":
        case "reassigned":
          return "bg-orange-500 uppercase";
        case "accepted":
          return "bg-yellow-400 uppercase";
        case "pickedup":
          return "bg-blue-500 uppercase";
        case "droppedoff":
          return "bg-green-500 uppercase";
        case "disabled":
          return "bg-gray-300 uppercase";
        case "enter_station":
          return "bg-yellow-300 uppercase";
        case "exit_station":
          return "bg-lime-500 uppercase";
        default:
          return "";
      }
    },
    first() {
      if (this.loading) return;

      this.page = 0;
      this.loadData();
    },
    last() {
      if (this.loading) return;

      this.page = this.maxPage;
      this.loadData();
    },
    prev() {
      if (this.loading) return;

      if (this.page > 0) {
        this.page--;
        this.loadData();
      }
    },
    next() {
      if (this.loading) return;

      if (this.page < this.maxPage) {
        this.page++;
        this.loadData();
      }
    },
    goto(page) {
      if (this.loading) return;

      if (0 <= page && page <= this.maxPage) {
        this.page = page;
        this.loadData();
      }
    },
    goToDetailPage(row) {
      this.$router.push({ name: this.detailPage, params: { id: row.id } });
    },
    remove(row) {
      const confirm = window.confirm(
        Translate.do(this.locale, "Are you sure you want to delete this item?")
          .text,
      );

      if (!confirm) return;

      api
        .delete(this.url + "/" + row.id)
        .then(() => {
          this.toast.success("Eliminat correctament.");
          this.loadData();
        })
        .catch(() => {
          this.toast.error("No s'ha pogut eliminar.");
        });
    },
  },
  created() {
    this.loadData();

    if (this.autorefresh) {
      this.interval = setInterval(this.loadData, 30 * 1000);
    }
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style>
.ps-3 {
  -webkit-padding-start: 0.75rem;
  padding-inline-start: 0.75rem;
}
.start-0 {
  inset-inline-start: 0;
}
</style>
