<template>
  <div class="space-y-4 min-w-min">
    <Datatable
      url="/areaslog"
      :columns="columns"
      :autorefresh="true"
      :initial-sort="{ col: 'createdAt', dir: 1 }"
      :pageSize="100"
      :actions-enabled="false"
      :filterEvent="openFilterModal"
      :filters="filters"
    />
  </div>
</template>

<script>
import Datatable from "@/components/Datatable/Datatable";
import Modal from "@/modals/Modal";
import AreasLogFilter from "./AreasLogFilter.vue";

export default {
  name: "AreasLogTable",
  components: {
    Datatable,
  },
  data() {
    return {
      search: "",
      filters: null,
      filterModalValues: null,
      columns: [
        {
          key: "areaCode",
          label: "generic.area",
        },
        {
          key: "createdAt",
          label: "services.date",
          customRender: (value) => {
            return this.formatDate(value.createdAt);
          },
        },
        {
          key: "createdAt",
          label: "services.time",
          customRender: (value) => {
            return this.formatTimeShort(value.createdAt);
          },
        },
        {
          key: "level",
          label: "status.level",
          translate: true,
        },
      ],
    };
  },
  methods: {
    openFilterModal(row) {
      Modal.open(AreasLogFilter, {
        row: row,
        modalValues: this.filterModalValues,
      }).then((data) => {
        this.filters = data.filters;
        this.filterModalValues = data.modalValues;
      });
    },
  },
};
</script>
