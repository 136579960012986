<template>
  <template></template>
</template>

<script>
import { api } from "@/boot/axios";
import Modal from "@/modals/Modal";
import { useToast } from "vue-toastification";

const L = window.L;

export default {
  name: "MapEditing",
  props: ["map", "editableLayer", "config", "polygonIds"],
  emits: ["update:editableLayer"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      drawControl: null,

      current: {
        model: null,
        id: null,
        color: null,
      },
    };
  },
  created() {
    this.createControl();
  },
  beforeUnmount() {
    if (this.drawControl) {
      this.map?.removeControl(this.drawControl);
    }
  },
  watch: {
    config: {
      deep: true,
      handler(config) {
        if (config) {
          if (
            config.hideMunicipalities &&
            config.hideAreas &&
            config.hideZones &&
            config.hideStations
          ) {
            if (this.drawControl) {
              this.map?.removeControl(this.drawControl);
            }
            this.drawControl = null;
          } else {
            this.createControl();
          }
        }
      },
    },
  },
  methods: {
    createControl() {
      if (this.drawControl) return;
      if (
        this.config?.hideMunicipalities &&
        this.config?.hideAreas &&
        this.config?.hideZones &&
        this.config?.hideStations
      )
        return;

      const editableLayer = L.featureGroup().addTo(this.map);

      L.EditToolbar.Delete.include({
        removeAllLayers: false,
      });

      this.drawControl = new L.Control.Draw({
        draw: {
          polyline: false,
          polygon: true,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: editableLayer,
          removeAllLayers: false,
        },
        position: "bottomright",
      });

      this.map.addControl(this.drawControl);

      this.map.on("draw:drawstart", (e) => {
        Modal.openPolygonCreate({
          config: this.config,
          excludeIds: this.polygonIds || [],
        })
          .then(({ item }) => {
            this.current.model = item.model;
            this.current.id = item.id;
          })
          .catch(() => {
            this.current.model = null;
            this.current.id = null;

            this.toggleControl();
          });
      });

      this.map.on("draw:created", (e) => {
        e.layer.properties = {
          model: this.current.model,
          id: this.current.id,
        };

        this.current.model = null;
        this.current.id = null;

        this.save(e.layer);
      });

      this.map.on("draw:edited", (e) => {
        for (const layer of e.layers.getLayers()) {
          this.save(layer);
        }
      });

      this.map.on("draw:deleted", (e) => {
        this.deleteLayers(e.layers.getLayers());
      });

      this.$emit("update:editableLayer", editableLayer);
    },

    toggleControl() {
      this.map?.removeControl(this.drawControl);
      this.map?.addControl(this.drawControl);
    },

    save(layer) {
      if (!layer.properties) return;

      const { model, id } = layer.properties;

      api
        .put(`/${model.uri}/${id}`, {
          polygon: this.serialize(layer),
        })
        .then(() => {
          this.toast.success("Guardat correctament.");
        })
        .catch(() => {
          this.toast.error("No s'ha pogut guardar.");
        });
    },

    deleteLayers(layers) {
      for (const layer of layers) {
        const { model, id } = layer.properties;

        api
          .put(`/${model.uri}/${id}`, {
            polygon: null,
          })
          .then(() => {
            this.toast.success("Eliminat correctament.");
          })
          .catch(() => {
            this.toast.error("No s'ha pogut eliminar.");
          });
      }
    },

    serialize(layer) {
      return JSON.stringify(
        layer.toGeoJSON().geometry.coordinates[0].map((c) => [c[1], c[0]]),
      );
    },
  },
};
</script>
