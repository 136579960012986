<template>
  <div
    v-if="sidebar"
    class="flex-none w-60 h-full border-r flex flex-col justify-between overflow-y-auto overflow-x-hidden"
  >
    <div>
      <router-link :to="{ name: 'home' }" class="mt-4 flex justify-center">
        <img src="@/assets/logo.png" class="w-40" />
      </router-link>

      <div class="mt-10 flex flex-col">
        <router-link
          :to="{ name: 'client-main' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.inici" />
        </router-link>

        <router-link
          :to="{ name: 'client-reserva' }"
          class="px-6 py-2 hover:bg-gray-200 flex items-center"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.reserva" />
        </router-link>

        <router-link
          :to="{ name: 'client-services-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.serveis" />
        </router-link>

        <router-link
          :to="{ name: 'client-services-history' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="txt.services-history" />
        </router-link>

        <router-link
          :to="{ name: 'client-config-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.config" />
        </router-link>
      </div>
    </div>

    <div class="mt-4 shrink-0 space-y-2 overflow-x-hidden">
      <div class="mx-6 overflow-x-hidden">
        <p>{{ user?.name }}</p>
        <p class="text-gray-600 text-sm">{{ user?.email }}</p>
      </div>

      <div class="mx-6 flex justify-between">
        <select
          class="m-auto w-32 px-2 py-1 focus:ring-blue-500 focus:border-blue-500 block border-gray-300 rounded-md"
          v-model="lang"
        >
          <option value="ca">Català</option>
          <option value="es">Castellano</option>
        </select>

        <router-link
          :to="{ name: 'logout' }"
          class="m-auto w-12 py-1 flex justify-center text-sm border border-stone-400 rounded-md bg-stone-200"
        >
          <translation value="nav.sortir" />
        </router-link>
      </div>

      <div class="py-1 border-t text-center text-xs text-gray-600">
        v{{ version }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import store from "@/store";

export default {
  name: "ClientLayoutSidebar",
  components: {
    HtcButton,
    Translation,
  },
  computed: {
    ...mapState({
      version: (state) => state.version,
      sidebar: (state) => state.sidebar,
      user: (state) => state.user,
    }),

    lang: {
      get() {
        return store.state.lang;
      },
      set(lang) {
        store.commit("lang", lang);
      },
    },
  },
};
</script>
