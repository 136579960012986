<template>
  <div></div>
</template>

<script>
import Connection from "@/ws/Connection";
import store from "@/store";

export default {
  name: "Logout",
  created() {
    this.$store.commit("token", null);

    if (!store.state.isAdmin) {
      Connection.send({
        type: "Driver",
        action: "State",
        state: "offline",
      });
    }

    Connection.terminate();
    this.$router.replace({ name: "login" });
  },
};
</script>
