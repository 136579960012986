<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="create" :capitalize="true" />
          usuari
        </template>

        <template v-else> Editar: {{ original?.email }} </template>
      </h1>

      <div class="mt-10">
        <!-- <label>
          <div>
            Tipus:
            <span class="text-stone-500 text-sm">(obligatori)</span>
          </div>
          <select
            v-model="form.type"
            class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          >
            <option value="admin">Admin</option>
            <option value="driver">Taxista</option>
          </select>
        </label> -->

        <label>
          <div>
            Nom complet:
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.name"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>
            Correu electrònic:
            <span class="text-gray-500 text-sm">(obligatori)</span>
          </div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.email"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>
            Contrasenya:
            <span v-if="!editMode" class="text-gray-500 text-sm">
              (obligatori)
            </span>
          </div>
          <htc-input-field
            type="password"
            maxlength="255"
            v-model:value="form.password"
            :placeholder="editMode ? '••••••' : ''"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>DNI:</div>
          <htc-input-field
            type="text"
            maxlength="255"
            v-model:value="form.dni"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div>Notes:</div>
          <textarea
            maxlength="65535"
            v-model="form.notes"
            class="w-full h-32 border-gray-300 rounded-md shadow-sm focus:border-taxis-orange focus:ring focus:ring-taxis-orange/25 focus:ring-opacity-50 disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          >
          </textarea>
        </label>

        <label class="flex items-center gap-2">
          <input
            type="checkbox"
            v-model="form.enabled"
            class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
          <span>Habilitada</span>
        </label>
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="save" :capitalize="true" />
          <translation v-else value="create" :capitalize="true" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";

export default {
  name: "UsersEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: false,
      editMode: false,

      original: null,
      form: {
        enabled: true,
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loading = true;
      this.loadAll();
    } else {
      // this.loadTaxis();
    }
  },
  methods: {
    loadAll() {
      Promise.all([
        api
          .get("/admins/" + this.id)
          .then((response) => {
            this.original = { ...response.data };
            this.form = { ...response.data };
          })
          .catch(() => {
            this.$router.replace({ name: "users-index" });
          }),
        // api.get("/taxis").then((response) => {
        //   this.taxis = [...response.data.data];
        // }),
      ]).then(() => {
        this.loading = false;
      });
    },

    // loadTaxis() {
    //   api.get("/taxis").then((response) => {
    //     this.taxis = [...response.data.data];
    //     this.loading = false;
    //   });
    // },

    loadInfo() {
      api
        .get("/admins/" + this.id)
        .then((response) => {
          this.original = { ...response.data };
          this.form = { ...response.data };
          this.loading = false;
        })
        .catch(() => {
          this.$router.replace({ name: "users-index" });
        });
    },

    submit() {
      if (this.editMode) {
        api
          .put("/admins/" + this.id, this.form)
          .then(() => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "users-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        api
          .post("/admins", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "users-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
  },
};
</script>
