<template>
  <div v-if="hasServiceAndNotInHome" class="z-50">
    <button
      class="w-full items-center font-semibold text-white text-lg px-3 py-4 bg-red-500 fixed bottom-0 z-50"
      style="animation: alert 2s infinite step-end"
    >
      <router-link
        :to="{ name: 'driver-main' }"
        class="flex justify-center items-center z-50"
      >
        <ChevronLeftIcon class="w-5 h-5" />
        <translation value="button.service-notif" />
      </router-link>
    </button>
  </div>

  <div v-if="hasReasignmentAndNotInHome" class="z-50">
    <button
      class="w-full items-center font-semibold text-white text-lg px-3 py-4 bg-red-500 fixed bottom-0 z-50"
      style="animation: alert 2s infinite step-end"
    >
      <router-link
        :to="{ name: 'driver-main' }"
        class="flex justify-center items-center z-50"
      >
        <ChevronLeftIcon class="w-5 h-5" />
        <translation
          v-if="isReassignmetPriority"
          value="button.service-notif-reasing-priority"
        />
        <translation v-else value="button.service-notif-reasing" />
      </router-link>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { NativeAudio } from "@capacitor-community/native-audio";

export default {
  name: "ServiceNotificationButton",
  components: {
    Translation,
    ChevronLeftIcon,
  },
  data: () => ({
    interval: null,
  }),
  created() {
    if (this.config.sound) {
      this.interval = setInterval(() => {
        if (this.hasServiceAndNotInHome) {
          NativeAudio.play({
            assetId: "beep",
          });
        }
      }, 3000);
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState({
      serviceTaxi: (state) => state.serviceTaxi,
      config: (state) => state.config,
      serviceTaxiRe: (state) => state.serviceTaxiRe,
    }),
    status() {
      return this.serviceTaxi?.assignment?.status;
    },
    hasServiceAndNotInHome() {
      return (
        this.serviceTaxi &&
        !["accepted", "pickedup", "droppedoff"].includes(this.status) &&
        this.$route.name != "driver-main"
      );
    },
    hasReasignmentAndNotInHome() {
      return (
        this.serviceTaxiRe?.assignment && this.$route.name != "driver-main"
      );
    },
    isReassignmetPriority() {
      return this.serviceTaxiRe?.service?.priority;
    },
  },
};
</script>

<style>
@keyframes alert {
  0% {
    background-color: rgb(185 28 28);
  }
  50% {
    background-color: rgb(220 38 38);
  }
}
</style>
