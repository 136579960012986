<template>
  <layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-6xl">
      <h1 class="text-center text-2xl">
        <translation value="nav.taxis" />
      </h1>

      <div class="py-4 px-1 overflow-auto">
        <taxis-table />
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import TaxisTable from "./components/TaxisTable.vue";
import Translation from "@/translations/Translation.vue";

export default {
  name: "TaxisIndex",
  components: {
    LayoutMain,
    TaxisTable,
    Translation,
  },
};
</script>
