<template>
  <layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-4xl">
      <h2 class="text-center text-2xl">Reserva Taxi</h2>

      <reserva-component />
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import ReservaComponent from "@/components/ReservaComponent.vue";

export default {
  name: "Reserva",
  components: {
    LayoutMain,
    ReservaComponent,
  },
};
</script>
