<template>
  <div class="w-full h-full flex justify-center items-center bg-black">
    <template v-if="taxiState === 'online' || taxiState === 'busy'">
      <div
        class="w-full h-full"
        v-if="
          taxiState == 'busy' || status == 'pickedup' || status == 'droppedoff'
        "
      >
        <div class="w-full h-full text-center">
          <button
            disabled="true"
            class="w-full h-full items-center font-semibold px-4 py-2 bg-red-700 rounded-md text-6xl"
            @click="free"
          >
            <translation value="button.ocupado" />
          </button>
        </div>
      </div>
      <div class="w-full h-full" v-else-if="Accepted">
        <div class="w-full h-full text-center">
          <button
            disabled="true"
            class="w-full h-full items-center font-semibold px-4 py-2 bg-orange-700 rounded-md text-2xl"
            @click="busy"
          >
            <translation value="button.service.assigned" />
          </button>
        </div>
      </div>
      <div class="w-full h-full" v-else>
        <div class="w-full h-full text-center">
          <button
            class="w-full h-full items-center font-semibold px-4 py-2 bg-green-700 rounded-md text-6xl"
            @click="busy"
          >
            <translation value="button.libre" />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ClockIcon, ExclamationIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapGetters, mapState } from "vuex";
import Connection from "@/ws/Connection";
import store from "@/store";

export default {
  name: "ServiceBusy",
  components: {
    ClockIcon,
    ExclamationIcon,
    Translation,
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),
    ...mapGetters(["isOffline"]),

    taxiState() {
      return !this.isOffline && this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    Accepted() {
      return ["accepted"].includes(this.status);
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },
  },
  mounted() {},
  methods: {
    connect() {
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },
    busy() {
      if (this.isOffline) {
        Connection.connect();
      }
      Connection.send({
        type: "Driver",
        action: "State",
        state: "busy",
      });
      store.commit("deleteOthersService");
    },
    free() {
      if (this.isOffline) {
        Connection.connect();
      }

      Connection.send({
        type: "Driver",
        action: "State",
        state: "online",
      });
    },
  },
};
</script>
