<template>
  <component
    :is="is"
    v-bind="$attrs"
    class="inline-flex items-center justify-center px-4 h-8 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "HtcButton",
  props: {
    is: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
  },
  computed: {
    classes() {
      if (this.color === "red") {
        return "text-white bg-red-600 hover:bg-red-700 focus-visible:ring-red-500";
      }

      if (this.color === "green") {
        return "text-white bg-emerald-600 hover:bg-emerald-700 focus-visible:ring-emerald-500";
      }

      if (this.color === "dark") {
        return "text-white bg-gray-600 hover:bg-gray-700 focus-visible:ring-gray-500";
      }

      return "text-blue-900 bg-blue-100 hover:bg-blue-200 focus-visible:ring-blue-500";
    },
  },
};
</script>
