<template>
  <layout-driver>
    <div class="m-4">
      <h1 class="my-4 text-center text-xl">
        <translation value="nav.parades" />
      </h1>

      <div class="space-y-2">
        <station-item
          v-for="(station, stationId) in getStations"
          :key="stationId"
          :isStnext="isStnext(station)"
          :station="station"
          :stationTaxis="getStationTaxis(station)"
          :isStationed="isStationed"
        />
      </div>
    </div>
  </layout-driver>
</template>

<script>
import LayoutDriver from "../LayoutDriver.vue";
import StationItem from "./components/StationItem.vue";
import Connection from "@/ws/Connection";
import { mapState } from "vuex";
import Translation from "@/translations/Translation.vue";

export default {
  name: "DriverStations",
  components: {
    LayoutDriver,
    StationItem,
    Translation,
  },
  data() {
    return {
      stations: {},
      stationTaxis: {},

      listeners: {
        ready: null,
        stations: null,
      },
    };
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
    }),

    getStationTaxis() {
      return (station) => {
        return Object.values(this.stationTaxis)
          .filter((stationTaxi) => stationTaxi.stationId === station.id)
          .reduce((taxis, taxi) => {
            taxis[taxi.taxiId] = taxi;
            return taxis;
          }, {});
      };
    },

    getStations() {
      return Object.values(this.stations);
    },

    isStnext() {
      return (station) => {
        return station.stnext;
      };
    },

    isStationed() {
      return this.stationTaxis[this.taxi.id] != null;
    },
  },
  created() {
    this.listeners.ready = Connection.addListener("ready", () => {
      Connection.send({
        type: "Stations",
        action: "Subscribe",
      });
    });

    this.listeners.stations = Connection.addListener(
      { type: "Stations", action: "Data" },
      (msg) => {
        this.processData(msg);
      },
    );

    this.listeners.map = Connection.addListener(
      { type: "Stations", action: "Data" },
      (msg) => {
        this.processData(msg);
      },
    );
  },
  beforeUnmount() {
    Connection.removeListener(this.listeners.ready);
    Connection.removeListener(this.listeners.stations);

    if (Connection.isReady()) {
      Connection.send({
        type: "Stations",
        action: "Unsubscribe",
      });
    }
  },
  methods: {
    processData(msg) {
      if (this.isPropObject(msg.station)) {
        this.stations[msg.station.id] = msg.station;
      }

      if (this.isPropObject(msg.delStation)) {
        delete this.stations[msg.delStation.id];
      }

      if (this.isPropObject(msg.stations)) {
        this.stations = msg.stations;
      }

      if (this.isPropObject(msg.stationTaxi)) {
        this.stationTaxis[msg.stationTaxi.taxiId] = msg.stationTaxi;
      }

      if (this.isPropObject(msg.delStationTaxi)) {
        delete this.stationTaxis[msg.delStationTaxi.taxiId];
      }

      if (this.isPropObject(msg.stationTaxis)) {
        this.stationTaxis = msg.stationTaxis;
      }
    },
  },
};
</script>
