<template>
  <div
    v-if="sidebar"
    class="flex-none w-60 h-full border-r flex flex-col justify-between overflow-y-auto overflow-x-hidden"
  >
    <div>
      <router-link :to="{ name: 'home' }" class="mt-4 flex justify-center">
        <img src="@/assets/logo.png" class="w-40" />
      </router-link>

      <div class="mt-10 flex flex-col">
        <router-link
          :to="{ name: 'home' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.inici" />
        </router-link>

        <router-link
          :to="{ name: 'reserva' }"
          class="px-6 py-2 hover:bg-gray-200 flex items-center"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.reserva" />
        </router-link>

        <router-link
          :to="{ name: 'services-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.serveis" />
        </router-link>

        <router-link
          :to="{ name: 'services-futur' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.serveis.futurs" />
        </router-link>

        <router-link
          :to="{ name: 'services-log-show' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="txt.log-services" />
        </router-link>

        <router-link
          :to="{ name: 'taxis-log' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="txt.log-taxis" />
        </router-link>

        <router-link
          :to="{ name: 'areas-log' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="txt.log-areas" />
        </router-link>

        <router-link
          :to="{ name: 'status-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.status" />
        </router-link>

        <router-link
          :to="{ name: 'situation' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.situation" />
        </router-link>

        <div
          class="ml-6 mt-4 mb-1 font-semibold uppercase text-gray-500 text-xs"
        >
          <translation value="nav.manteniments" />
        </div>

        <router-link
          :to="{ name: 'municipalities-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.municipalities" />
        </router-link>

        <router-link
          :to="{ name: 'areas-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.arees" />
        </router-link>

        <router-link
          :to="{ name: 'zones-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.zones" />
        </router-link>

        <router-link
          :to="{ name: 'sectors-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.sectors" />
        </router-link>

        <router-link
          :to="{ name: 'stations-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.parades" />
        </router-link>

        <router-link
          :to="{ name: 'taxis-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.taxis" />
        </router-link>

        <router-link
          :to="{ name: 'drivers-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.taxistes" />
        </router-link>

        <router-link
          :to="{ name: 'users-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.usuaris" />
        </router-link>

        <router-link
          :to="{ name: 'places-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.llocshabituals" />
        </router-link>

        <router-link
          :to="{ name: 'client-types-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.client-types" />
        </router-link>

        <router-link
          :to="{ name: 'clients-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.clients" />
        </router-link>

        <router-link
          :to="{ name: 'config-index' }"
          class="px-6 py-2 hover:bg-gray-200"
          active-class="font-semibold bg-gray-300"
        >
          <translation value="nav.confglobal" />
        </router-link>
      </div>
    </div>

    <div class="mt-4 shrink-0 space-y-2 overflow-x-hidden">
      <div class="mx-6 overflow-x-hidden">
        <p>{{ user?.name }}</p>
        <p class="text-gray-600 text-sm">{{ user?.email }}</p>
      </div>

      <div class="mx-6 flex justify-between">
        <select
          class="m-auto w-32 px-2 py-1 focus:ring-blue-500 focus:border-blue-500 block border-gray-300 rounded-md"
          v-model="lang"
        >
          <option value="ca">Català</option>
          <option value="es">Castellano</option>
        </select>

        <router-link
          :to="{ name: 'logout' }"
          class="m-auto w-12 py-1 flex justify-center text-sm border border-stone-400 rounded-md bg-stone-200"
        >
          <translation value="nav.sortir" />
        </router-link>
      </div>

      <div class="py-1 border-t text-center text-xs text-gray-600">
        v{{ version }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import store from "@/store";
import { version } from "../../package.json";

export default {
  name: "LayoutSidebar",
  components: {
    HtcButton,
    Translation,
  },
  data: () => ({
    version: version,
  }),
  computed: {
    ...mapState({
      sidebar: (state) => state.sidebar,
      user: (state) => state.user,
    }),
    lang: {
      get() {
        return store.state.lang;
      },
      set(lang) {
        store.commit("lang", lang);
      },
    },
  },
};
</script>
