<template>
  <div class="w-screen h-screen flex flex-col justify-center items-center">
    <h1 class="text-3xl">404 No Encontrado</h1>
    <p class="text-md">Esta página no existe.</p>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
