import strings from "./strings.json";
import store from "../store";

const no_multi_capitalize = ["de", "of"];

class Translate {
  #text = "N/A";

  get text() {
    return this.#text;
  }

  static value(value) {
    const lang = store.state.lang;
    return new this(lang, value);
  }

  static do(lang, value) {
    return new this(lang, value);
  }

  constructor(lang, value) {
    if (strings[value]) {
      if (strings[value][lang]) {
        this.#text = strings[value][lang];
        return;
      }

      if (Object.values(strings[value]).length) {
        this.#text = Object.values(strings[value])[0];
        return;
      }
    }

    this.#text = value;
  }

  attributes(...attributes) {
    for (const attribute of attributes) {
      this.#text = this.#text.replace(/:\S+/, attribute);
    }

    return this;
  }

  lowercase() {
    this.#text = this.#text.toLowerCase();

    return this;
  }

  uppercase() {
    this.#text = this.#text.toUpperCase();

    return this;
  }

  capitalize() {
    this.#text = this.#text[0].toUpperCase() + this.#text.substr(1);

    return this;
  }

  multicapitalize() {
    const words = this.#text.split(" ");
    const parsedWords = [];

    for (let word of words) {
      if (no_multi_capitalize.includes(word)) {
        parsedWords.push(word);
        continue;
      }

      parsedWords.push(word[0].toUpperCase() + word.substr(1).toLowerCase());

      this.#text = parsedWords.join(" ");
    }

    return this;
  }

  ellipsis() {
    this.#text += "...";

    return this;
  }
}

export default Translate;
