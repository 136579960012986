<template>
  <div class="hidden">
    <div ref="marker">
      <img src="@/assets/speakerphone.png" alt="cantado" class="w-7 h-7" />
    </div>

    <div ref="popup" class="w-44 min-w-min space-y-2 font-sans">
      <h2 class="text-gray-800 text-sm font-bold uppercase">Cantado</h2>

      <div class="space-y-1">
        <div>
          <div>De Taxi</div>
          <div class="text-sm font-semibold">
            {{ cantar.taxi?.code ?? cantar.taxiId }}
          </div>
        </div>

        <div>
          <div>Data i hora de cantado</div>
          <div class="text-sm font-semibold">{{ timestamp() || "-" }}</div>
          <div class="-mt-1 text-sm">{{ timestampTimeago() || "-" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import InlineSvg from "vue-inline-svg";

export default {
  name: "MapCantar",
  components: {
    InlineSvg,
  },
  props: ["map", "cantar"],
  data() {
    return {
      marker: null,
    };
  },
  mounted() {
    this.draw();
  },
  beforeUnmount() {
    this.marker?.remove();
  },
  methods: {
    draw() {
      const myIcon = L.divIcon({
        className: "",
        html: this.$refs.marker,
        iconAnchor: [14, 14],
        popupAnchor: [0, -14],
      });

      const marker = L.marker([this.cantar.latitude, this.cantar.longitude], {
        icon: myIcon,
      });

      marker.bindPopup(this.$refs.popup);

      marker.addTo(this.map);

      this.marker = marker;
    },

    timestamp() {
      return this.formatTimestamp(this.cantar.timestamp);
    },

    timestampTimeago() {
      return this.timeago(this.cantar.timestamp);
    },
  },
};
</script>
