<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="reservation.priorityinput" />
        </h2>
      </div>
      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>
    <div class="p-2 text-gray-700">
      {{ row.priorityInfo }}
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { XIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";

export default {
  name: "PriorityInfo",
  components: {
    XIcon,
    Translation,
  },
  props: ["modal"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      row: this.modal.payload,
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
