<template>
  <div class="m-1">
    <div class="flex justify-between space-x-1">
      <station-item
        v-for="(station, stationId) in getStations()"
        :key="stationId"
        :station="station"
        :stationTaxis="getStationTaxis(station)"
        :color="getZoneColor(station.zoneId)"
        :isStationed="isStationed"
        :services="getStationServices(station.id)"
        :p10="this.p10"
      />
    </div>
  </div>
</template>

<script>
import StationItem from "./StationItem.vue";
import Connection from "@/ws/Connection";
import { mapState } from "vuex";
import Translation from "@/translations/Translation.vue";
import { NativeAudio } from "@capacitor-community/native-audio";

export default {
  name: "DriverStations",
  components: {
    StationItem,
    Translation,
    NativeAudio,
  },
  props: ["taxiConfig"],
  data() {
    return {
      stations: {},
      stationTaxis: {},
      zones: {},
      services: {},
      p10: false,
      listeners: {
        ready: null,
        map: null,
      },
      alerts: {},
    };
  },
  watch: {
    stationTaxis: {
      deep: true,
      handler(stationTaxis) {
        if (stationTaxis) {
          //console.log("stations taxis");
          //console.log(stationTaxis);
          //console.log(stationTaxis.length);
          // if (this.taxiConfig?.sound) {
          //   this.noTaxi();
          // }
        }
      },
    },
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
    }),

    getStationTaxis() {
      return (station) => {
        return Object.values(this.stationTaxis)
          .filter((stationTaxi) => stationTaxi.stationId === station.id)
          .reduce((taxis, taxi) => {
            taxis[taxi.taxiId] = taxi;
            return taxis;
          }, {});
      };
    },

    isStationed() {
      return this.stationTaxis[this.taxi.id] != null;
    },
  },
  created() {
    this.$emit("loadTaxiConfig");
    this.listeners.ready = Connection.addListener("ready", () => {
      Connection.send({
        type: "Map",
        action: "Subscribe",
      });
    });

    this.listeners.stations = Connection.addListener(
      { type: "Map", action: "Data" },
      (msg) => {
        this.processData(msg);
      },
    );
  },

  beforeUnmount() {
    Connection.removeListener(this.listeners.ready);
    Connection.removeListener(this.listeners.map);

    if (Connection.isReady()) {
      Connection.send({
        type: "Map",
        action: "Unsubscribe",
      });
    }
  },
  methods: {
    /*
    getStationTaxis(station) {
      var taxis = Object.values(this.stationTaxis)
        .filter((stationTaxi) => stationTaxi.stationId === station.id)
        .reduce((taxis, taxi) => {
          taxis[taxi.taxiId] = taxi;
          return taxis;
        }, {});

      //console.log(this.alerts[station.id]);
      //console.log(taxis);
      //console.log(taxis[station.id]);
      if (
        this.alerts[station.id]?.length &&
        !taxis[station.id] &&
        this.taxiConfig?.sound
      ) {
        //console.log("SOUNDDDDDDDDDDDDDDDDDDD");
        this.noTaxi();
        delete this.alerts[station.id];
      }

      this.alerts[station.id] = taxis;

      //console.log("alerts!");
      //console.log(this.alerts);

      return taxis;
    },
    */
    getZoneColor(stationId) {
      var zoneStations = Object.values(this.zones);

      if (this.zones) {
        var zone = zoneStations?.filter((zone) => zone.id === stationId);
        if (zone[0]?.color) {
          return zone[0].color;
        }

        return "#F54435";
      }

      return "#F54435";
    },

    getStations() {
      var stations = Object.values(this.stations);

      stations = stations.sort((a, b) =>
        a.code.toLowerCase() < b.code.toLowerCase()
          ? -1
          : b.code.toLowerCase() > a.code.toLowerCase()
          ? 1
          : 0,
      );

      return stations;
    },

    getStationServices(stationId) {
      var StationServices = Object.values(this.services);

      StationServices = StationServices?.filter(
        (service) => service.pickupStationId === stationId,
      );

      return StationServices.length;
    },

    processData(msg) {
      if (this.isPropObject(msg.station)) {
        this.stations[msg.station.id] = msg.station;
      }

      if (this.isPropObject(msg.delStation)) {
        delete this.stations[msg.delStation.id];
      }

      if (this.isPropObject(msg.stations)) {
        this.stations = msg.stations;
      }

      if (this.isPropObject(msg.stationTaxi)) {
        this.stationTaxis[msg.stationTaxi.taxiId] = msg.stationTaxi;
      }

      if (this.isPropObject(msg.delStationTaxi)) {
        delete this.stationTaxis[msg.delStationTaxi.taxiId];
      }

      if (this.isPropObject(msg.stationTaxis)) {
        this.stationTaxis = msg.stationTaxis;
      }

      if (this.isPropObject(msg.service)) {
        this.services[msg.service.id] = msg.service;
      }

      if (this.isPropObject(msg.services)) {
        this.services = msg.services;
      }

      if (typeof msg.delServiceTaxi === "string") {
        delete this.services[msg.delServiceTaxi];
      }

      if (this.isPropObject(msg.zone)) {
        this.zones[msg.zone.id] = msg.zone;
      }

      if (this.isPropObject(msg.zones)) {
        this.zones = msg.zones;
      }

      if (typeof msg.delZone === "string") {
        delete this.zones[msg.delZone];
      }

      if (typeof msg.p10 === "string") {
        this.p10 = msg.status;
        // if (this.taxiConfig?.sound) {
        //   this.noTaxi();
        // }
      }
    },

    noTaxi() {
      (async () => {
        await NativeAudio.play({
          assetId: "beep",
        });
      })();
    },
  },
};
</script>
