<template>
  <div @keydown.ctrl.s.prevent.stop="validateForm(false)">
    <div v-if="reservaForm" class="mt-4">
      <div class="flex items-center">
        <translation value="form.pickupAt" />:
        <label class="ml-2 flex items-center gap-1 text-gray-800 text-sm">
          <input
            type="checkbox"
            class="w-3 h-3 accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            v-model="allowDate"
            @click="setDate()"
          />
          <span><translation value="Enable" /></span>
        </label>
      </div>

      <htc-input-field
        type="datetime-local"
        min="1"
        max="4"
        v-model:value="form.pickupAt"
        class="w-full disabled:bg-gray-200"
        :disabled="!allowDate"
      />
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2">
      <div>
        <h2 class="text-xl mt-4 mb-2 ml-1 font-medium">
          <translation value="reservation.pickup" />
        </h2>
        <div class="bg-green-100 rounded-md p-4 m-1">
          <div class="flex items-center">
            <translation value="reservation.pickupzone" />:
            <span class="ml-1 text-gray-500 text-sm"
              >(<translation value="required" />)</span
            >
          </div>

          <div>
            <div class="flex">
              <htc-input-field
                type="text"
                ref="pickupName"
                id="pickupName"
                v-model:value="pickupName"
                @input="search"
                v-focus
                placeholder="Cercar..."
                required="required"
                class="w-full disabled:bg-gray-200 disabled:animate-pulse"
              />
              <button
                v-if="pickupName !== ''"
                class="shrink-0"
                @click="clearPickup()"
                tabindex="-1"
              >
                <XIcon class="w-6 h-6" />
              </button>
            </div>
            <div class="mb-2">
              <button
                class="ml-2 flex items-center text-gray-800 text-sm"
                @click="selectPlace(0)"
              >
                <BookmarkIcon class="w-6 h-6 scale-50 text-taxis-orange" />
                <span><translation value="nav.llocshabituals" /></span>
              </button>
            </div>
            <ul
              v-show="showResults"
              class="bg-green-100"
              style="position: relative"
            >
              <figure
                v-show="
                  results.length === 0 && Object.keys(popularPlaces).length > 0
                "
              >
                <figcaption class="font-semibold">
                  <translation value="reservation.usualplaces.first" />
                  {{
                    Object.keys(popularPlaces).length > 0
                      ? popularPlaces.popularOrigins.length
                      : 10
                  }}
                  <translation value="reservation.usualplaces.second" />
                </figcaption>
                <ul>
                  <li
                    style="
                      cursor: pointer;
                      padding: 4px;
                      &.sel {
                        font-weight: bolder;
                      }
                    "
                    v-for="place in popularPlaces.popularOrigins"
                    :key="place.id"
                    @click="selectResultPlace(0, place)"
                    @keyup.enter="selectResultPlace(0, place)"
                    tabindex="0"
                  >
                    {{ place.name }}
                  </li>
                </ul>
              </figure>
              <li
                style="
                  cursor: pointer;
                  padding: 5px;
                  &.sel {
                    font-weight: bolder;
                  }
                "
                v-for="result in results"
                :key="result.place_id"
                @click="selectResult(result)"
                @keyup.enter="selectResult(result)"
                tabindex="0"
              >
                {{ result.display_name }}
              </li>
            </ul>
          </div>

          <div class="flex items-center">
            <translation value="reservation.infopickupzone" />:
          </div>

          <div>
            <htc-input-field
              type="text"
              v-model:value="form.pickupInfo"
              ref="pickupInfoInput"
              id="pickupInfoInput"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </div>

          <div>
            <div class="text-right">
              <button
                v-if="showMap && this.form.location.lat > 0"
                class="shrink-0"
                @click="showMap = !showMap"
                tabindex="-1"
              >
                <EyeOffIcon class="w-6 h-6" />
              </button>
              <button
                v-if="!showMap && this.form.location.lat > 0"
                class="shrink-0"
                @click="showMap = !showMap"
                tabindex="-1"
              >
                <EyeIcon class="w-6 h-6" />
              </button>
            </div>
            <div id="mapPickup" v-show="showMap"></div>
            <div class="mt-4 mb-4" v-if="this.form.location.lat > 0 && showMap">
              <div><b>Lat:</b> {{ form.location.lat }}</div>
              <div><b>Lon:</b> {{ form.location.lng }}</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 class="text-xl mt-4 mb-2 ml-1 font-medium">
          <translation value="reservation.dropoff" />
        </h2>
        <div class="bg-red-100 rounded-md p-4 m-1">
          <div class="flex items-center">
            <translation value="reservation.dropzone" />:
            <span class="ml-1 text-gray-500 text-sm"
              >(<translation value="required" />)</span
            >
          </div>

          <div>
            <div class="flex">
              <htc-input-field
                type="text"
                ref="dropoffName"
                id="dropoffName"
                v-model:value="dropoffName"
                @input="searchDrop"
                placeholder="Cercar..."
                required="required"
                class="w-full disabled:bg-gray-200 disabled:animate-pulse"
              />
              <button
                v-if="dropoffName !== ''"
                class="shrink-0"
                @click="clearDropoff()"
                tabindex="-1"
              >
                <XIcon class="w-6 h-6" />
              </button>
            </div>
            <div class="mb-2">
              <button
                class="ml-2 flex items-center text-gray-800 text-sm"
                @click="selectPlace(1)"
              >
                <BookmarkIcon class="w-6 h-6 scale-50 text-taxis-orange" />
                <span><translation value="nav.llocshabituals" /></span>
              </button>
            </div>
            <ul
              v-show="showResultsDrop"
              class="bg-red-100"
              style="position: relative"
            >
              <figure
                v-show="
                  resultsDrop.length === 0 &&
                  Object.keys(popularPlaces).length > 0
                "
              >
                <figcaption class="font-semibold">
                  <translation value="reservation.usualplaces.first" />
                  {{
                    Object.keys(popularPlaces).length > 0
                      ? popularPlaces.popularDestinations.length
                      : 10
                  }}
                  <translation value="reservation.usualplaces.second" />
                </figcaption>
                <ul>
                  <li
                    style="
                      cursor: pointer;
                      padding: 4px;
                      &.sel {
                        font-weight: bolder;
                      }
                    "
                    v-for="place in popularPlaces.popularDestinations"
                    :key="place.id"
                    @click="selectResultPlace(1, place)"
                    @keyup.enter="selectResultPlace(1, place)"
                    tabindex="0"
                  >
                    {{ place.name }}
                  </li>
                </ul>
              </figure>
              <li
                style="
                  cursor: pointer;
                  padding: 5px;
                  &.sel {
                    font-weight: bolder;
                  }
                "
                v-for="result in resultsDrop"
                :key="result.place_id"
                @click="selectResultDrop(result)"
                @keyup.enter="selectResultDrop(result)"
                tabindex="0"
              >
                {{ result.display_name }}
              </li>
            </ul>
          </div>

          <div class="flex items-center">
            <translation value="reservation.infodropoffzone" />:
          </div>

          <div>
            <htc-input-field
              type="text"
              v-model:value="form.dropoffInfo"
              ref="dropoffInfoInput"
              id="dropoffInfoInput"
              class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            />
          </div>

          <div>
            <div class="text-right">
              <button
                v-if="showMapDrop && this.form.locationDrop.lat > 0"
                class="shrink-0"
                @click="showMapDrop = !showMapDrop"
                tabindex="-1"
              >
                <EyeOffIcon class="w-6 h-6" />
              </button>
              <button
                v-if="!showMapDrop && this.form.locationDrop.lat > 0"
                class="shrink-0"
                @click="showMapDrop = !showMapDrop"
                tabindex="-1"
              >
                <EyeIcon class="w-6 h-6" />
              </button>
            </div>
            <div id="mapDrop" v-show="showMapDrop"></div>
            <div
              class="mt-4 mb-4"
              v-if="this.form.locationDrop.lat > 0 && showMapDrop"
            >
              <div><b>Lat:</b> {{ form.locationDrop.lat }}</div>
              <div><b>Lon:</b> {{ form.locationDrop.lng }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-xl mt-4 mb-2 font-medium">
      <translation value="reservation.client" />
    </h2>

    <div>
      <div><translation value="reservation.client_name" />:</div>
      <htc-input-field
        type="text"
        maxlength="255"
        v-model:value="form.name"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <div>
      <div><translation value="reservation.room" />:</div>
      <htc-input-field
        type="text"
        maxlength="255"
        v-model:value="form.room"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <div>
      <div>
        <translation value="form.pplqty" /> (<translation value="max" /> 4):
        <span class="text-gray-500 text-sm"
          >(<translation value="required" />)</span
        >
      </div>
      <htc-input-field
        type="number"
        min="1"
        max="4"
        required="required"
        ref="people"
        id="people"
        v-model:value="form.people"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <div>
      <div><translation value="form.phone" />:</div>
      <htc-input-field
        type="text"
        maxlength="255"
        v-model:value="form.phone"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <div>
      <div><translation value="reservation.info" />:</div>
      <htc-input-field
        type="text"
        maxlength="2048"
        v-model:value="form.message"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <h2 class="text-xl mt-4 mb-2 font-medium">
      <translation value="reservation.services" />
    </h2>

    <div class="mt-2 mb-2">
      <label>
        <input
          type="checkbox"
          id="priority"
          :value="form.priority"
          v-model="form.priority"
          class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
          :disabled="loading"
        />
        <span class="ml-2">
          <translation value="reservation.priority" />
        </span>
      </label>
    </div>

    <div v-if="form.priority">
      <div><translation value="reservation.priorityinput" />:</div>
      <htc-input-field
        type="text"
        maxlength="2048"
        v-model:value="form.priorityInfo"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <h2 class="text-xl mt-4 mb-2 font-medium">
      <translation value="reservation.especials" />
    </h2>

    <div class="mt-2 mb-2">
      <label>
        <input
          type="checkbox"
          id="especial"
          :value="form.especial"
          v-model="form.especial"
          class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
          :disabled="loading"
        />
        <span class="ml-2">
          <translation value="reservation.especials" />
        </span>
      </label>
    </div>

    <div class="mt-2 mb-2" v-if="form.especial">
      <label>
        <input
          type="checkbox"
          id="entrada"
          :value="form.entrada"
          v-model="form.entrada"
          class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
          :disabled="loading"
        />
        <span class="ml-2">
          <translation value="reservation.entrada" />
        </span>
      </label>
    </div>

    <div v-if="form.especial && form.entrada">
      <div>
        <translation value="reservation.flynumber" />:
        <span class="text-gray-500 text-sm"
          >(<translation value="required" />)</span
        >
      </div>
      <htc-input-field
        type="text"
        maxlength="2048"
        ref="flynumber"
        id="flynumber"
        v-model:value="form.flynumber"
        class="w-full disabled:bg-gray-200"
        required="required"
      />
    </div>

    <div v-if="form.especial && form.entrada">
      <div>
        <translation value="reservation.fullname" />:
        <span class="text-gray-500 text-sm"
          >(<translation value="required" />)</span
        >
      </div>
      <htc-input-field
        type="text"
        maxlength="2048"
        ref="fullname"
        id="fullname"
        v-model:value="form.fullname"
        class="w-full disabled:bg-gray-200"
        required="required"
      />
    </div>

    <div v-if="form.especial && form.entrada">
      <div>
        <translation value="reservation.paxn" />:
        <span class="text-gray-500 text-sm"
          >(<translation value="required" />)</span
        >
      </div>
      <htc-input-field
        type="number"
        maxlength="2048"
        ref="paxn"
        id="paxn"
        v-model:value="form.paxn"
        class="w-full disabled:bg-gray-200"
        required="required"
      />
    </div>

    <div v-if="form.especial && form.entrada">
      <div>
        <translation value="reservation.paxphone" />:
        <span class="text-gray-500 text-sm"
          >(<translation value="required" />)</span
        >
      </div>

      <htc-input-field
        type="text"
        maxlength="2048"
        ref="paxphone"
        id="paxphone"
        v-model:value="form.paxphone"
        class="w-full disabled:bg-gray-200"
        required="required"
      />
    </div>

    <div class="mt-2 mb-2" v-if="form.especial && form.entrada">
      <label>
        <input
          type="checkbox"
          id="especial"
          :value="form.luggage"
          v-model="form.luggage"
          class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
          :disabled="loading"
        />
        <span class="ml-2">
          <translation value="reservation.luggage" />
        </span>
      </label>
    </div>

    <div v-if="form.especial">
      <div><translation value="reservation.waiting" />:</div>
      <div class="row">
        <div class="form-group inline-flex">
          <htc-input-field
            type="number"
            min="0"
            max="600"
            step="0.5"
            v-model:value="form.waiting_number"
            class="w-50 disabled:bg-gray-200"
          />
          <span class="ml-2 mr-2"> Info: </span>
          <htc-input-field
            type="text"
            maxlength="2048"
            v-model:value="form.waiting"
            class="w-full disabled:bg-gray-200"
            style="min-width: -webkit-fill-available"
          />
        </div>
      </div>
    </div>

    <div v-if="form.especial">
      <div><translation value="reservation.bikes" />:</div>
      <div class="row">
        <div class="form-group inline-flex">
          <htc-input-field
            type="number"
            min="0"
            max="600"
            v-model:value="form.bikes_number"
            class="w-50 disabled:bg-gray-200"
          />
          <span class="ml-2 mr-2"> Info: </span>
          <htc-input-field
            type="text"
            maxlength="2048"
            v-model:value="form.bikes"
            class="w-full disabled:bg-gray-200"
            style="min-width: -webkit-fill-available"
          />
        </div>
      </div>
    </div>

    <div v-if="form.especial">
      <div><translation value="reservation.animals" />:</div>
      <div class="row">
        <div class="form-group inline-flex">
          <htc-input-field
            type="number"
            min="0"
            max="600"
            v-model:value="form.animals_number"
            class="w-50 disabled:bg-gray-200"
          />
          <span class="ml-2 mr-2"> Info: </span>
          <htc-input-field
            type="text"
            maxlength="2048"
            v-model:value="form.animals"
            class="w-full disabled:bg-gray-200"
            style="min-width: -webkit-fill-available"
          />
        </div>
      </div>
    </div>

    <div v-if="form.especial">
      <div><translation value="reservation.babychair" />:</div>
      <div class="row">
        <div class="form-group inline-flex">
          <htc-input-field
            type="number"
            min="0"
            max="600"
            v-model:value="form.babychair_number"
            class="w-50 disabled:bg-gray-200"
          />
          <span class="ml-2 mr-2"> Info: </span>
          <htc-input-field
            type="text"
            maxlength="2048"
            v-model:value="form.babychair"
            class="w-full disabled:bg-gray-200"
            style="min-width: -webkit-fill-available"
          />
        </div>
      </div>
    </div>

    <div v-if="form.especial">
      <div><translation value="reservation.others" />:</div>
      <htc-input-field
        type="text"
        maxlength="2048"
        v-model:value="form.others"
        class="w-full disabled:bg-gray-200"
      />
    </div>

    <div class="mt-2 flex justify-between">
      <div class="flex flex-col gap-2 items-start justify-end">
        <div>
          <keyboard-helper>Ctrl</keyboard-helper> +
          <keyboard-helper>S</keyboard-helper>
          <span class="ml-1 text-slate-800 text-sm py-1">
            <translation value="button.tooltip.save" />
          </span>
        </div>
        <div>
          <keyboard-helper>Tab</keyboard-helper>
          <span class="ml-1 text-slate-800 text-sm py-1">
            <translation value="controls.tabForm" />
          </span>
        </div>
        <div>
          <keyboard-helper>Shift</keyboard-helper> +
          <keyboard-helper>Tab</keyboard-helper>
          <span class="ml-1 text-slate-800 text-sm py-1">
            <translation value="controls.shiftTabForm" />
          </span>
        </div>
      </div>
      <div class="flex flex-col gap-3 items-end">
        <div class="flex items-center gap-1">
          <span><translation value="reservation.createmsg.create" /></span>
          <div class="inline-flex shadow-sm mb-2" role="group">
            <button
              v-for="idx in limitServicesToCreate"
              :key="idx"
              @click="servicesToCreate = idx"
              type="button"
              :class="[
                servicesToCreate == idx
                  ? 'bg-blue-700 text-white border-blue-700'
                  : 'text-gray-900 bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700',
                idx === minServicesToCreate ? 'rounded-l-lg' : '',
                idx === limitServicesToCreate ? 'rounded-r-lg' : '',
              ]"
              class="px-3 py-1.5 text-sm font-medium border rounded-s-lg"
            >
              {{ idx }}
            </button>
          </div>
          <span><translation value="reservation.createmsg.services" /></span>
        </div>
        <div class="flex items-center gap-3">
          <htc-button
            is="button"
            color="green"
            :disabled="sending"
            :class="[sending && 'animate-pulse']"
            class="text-xl px-9 py-8 mr-1 disabled:bg-transparent disabled:border-gray-600 disabled:text-gray-600"
            @click="validateForm()"
          >
            <translation value="button.save-close" />
          </htc-button>
          <htc-button
            is="button"
            color="green"
            :disabled="sending"
            :class="[sending && 'animate-pulse']"
            class="text-2xl px-16 py-8 disabled:bg-transparent disabled:border-gray-600 disabled:text-gray-600"
            @click="validateForm(false)"
          >
            <translation value="button.save" />
          </htc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BookmarkIcon,
  LocationMarkerIcon,
  XIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/vue/outline";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import KeyboardHelper from "@/components/KeyboardHelper.vue";
import Translation from "@/translations/Translation.vue";
import Translate from "@/translations/Translate";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";
import { mapState } from "vuex";
import Modal from "@/modals/Modal";
import L from "leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import moment from "moment";

const focus = {
  mounted: (el) => el.focus(),
};

export default {
  name: "ReservaComponent",
  components: {
    BookmarkIcon,
    LocationMarkerIcon,
    XIcon,
    EyeIcon,
    EyeOffIcon,
    HtcInputField,
    HtcButton,
    Translation,
    KeyboardHelper,
  },
  directives: {
    // enables v-focus in template
    focus,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  props: {
    modal: {},
    pickupLatLng: {},
    reservaForm: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["service:created"],
  data() {
    const formBlank = {
      pickupAt: null,
      pickupLat: this.pickupLatLng?.latitude,
      pickupLng: this.pickupLatLng?.longitude,
      pickupName: "",
      pickupInfo: null,
      dropoffInfo: null,
      location: {
        lat: 0,
        lng: 0,
      },
      locationDrop: {
        lat: 0,
        lng: 0,
      },
      dropoffLat: null,
      dropoffLng: null,
      dropoffName: "",

      people: 4,
      message: null,

      name: null, // + Math.random(),
      room: null,
      phone: null, // + Math.random(),
      flynumber: null,
      fullname: null,
      paxn: 4,
      paxphone: null,
      luggage: 0,
      waiting: null,
      waiting_number: 0,
      bikes: null,
      bikes_number: 0,
      animals: null,
      animals_number: 0,
      babychair: null,
      babychair_number: 0,
      others: null,
      entrada: false,
      especial: false,
      priority: false,
      priorityInfo: null,
    };

    return {
      sending: false,
      allow_send: false,
      loading: false,
      allowDate: false,
      servicesToCreate: 1,
      minServicesToCreate: 1,
      limitServicesToCreate: 10,
      places: [],
      popularPlaces: {},

      formBlank,

      form: { ...formBlank },

      priority: false,
      especial: false,
      entrada: false,

      results: [],
      resultsDrop: [],
      showResults: true,
      showResultsDrop: true,
      showMap: true,
      showMapDrop: true,
      lat: 39.6137,
      lon: 2.9787,
      map: null,
      mapDrop: null,
      marker: null,
      markerDrop: null,
      zoom: 8,
      zoomSelected: 16,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.allow_send = true;
      },
    },
  },
  created() {
    this.loadPlaces();
    this.loadPopularPlaces();
  },
  computed: {
    ...mapState({
      mapForm: (state) => state.mapForm,
    }),

    validated() {
      return (
        this.pickupName &&
        this.form.name &&
        this.form.phone &&
        ["1", "2", "3", "4"].includes(this.form.people)
      );
    },

    pickupName: {
      get() {
        if (this.form?.pickupName) {
          return this.form.pickupName;
        }

        if (this.form?.pickupLat && this.form?.pickupLng) {
          return this.form.pickupLat + "," + this.form.pickupLng;
        }

        return null;
      },

      set(pickupName) {
        this.form.pickupLat = null;
        this.form.pickupLng = null;
        this.form.pickupName = pickupName;
      },
    },

    dropoffName: {
      get() {
        if (this.form?.dropoffName) {
          return this.form.dropoffName;
        }

        if (this.form?.dropoffLat && this.form?.dropoffLng) {
          return this.form.dropoffLat + ", " + this.form.dropoffLng;
        }

        return null;
      },

      set(dropoffName) {
        this.form.dropoffLat = null;
        this.form.dropoffLng = null;
        this.form.dropoffName = dropoffName;
      },
    },
  },
  methods: {
    validateForm(close = true) {
      /* eslint-disable */
      if (!this.sending) {
        this.sending = true;
        if (!this.form.pickupName && !this.form.location.lat && !this.form.location.lng) {
          this.$refs.pickupName.$el.focus();
          this.toast.error(Translate.value("reservationError.pickup").text);
          this.sending = false;
          return false;
        }

        if (!this.form.dropoffName && !this.form.locationDrop.lat && !this.form.locationDrop.lng) {
          this.$refs.dropoffName.$el.focus();
          this.toast.error(Translate.value("reservationError.dropoff").text);
          this.sending = false;
          return false;
        }

        if (!this.form.people || this.form.people == "") {
          this.$refs.people.$el.focus();
          this.toast.error(Translate.value("reservationError.people").text);
          this.sending = false;
          return false;
        }

        if (this.form.entrada) {
          if (!this.form.flynumber || this.form.flynumber == "") {
            this.$refs.flynumber.$el.focus();
            this.toast.error(Translate.value("reservationError.flynumber").text);
            this.sending = false;
            return false;
          }

          if (!this.form.fullname || this.form.fullname == "") {
            this.$refs.fullname.$el.focus();
            this.toast.error(Translate.value("reservationError.fullname").text);
            this.sending = false;
            return false;
          }

          if (!this.form.paxn || this.form.paxn == "") {
            this.$refs.paxn.$el.focus();
            this.toast.error(Translate.value("reservationError.paxn").text);
            this.sending = false;
            return false;
          }

          if (!this.form.paxphone || this.form.paxphone == "") {
            this.$refs.paxphone.$el.focus();
            this.toast.error(Translate.value("reservationError.paxphone").text);
            this.sending = false;
            return false;
          }
        }

        this.submit(close);
      }
      /* eslint-enable */
    },

    submit(close = true) {
      this.sending = true;

      this.form.dropoffLat = parseFloat(this.form.locationDrop.lat);
      this.form.dropoffLng = parseFloat(this.form.locationDrop.lng);
      this.form.pickupLat = parseFloat(this.form.location.lat);
      this.form.pickupLng = parseFloat(this.form.location.lng);

      this.form.waiting_number = parseFloat(this.form.waiting_number);
      this.form.animals_number = parseInt(this.form.animals_number);
      this.form.babychair_number = parseInt(this.form.babychair_number);
      this.form.bikes_number = parseInt(this.form.bikes_number);

      this.form.priority = this.form.priority ? true : false;
      this.form.especial = this.form.especial ? true : false;
      this.form.entrada = this.form.entrada ? true : false;
      this.form.luggage = this.form.luggage ? true : false;
      this.form.times = this.servicesToCreate;

      api
        .post("/services", this.form)
        .then(() => {
          // this.allow_send = false;
          let successMessage =
            this.servicesToCreate > 1
              ? Translate.value("reservation.successmsg.multiple").text
              : Translate.value("reservation.successmsg.one").text;

          this.toast.success(successMessage);
          this.clearFields();
          if (close) {
            this.$emit("service:created");
          } else {
            this.$refs.pickupName.$el.focus();
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errors?.geocode) {
            this.toast.error("No s'ha pogut resoldre l'adreça de recollida.");
          } else if (error?.response?.data?.errors?.geocode2) {
            this.toast.error("No s'ha pogut resoldre l'adreça de destinació.");
          } else {
            this.toast.error("No s'ha pogut crear la reserva.");
          }
          this.sending = false;
        })
        .finally(() => {
          this.sending = false;
        });
    },

    clearFields() {
      this.form = { ...this.formBlank };
      this.showMap = false;
      this.showMapDrop = false;
      this.form.location.lat = null;
      this.form.locationDrop.lat = null;
      this.allowDate = false;
      this.servicesToCreate = 1;
      this.results = [];
      this.resultsDrop = [];
      this.showResults = true;
      this.showResultsDrop = true;
    },

    setDate() {
      if (!this.allowDate) {
        var date = new Date();
        this.form.pickupAt = moment(date).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.form.pickupAt = null;
      }
    },

    loadPlaces() {
      api
        .get("/places")
        .then((response) => {
          this.places = response.data.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
        })
        .catch(() => {});
    },

    loadPopularPlaces() {
      api
        .get("/popular-places")
        .then((response) => {
          this.popularPlaces = response.data;
        })
        .catch(() => {});
    },

    selectPlace(num) {
      Modal.openPlacesSelector({ places: this.places })
        .then(({ place }) => {
          this.selectResultPlace(num, place);
        })
        .catch(() => {});
    },

    clearPickup() {
      this.pickupName = "";
      this.form.location.lat = null;
      this.form.location.lng = null;
      this.showMap = false;
      this.results = [];
      this.showResults = true;
    },

    clearDropoff() {
      this.dropoffName = "";
      this.form.locationDrop.lat = null;
      this.form.locationDrop.lng = null;
      this.showMapDrop = false;
      this.resultsDrop = [];
      this.showResultsDrop = true;
    },

    setResult(coor) {
      if (this.loading == false) {
        var url = `https://nominatim.limit.es/search?q=${coor}`;

        api.get(url).then((response) => {
          this.results = response.data;
          this.selectResult(this.results[0]);
        });
      }
    },

    search() {
      if (this.pickupName !== "" && this.loading == false) {
        var url = `https://nominatim.limit.es/search?q=${this.pickupName}`;

        api.get(url).then((response) => {
          this.results = response.data;
          this.showResults = true;
        });
      } else {
        this.showResults = false;
      }
    },

    selectResult(result) {
      if (this.marker) this.map.removeLayer(this.marker);

      this.pickupName = result.display_name;
      this.form.address = result.display_name;
      this.showResults = false;
      this.$refs.pickupInfoInput.$el.focus();
      //this.showMap = true;
      this.lat = result.lat;
      this.lon = result.lon;
      this.form.location.lat = result.lat;
      this.form.location.lng = result.lon;

      this.map.setView([this.lat, this.lon], this.zoomSelected);

      this.marker = L.marker([this.lat, this.lon], { draggable: true }).addTo(
        this.map,
      );
      this.marker.on("dragend", (e) => {
        this.form.location = e.target.getLatLng();
      });
      // this.marker.bindPopup(this.pickupName).openPopup();
    },
    selectResultPlace(num, place) {
      if (place) {
        if (num === 0) {
          this.form.pickupName = place.name;
          this.pickupName = place.name;
          this.form.pickupLat = place.latitude;
          this.form.pickupLng = place.longitude;
          this.form.location.lat = place.latitude;
          this.form.location.lng = place.longitude;
          this.lat = place.latitude;
          this.lng = place.longitude;
          this.showResults = false;
          this.$refs.pickupInfoInput.$el.focus();
          this.setMarker();
        } else if (num === 1) {
          this.form.dropoffName = place.name;
          this.dropoffName = place.name;
          this.form.dropoffLat = place.latitude;
          this.form.dropoffLng = place.longitude;
          this.form.locationDrop.lat = place.latitude;
          this.form.locationDrop.lng = place.longitude;
          this.lat = place.latitude;
          this.lng = place.longitude;
          this.showResultsDrop = false;
          this.$refs.dropoffInfoInput.$el.focus();
          this.setMarkerDrop();
        }
      }
    },
    setMarker() {
      if (this.marker) this.map.removeLayer(this.marker);
      this.map.setView(
        [this.form.location.lat, this.form.location.lng],
        this.zoomSelected,
      );

      this.marker = L.marker([this.form.location.lat, this.form.location.lng], {
        draggable: true,
      }).addTo(this.map);
      this.marker.on("dragend", (e) => {
        this.form.location = e.target.getLatLng();
      });
      // this.marker.bindPopup(this.pickupName).openPopup();
    },
    initMap() {
      this.map = L.map("mapPickup").setView([this.lat, this.lon], this.zoom);

      // Añadir la capa de OpenStreetMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(this.map);

      this.showMap = false;
    },
    //DropMap
    searchDrop() {
      if (this.dropoffName !== "" && this.loading == false) {
        var url = `https://nominatim.limit.es/search?q=${this.dropoffName}`;
        api.get(url).then((response) => {
          this.resultsDrop = response.data;
          this.showResultsDrop = true;
        });
      } else {
        this.showResultsDrop = false;
      }
    },
    selectResultDrop(result) {
      if (this.markerDrop) this.mapDrop.removeLayer(this.markerDrop);
      this.dropoffName = result.display_name;
      this.form.address = result.display_name;
      this.showResultsDrop = false;
      this.$refs.dropoffInfoInput.$el.focus();
      //this.showMapDrop = true;
      this.lat = result.lat;
      this.lon = result.lon;
      this.form.locationDrop.lat = result.lat;
      this.form.locationDrop.lng = result.lon;

      this.mapDrop.setView([this.lat, this.lon], this.zoomSelected);

      this.markerDrop = L.marker([this.lat, this.lon], {
        draggable: true,
      }).addTo(this.mapDrop);
      this.markerDrop.on("dragend", (e) => {
        this.form.locationDrop = e.target.getLatLng();
      });
      // this.markerDrop.bindPopup(this.dropoffName).openPopup();
    },
    setMarkerDrop() {
      if (this.markerDrop) this.mapDrop.removeLayer(this.markerDrop);
      //this.showMapDrop = true;
      this.mapDrop.setView(
        [this.form.locationDrop.lat, this.form.locationDrop.lng],
        this.zoomSelected,
      );

      this.markerDrop = L.marker(
        [this.form.locationDrop.lat, this.form.locationDrop.lng],
        {
          draggable: true,
        },
      ).addTo(this.mapDrop);
      this.markerDrop.on("dragend", (e) => {
        this.form.locationDrop = e.target.getLatLng();
      });
      // this.markerDrop.bindPopup(this.dropoffName).openPopup();
    },
    initMapDrop() {
      this.mapDrop = L.map("mapDrop").setView([this.lat, this.lon], this.zoom);

      // Añadir la capa de OpenStreetMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        maxZoom: 18,
      }).addTo(this.mapDrop);

      this.showMapDrop = false;
    },
  },
  mounted() {
    this.initMap();
    this.initMapDrop();

    if (this.reservaForm) {
      this.setDate();
      this.allowDate = true;
    }

    if (this.form?.pickupLat && this.form?.pickupLng) {
      //return this.form.pickupLat + ", " + this.form.pickupLng;
      this.setResult(this.form.pickupLat + "," + this.form.pickupLng);
    }
  },
};
</script>
<style>
#mapPickup {
  height: 400px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 10;
}
#mapDrop {
  height: 400px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 10;
}
</style>
