<template>
  <layout-main>
    <div class="my-5 mx-auto w-11/12 max-w-6xl">
      <h1 class="text-center text-2xl">Usuaris</h1>

      <div class="py-4 px-1 overflow-auto">
        <users-table />
      </div>
    </div>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import UsersTable from "./components/UsersTable.vue";

export default {
  name: "UsersIndex",
  components: {
    LayoutMain,
    UsersTable,
  },
};
</script>
