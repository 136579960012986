<template>
  <button
    class="z-10 absolute top-2 left-2 flex items-center gap-1 text-gray-700 bg-white pl-2 pr-3 py-1 rounded-lg font-semibold shadow"
    @click="open"
  >
    <DocumentAddIcon class="w-6 h-6" />

    <translation value="generic.service" />
  </button>
</template>

<script>
import { DocumentAddIcon } from "@heroicons/vue/outline";
import Modal from "@/modals/Modal";
import { useToast } from "vue-toastification";
import Translation from "@/translations/Translation.vue";

export default {
  name: "MapNewService",
  components: {
    DocumentAddIcon,
    Translation,
  },
  props: ["map", "mapConfig"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  methods: {
    open() {
      Modal.openServiceNewModal()
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>
