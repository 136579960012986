<template>
  <div
    class="absolute bg-gray-200/80 rounded-md w-[300px] z-10 top-16 right-2 select-none"
  >
    <div class="bg-white p-2 rounded-t-lg flex direction-row justify-between">
      <h3 class="font-bold">
        <translation value="nav.parades" />
      </h3>
      <button @click="toggle">
        <ChevronUpIcon v-if="open" class="w-4 mx-1" />
        <ChevronDownIcon v-else class="w-4 mx-1" />
      </button>
    </div>
    <div
      class="w-full py-2"
      v-for="(zone, i) in getZones()"
      v-bind:key="i"
      :style="{ display: open ? 'block' : 'none' }"
    >
      <div class="w-full flex direction-row bg-white">
        <div class="w-full flex direction-row">
          <i
            class="w-1"
            :style="{
              background: zone.color,
            }"
          />
          <span class="px-2">{{ zone.code }}</span>
        </div>
        <button @click="toggleZone(zone.id)">
          <ChevronDownIcon v-if="zonesClosed[zone.id]" class="w-4 mx-1" />
          <ChevronUpIcon v-else class="w-4 mx-1" />
        </button>
      </div>
      <div>
        <div
          class="w-full flex direction-row"
          v-for="(station, s) in zoneStations(zone.id)"
          v-bind:key="s"
          :style="{
            display: zonesClosed[zone.id] ? 'none' : 'flex',
            background: station.stnext ? '#d5ffb8' : 'none',
          }"
        >
          <i
            class="w-1"
            :style="{
              background: zone.color,
            }"
          />
          <i
            class="w-1"
            :style="{
              background: station.color,
            }"
          />
          <div class="w-full flex direction-row justify-between">
            <span class="pl-4">
              {{ station.code }} ({{ numTaxisStation(station.id) }})
            </span>
            <button
              class="bg-white rounded border m-1"
              @click="setNextStation(station.id)"
            >
              <LightningBoltIcon class="w-4 mx-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LightningBoltIcon,
} from "@heroicons/vue/outline";
import Translation from "@/translations/Translation";
import { api } from "@/boot/axios";

export default {
  name: "MapStations",
  components: {
    Translation,
    ChevronDoubleRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    LightningBoltIcon,
  },
  props: ["stations", "stationTaxis", "zones"],
  emits: [],
  data() {
    return {
      open: true,
      zonesClosed: {},
    };
  },
  methods: {
    zoneStations(zoneId) {
      console.log(this.stations);
      return Object.values(this.stations)
        .filter((station) => station.zoneId == zoneId && station.enabled)
        .sort((a, b) => a.storder - b.storder);
    },
    getZones() {
      return Object.values(this.zones)
        .filter((z) => this.zoneStations(z.id).length > 0)
        .sort((a, b) => (a.code < b.code ? -1 : 1));
    },
    numTaxisStation(stationId) {
      return Object.values(this.stationTaxis).filter(
        (st) => st.stationId == stationId,
      ).length;
    },
    toggle() {
      console.log(this.stationTaxis);
      this.open = !this.open;
    },
    toggleZone(zoneId) {
      this.zonesClosed[zoneId] = !this.zonesClosed[zoneId];
    },
    setNextStation(stationId) {
      api
        .put(`stations/next/${stationId}`)
        .then((r) => {})
        .catch((e) => {});
    },
  },
  computed: {},
  mounted() {},
};
</script>
