import { createStore } from "vuex";
import router from "@/router";
import { api } from "@/boot/axios";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import Connection from "@/ws/Connection";
import Tracking from "@/tracking/Tracking";
import { KeepAwake } from "@capacitor-community/keep-awake";
import {
  OrientationType,
  ScreenOrientation,
} from "@robingenz/capacitor-screen-orientation";

let storage: Storage = localStorage;

if (process.env.NODE_ENV === "development") {
  if (Capacitor.getPlatform() === "web") {
    storage = sessionStorage;
  }
}

export { storage };

let statusBarHideInterval = null;

export default createStore({
  state: {
    version: process.env.VUE_APP_VERSION,
    lang: storage.getItem("lang") || "ca",
    sidebar: true,
    modals: [],
    serverTime: null,
    taxi: null,
    station: null,
    isAdmin: JSON.parse(storage.getItem("isAdmin")),
    isClient: JSON.parse(storage.getItem("isClient")),
    config: null,

    networkState: "offline",

    token: null,
    user: null,

    serviceTaxi: null,
    serviceTaxiRe: null,

    driver: {
      fullscreen: false,
      keepAwake: false,
      orientation: "auto",
    },

    mapConfig: {},
  },
  mutations: {
    token(state, payload) {
      if (payload) {
        state.token = payload;
        storage.setItem("token", payload);
        if (!api.defaults.headers.common) api.defaults.headers.common = {};
        api.defaults.headers.common["Authorization"] = "Bearer " + payload;
      } else {
        state.token = null;
        storage.removeItem("token");
        delete api.defaults.headers?.common?.["Authorization"];
      }
    },

    SET_SERVER_TIME(state, time) {
      state.serverTime = time;
    },

    lang(state, payload) {
      state.lang = payload;
      storage.setItem("lang", payload);
    },

    user(state, payload) {
      state.serverTime = payload.now;
      state.user = payload;

      if (payload?.type === "driver" && payload?.taxi) {
        state.taxi = payload.taxi;
      }
    },

    toggleSidebar(state, payload) {
      if (payload != null) {
        state.sidebar = !!payload;
      } else {
        state.sidebar = !state.sidebar;
      }
    },

    addModal(state, payload) {
      state.modals.push({
        ...payload,
      });
    },

    removeModal(state, id) {
      const index = state.modals.findIndex((modal) => modal.id === id);

      if (index > -1) {
        state.modals.splice(index, 1);
      }
    },

    taxi(state, payload) {
      state.serverTime = payload.now;
      state.taxi = payload;

      if (payload) {
        if (payload.state !== "offline") {
          if (!Tracking.watcherId) {
            Tracking.startWatcher();
          }
        } else {
          Tracking.stopWatcher();
        }
      } else {
        Tracking.stopWatcher();
      }
    },

    SET_STATION(state, station) {
      state.station = station;
    },

    isAdmin(state, payload) {
      state.isAdmin = !!payload;

      if (payload) {
        storage.setItem("isAdmin", JSON.stringify(!!payload));
      } else {
        storage.removeItem("isAdmin");
      }
    },

    isClient(state, payload) {
      state.isClient = !!payload;

      if (payload) {
        storage.setItem("isClient", JSON.stringify(!!payload));
      } else {
        storage.removeItem("isClient");
      }
    },

    networkState(state, payload) {
      state.networkState = payload;
    },

    addService(state, payload) {
      if (
        state.serviceTaxi?.assignment?.serviceId !=
          payload?.assignment?.serviceId &&
        ["accepted", "pickedup"].includes(state.serviceTaxi?.assignment?.status)
      ) {
        return;
      }

      if (payload?.assignment) {
        state.serverTime = payload?.now;
        state.serviceTaxi = payload;
      }
    },

    deleteService(state, payload) {
      if (state.serviceTaxi?.service?.id === payload.assignment.serviceId) {
        state.serviceTaxi = null;
      }
    },

    addReassignment(state, payload) {
      state.serviceTaxiRe = null;
      state.serverTime = payload?.now;
      // Si ja tenc un servei assignat meu, només actualitza'l
      if (state.serviceTaxiRe?.assignment?.taxiId === state.taxi?.id) {
        if (
          state.serviceTaxiRe?.assignment?.serviceId ===
          payload.assignment.serviceId
        ) {
          state.serviceTaxiRe = payload.deleted ? null : payload;
        }

        return;
      }

      state.serviceTaxiRe = payload?.deleted ? null : payload;
    },

    addServiceSameStation(state, payload) {
      state.serverTime = payload.now;

      // Si ja tenc un servei assignat meu, no facis res
      if (
        (!!state.serviceTaxi?.assignment?.taxiId &&
          state.serviceTaxi?.assignment?.taxiId === state.taxi?.id) ||
        ["accepted", "pickedup"].includes(state.serviceTaxi?.assignment?.status)
      ) {
        return;
      }
      state.serviceTaxi = payload.deleted ? null : payload;
    },

    fullscreen(state, payload) {
      state.driver.fullscreen = !!payload;

      if (payload) {
        storage.setItem("fullscreen", "true");
      } else {
        storage.removeItem("fullscreen");
      }

      if (Capacitor.isNativePlatform()) {
        if (payload) {
          StatusBar.hide();
          StatusBar.setOverlaysWebView({ overlay: true });
          statusBarHideInterval = setInterval(async () => {
            const { visible } = await StatusBar.getInfo();
            if (visible) StatusBar.hide();
          }, 2000);
        } else {
          StatusBar.show();
          StatusBar.setOverlaysWebView({ overlay: false });
          clearInterval(statusBarHideInterval);
        }
      }
    },

    keepAwake(state, payload) {
      state.driver.keepAwake = !!payload;

      if (payload) {
        storage.setItem("keepAwake", "true");
      } else {
        storage.removeItem("keepAwake");
      }

      if (Capacitor.isNativePlatform()) {
        if (payload) {
          KeepAwake.keepAwake();
        } else {
          KeepAwake.allowSleep();
        }
      }
    },

    orientation(state, payload) {
      state.driver.orientation = payload || "auto";

      if (["portrait", "landscape"].includes(payload)) {
        storage.setItem("orientation", payload);
      } else {
        storage.removeItem("orientation");
      }

      if (Capacitor.isNativePlatform()) {
        if (payload === "portrait") {
          ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
        } else if (payload === "landscape") {
          ScreenOrientation.lock({ type: OrientationType.LANDSCAPE });
        } else {
          ScreenOrientation.unlock();
        }
      }
    },

    mapConfig(state, payload) {
      state.mapConfig = payload || {};
    },

    setConfig(state, config) {
      state.config = config;
    },
  },
  actions: {
    init({ commit, dispatch }) {
      const token = storage.getItem("token");

      if (token) {
        commit("token", token);
        dispatch("getUser");
        dispatch("getMapConfig");
        Connection.connect();

        commit("fullscreen", storage.getItem("fullscreen"));
        commit("keepAwake", storage.getItem("keepAwake"));
        commit("orientation", storage.getItem("orientation"));
      }
    },

    getUser({ commit }) {
      api
        .get("/auth")
        .then((response) => {
          const isAdmin = response.data?.type?.endsWith("admin");
          const isClient = response.data.user?.type?.endsWith("client");

          if (process.env.NODE_ENV !== "development") {
            if (
              (isAdmin && Capacitor.getPlatform() !== "web") ||
              (!isAdmin && Capacitor.getPlatform() === "web")
            ) {
              // this.toast.error("Usuari incompatible.");
              commit("token", null);
              commit("user", null);
              commit("isAdmin", null);
              commit("isClient", null);
              router.replace({ name: "login" });
              return;
            }
          }

          commit("user", response.data);
          commit("isAdmin", isAdmin);
          commit("isClient", isClient);
        })
        .catch(() => {});
    },

    getMapConfig({ commit }) {
      api
        .get("/map")
        .then((response) => {
          commit("mapConfig", response.data || {});
        })
        .catch(() => {});

      api
        .get("/auth")
        .then((response) => {
          const isAdmin = response.data?.type?.endsWith("admin");
          const isClient = response.data.user?.type?.endsWith("client");

          if (process.env.NODE_ENV !== "development") {
            if (
              (isAdmin && Capacitor.getPlatform() !== "web") ||
              (!isAdmin && Capacitor.getPlatform() === "web")
            ) {
              // this.toast.error("Usuari incompatible.");
              commit("token", null);
              commit("user", null);
              commit("isAdmin", null);
              commit("isClient", null);
              router.replace({ name: "login" });
              return;
            }
          }

          commit("user", response.data);
          commit("isAdmin", isAdmin);
          commit("isClient", isClient);
        })
        .catch(() => {});
    },

    pickup() {
      Connection.send({
        type: "Service",
        action: "DriverAction",
        payload: "Pickup",
      });
    },

    dropoff() {
      //this.state.serviceTaxi = null;
      //this.state.serviceTaxiRe = null;
      return Connection.send({
        type: "Service",
        action: "DriverAction",
        payload: "DropOff",
      });
    },
  },
  getters: {
    isOnline(state) {
      return state.networkState === "online";
    },

    isOffline(state) {
      return state.networkState === "offline";
    },
  },
});
