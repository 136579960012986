<template>
  <div class="w-[95vw] max-w-5xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">Reserva Taxi</h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <keyboard-helper class="mr-1">Esc</keyboard-helper>
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>

    <reserva-component
      :pickupLatLng="modal.payload?.pickupLatLng"
      :reservaForm="modal.payload?.reservaForm"
      @service:created="serviceCreated"
    />
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import KeyboardHelper from "@/components/KeyboardHelper.vue";
import ReservaComponent from "@/components/ReservaComponent.vue";

export default {
  name: "ServiceNewModal",
  components: {
    XIcon,
    ReservaComponent,
    KeyboardHelper,
  },
  props: ["modal"],
  methods: {
    serviceCreated() {
      this.modal.resolve();
    },
  },
};
</script>
