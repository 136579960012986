import store from "@/store";
import { MsgDriver } from "../../../backend/src/ws/msgtypes/driver";

export default class Driver {
  static async handle(msg: MsgDriver) {
    if (msg.type !== "Driver") return;

    if (msg.action === "Taxi") {
      store.commit("taxi", msg.taxi);
    }
  }
}
