import { api } from "@/boot/axios";

export default class Destination {
  static async create(
    serviceId,
    taxiId,
    zoneId,
    sectorId,
    pickupLat,
    pickupLng,
    dropoffLat,
    dropoffLng,
  ) {
    return new Promise(async (resolve) => {
      api
        .put("/services/" + serviceId, {
          taxiId: taxiId,
          zoneId: zoneId,
          sectorId: sectorId,
          manual: true,
          pickupLat: pickupLat,
          pickupLng: pickupLng,
          dropoffLat: dropoffLat,
          dropoffLng: dropoffLng,
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }
}
