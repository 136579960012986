<template>
  <template>
    <map-taxi
      v-if="showTaxi"
      :map="map"
      :taxi="trip.taxi"
      :location="trip.taxiLocation"
      :serviceTaxi="trip.serviceTaxi"
      :stationTaxi="stationTaxi"
    />

    <map-flag
      v-if="
        trip.serviceTaxi?.assignment &&
        trip.serviceTaxi?.assignment.status === 'pickedup' &&
        trip.serviceTaxi?.service?.createdBy == this.id
      "
      :map="map"
      :serviceTaxi="trip.serviceTaxi"
      :filters="filters"
    />

    <map-person
      v-else-if="
        trip.serviceTaxi && trip.serviceTaxi?.service?.createdBy == this.id
      "
      :map="map"
      :service="trip.serviceTaxi.service"
      :serviceTaxi="trip.serviceTaxi"
      :filters="filters"
    />

    <map-route
      v-if="
        trip.route &&
        showTaxi &&
        trip.serviceTaxi?.assignment?.acceptedAt != null &&
        trip.serviceTaxi?.service?.createdBy == this.id
      "
      :map="map"
      :serviceTaxi="trip.serviceTaxi"
      :route="trip.route"
      :filters="filters"
    />
  </template>
</template>

<script>
import MapTaxi from "./MapTaxi.vue";
import MapFlag from "./MapFlag.vue";
import MapPerson from "./MapPerson.vue";
import MapRoute from "./MapRoute.vue";

export default {
  name: "MapTrip",
  components: {
    MapTaxi,
    MapFlag,
    MapPerson,
    MapRoute,
  },
  props: ["map", "trip", "filters", "stationTaxi"],
  data() {
    return {
      id: sessionStorage.userId,
    };
  },
  computed: {
    showTaxi() {
      if (this.filters.taxis) {
        if (this.filters.taxis.taxisOnline) {
          if (this.trip.taxi.state !== "offline") {
            return true;
          }
        }

        if (this.filters.taxis.taxisAvailable) {
          if (this.trip.taxi.state !== "offline") {
            if (
              !this.trip.serviceTaxi ||
              this.trip.serviceTaxi.assignment.status === "assigned"
            ) {
              return true;
            }
          }
        }

        if (this.filters.taxis.taxisInService) {
          if (this.trip.taxi.state !== "offline") {
            if (
              this.trip.serviceTaxi ||
              this.trip.serviceTaxi.assignment.status !== "assigned"
            ) {
              return true;
            }
          }
        }

        if (this.filters.taxis.taxisOffline) {
          if (this.trip.taxi.state === "offline") {
            return true;
          }
        }
      }

      return false;
    },
  },
};
</script>
