<template>
  <div class="w-full h-full flex flex-col justify-between text-2xl">
    <div
      class="px-2 py-1"
      v-if="status === 'accepted' && reassignment && timeLeft != '00:00'"
    >
      <h4 class="mb-1 font-semibold w-full">
        <translation
          v-if="isReassignmetPriority"
          value="txt.confirm-reassigment-priority"
        />
        <translation v-else value="txt.confirm-reassigment" />
      </h4>

      <div class="px-2 py-1">
        <div class="font-semibold flex justify-between">
          <translation value="services.waiting-time" />:
          <div>{{ waitTime }}</div>
        </div>
        <div v-if="timeLeft" class="flex justify-between font-semibold">
          <translation value="services.time-left" />:
          <div>{{ timeLeft }}</div>
        </div>
      </div>

      <div class="flex justify-between">
        <button
          class="w-1/2 h-24 flex items-center justify-center font-semibold bg-red-500 text-lg px-1 py-0"
          @click="decline"
        >
          <XIcon class="w-6 h-6" />
          <translation value="services.decline" />
        </button>

        <button
          class="w-1/2 h-24 flex items-center justify-center font-semibold bg-green-700 text-lg px-1 py-0"
          @click="accept"
        >
          <CheckIcon class="w-6 h-6" />
          <translation value="services.accept" />
        </button>
      </div>
    </div>

    <div class="px-2 py-1">
      <div class="shrink-0 w-full flex justify-between">
        <h2 class="mb-1 font-semibold w-1/2">
          <template v-if="status === 'accepted'">
            <translation value="services.phase.pickup" />
          </template>

          <template v-else-if="status === 'pickedup'">
            <translation value="services.phase.dropoff" />
          </template>
        </h2>

        <div class="shrink-0 w-1/2 text-right">
          <div v-if="service.name" class="font-semibold">
            <div class="truncate">{{ service.name }}</div>
          </div>

          <div v-if="service.phone" class="font-semibold">
            <a :href="'tel:' + service.phone" class="block truncate">
              {{ service.phone }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="service.message" class="shrink-0 w-full text-right text-xs p-2">
      {{
        service.message.length > 100
          ? service.message.substring(0, 100)
          : service.message
      }}
    </div>

    <div class="mt-auto">
      <div class="font-semibold">
        <span class="text-sm font-normal">
          <translation value="form.pickup" />:
        </span>
        <a :href="pickupUrl" target="_blank" class="block">
          {{ service.pickupName }}
          <BoxIcon name="map" />
        </a>
      </div>

      <div class="font-semibold">
        <span class="text-sm font-normal">
          <translation value="form.dropoff" />:
        </span>
        <a
          v-if="service.dropoffName"
          :href="dropoffUrl"
          target="_blank"
          class="block truncate"
        >
          {{ service.dropoffName }}
          <BoxIcon name="map" />
        </a>

        <div v-else class="truncate">Indefinida</div>
        <div class="w-full h-full text-center mt-2">
          <button
            class="w-[80vw] h-full items-center font-semibold px-4 py-2 bg-slate-400 rounded-md text-4xl"
          >
            <router-link :to="{ name: 'driver-destination-service' }">
              <translation value="button.destination" />
            </router-link>
          </button>
        </div>
      </div>
    </div>

    <ServiceTileButtons />
  </div>
</template>

<script>
import { ArrowsExpandIcon, CheckIcon, XIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";
import { mapState } from "vuex";
import BoxIcon from "@/components/BoxIcon";
import ServiceTileButtons from "@/views/Driver/Main/components/ServiceTileButtons";
import Connection from "@/ws/Connection";
import moment from "moment";
import store from "@/store";
import { NativeAudio } from "@capacitor-community/native-audio";

export default {
  name: "ServiceTileAccepted",
  components: {
    ServiceTileButtons,
    BoxIcon,
    ArrowsExpandIcon,
    CheckIcon,
    XIcon,
    Translation,
  },
  props: ["taxiConfig"],
  data() {
    return {
      timeLeft: null,
      waitTime: null,
      intervals: {
        timeLeft: null,
        waitTime: null,
      },
    };
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
      serviceTaxiRe: (state) => state.serviceTaxiRe,
    }),

    taxiState() {
      return this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },

    reassignment() {
      return this.serviceTaxiRe?.assignment;
    },

    isReassignmetPriority() {
      return this.serviceTaxiRe?.service?.priority;
    },

    hasAccepted() {
      return ["accepted", "pickedup", "droppedoff"].includes(this.status);
    },

    pickupUrl() {
      return `https://maps.google.com/?q=${this.service.pickupLat},${this.service.pickupLng}`;
    },

    dropoffUrl() {
      return `https://maps.google.com/?q=${this.service.dropoffLat},${this.service.dropoffLng}`;
    },
    tileClasses() {
      switch (this.status) {
        case "accepted":
          return ["bg-red-700"];
        case "pickedup":
          return {
            "bg-red-700": true,
            "text-white": true,
          };
      }

      return [];
    },
  },
  watch: {
    reassignment: {
      immediate: true,
      handler(reassignment) {
        if (reassignment) {
          if (this.intervals.timeLeft == null) {
            this.updateTimeLeft();
            this.updateWaitTime();

            this.intervals.timeLeft = setInterval(
              () => this.updateTimeLeft(),
              1000,
            );
            this.intervals.waitTime = setInterval(
              () => this.updateWaitTime(),
              1000,
            );
          }
        } else {
          if (this.timeLeft != null || this.intervals.timeLeft != null) {
            clearInterval(this.intervals.timeLeft);
            this.intervals.timeLeft = null;
            this.timeLeft = null;
          }
          if (this.waitTime != null || this.intervals.waitTime != null) {
            clearInterval(this.intervals.waitTime);
            this.intervals.waitTime = null;
            this.waitTime = null;
          }
        }
      },
    },
  },
  mounted() {},
  methods: {
    updateTimeLeft() {
      if (!this.reassignment) return;

      let diff =
        moment(this.reassignment.assignedExpiryAt).unix() -
        moment(this.serverTime).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      // S'executa cada 3 segons
      if (this.taxiConfig.sound == 1 && seconds > 0 && seconds % 3 == 0) {
        NativeAudio.play({
          assetId: "beep",
        });
      }

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      const beforeTime = this.timeLeft;
      this.timeLeft =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");

      if (
        this.reassignment &&
        this.timeLeft === "00:00" &&
        beforeTime !== "00:00"
      ) {
        this.decline();
      }
    },

    updateWaitTime() {
      if (!this.serviceTaxiRe) return;

      const timestamp =
        this.serviceTaxiRe.service.pickupAt ||
        this.serviceTaxiRe.service.createdAt;

      let diff = moment(this.serverTime).unix() - moment(timestamp).unix();

      if (diff < 0) diff = 0;

      let minutes = 0;
      let seconds = diff;

      while (seconds >= 60) {
        minutes += 1;
        seconds -= 60;
      }

      this.waitTime =
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    },

    destination() {
      console.log("destination");
    },

    decline() {
      this.timeLeft = "00:00";
      Connection.send({
        type: "Service",
        action: "DriverAction",
        payload: "Decline",
      });
      store.commit("addReassignment", null);
    },

    accept() {
      this.timeLeft = "00:00";
      Connection.send({
        type: "Service",
        action: "DriverAction",
        payload: "Accept",
      });
      store.commit("addReassignment", null);
    },
  },
};
</script>
<style scoped>
.accepted {
  background: lightgray;
}

.pickedup {
  background: #b91c1c;
}
</style>
