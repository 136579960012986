<template>
  <layout-main>
    <map-component :editable="true" />
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import MapComponent from "@/components/Map/MapComponent.vue";

export default {
  name: "MapIndex",
  components: {
    LayoutMain,
    MapComponent,
  },
};
</script>
