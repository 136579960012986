import { api } from "@/boot/axios";
import { Geolocation } from "@capacitor/geolocation";
import moment from "moment";

export default class Manual {
  static async create(
    taxiId,
    zoneId,
    sectorId,
    dropoffLat,
    dropoffLng,
    cantado = false,
  ) {
    return new Promise(async (resolve) => {
      const location = await Geolocation.getCurrentPosition();

      api
        .post("/services", {
          taxiId: taxiId,
          zoneId: zoneId,
          sectorId: sectorId,
          manual: true,
          cantado: cantado,
          acceptedAt: moment().format("YYYY-MM-DDTHH:mm"),
          pickedupAt: moment().format("YYYY-MM-DDTHH:mm"),
          pickupLat: location.coords.latitude,
          pickupLng: location.coords.longitude,
          dropoffLat: dropoffLat,
          dropoffLng: dropoffLng,
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }
}
