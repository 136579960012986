<template>
  <div class="flex-none h-12 px-2 flex items-center justify-start border-b">
    <button
      class="p-1 flex-none flex items-center justify-center gap-1 border border-gray-300 rounded hover:bg-gray-100"
      @click="toggleSidebar()"
    >
      <box-icon name="menu-alt-left" size="sm" />
      <span class="mr-1">
        <translation value="nav.menu" />
      </span>
    </button>
  </div>
</template>

<script>
import Translation from "@/translations/Translation.vue";
import { mapMutations } from "vuex";

export default {
  name: "ClientLayoutHeader",
  components: {
    Translation,
  },
  methods: {
    ...mapMutations(["toggleSidebar"]),
  },
};
</script>
