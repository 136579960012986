<template>
  <layout-main>
    <form class="my-5 mx-auto w-11/12 max-w-4xl" @submit.prevent="submit">
      <h1 class="text-center text-2xl">
        <template v-if="!editMode">
          <translation value="button.create" />
          {{ " " }}
          <translation value="generic.zone" :lowercase="true" />
        </template>

        <template v-else>
          <translation value="generic.zone" />: {{ original?.code }}
        </template>
      </h1>

      <div class="mt-10">
        <label>
          <div><translation value="generic.code" />:</div>
          <htc-input-field
            type="text"
            v-model:value="form.code"
            class="w-full disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label>
          <div><translation value="generic.area" />:</div>
          <select
            class="w-full focus:ring-blue-500 focus:border-blue-500 block px-3 py-1 border-gray-300 rounded-md"
            v-model="form.areaId"
            @change="onChangeArea"
          >
            <option v-for="area in areas" :key="area.id" :value="area.id">
              {{ area.code }}
            </option>
          </select>
        </label>

        <label>
          <div><translation value="generic.zonepriority" />:</div>
          <Multiselect
            v-model="form.alternatives"
            :object="true"
            :resolve-on-load="false"
            :options="zones"
            mode="tags"
            :close-on-select="false"
          />
        </label>

        <label>
          <div v-if="this.$route.params.id">
            <translation value="generic.taxistationpriority" />:
          </div>
          <Multiselect
            v-if="this.$route.params.id"
            v-model="form.taxistations"
            :object="true"
            :resolve-on-load="false"
            :options="stations"
            mode="tags"
            :close-on-select="false"
          />
        </label>

        <label>
          <div><translation value="Color" />:</div>
          <htc-input-field
            type="color"
            v-model:value="form.color"
            class="w-14 disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
        </label>

        <label class="mt-3 flex items-center gap-2">
          <input
            type="checkbox"
            v-model="form.enabled"
            class="accent-taxis-orange border-gray-300 rounded-sm shadow-sm focus:ring-0 focus:border-taxis-orange disabled:bg-gray-200 disabled:animate-pulse"
            :disabled="loading"
          />
          <span>
            <translation value="form.enabled" />
          </span>
        </label>
      </div>

      <div class="mt-4 flex justify-end">
        <htc-button
          is="button"
          type="submit"
          color="green"
          :disabled="loading"
          class="disabled:border disabled:border-gray-600 disabled:bg-transparent disabled:text-black disabled:animate-pulse"
        >
          <translation v-if="editMode" value="button.save" />
          <translation v-else value="button.create" />
        </htc-button>
      </div>
    </form>
  </layout-main>
</template>

<script>
import LayoutMain from "@/layout/LayoutMain.vue";
import HtcInputField from "@/components/HtcInputField.vue";
import HtcButton from "@/components/HtcButton.vue";
import Translation from "@/translations/Translation.vue";
import { useToast } from "vue-toastification";
import { api } from "@/boot/axios";
import Multiselect from "@vueform/multiselect";

export default {
  name: "ZonesEdit",
  components: {
    LayoutMain,
    HtcInputField,
    HtcButton,
    Translation,
    Multiselect,
  },
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      id: null,
      loading: true,
      editMode: false,
      zones: [],
      fullzones: [],
      stations: [],
      fullstations: [],
      areas: [],
      original: null,
      form: {
        code: "",
        color: "#3388ff",
        enabled: true,
        alternatives: [],
        taxistations: [],
      },
    };
  },
  watch: {
    "form.color"(value) {
      if (!value) {
        this.form.color = "#3388ff";
      }
    },
    "form.areaId"(value) {
      if (!value) {
        this.zones = [];
        this.form.alternatives = [];
        this.stations = [];
        this.form.taxistations = [];
      } else {
        this.loadAltZones();
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.editMode = true;
      this.loadAll();
      this.loadStations();
    } else {
      this.loadAreas();
    }
  },
  methods: {
    onChangeArea(event) {
      this.zones = [];
      this.form.alternatives = null;
    },
    loadAll() {
      Promise.all([
        api
          .get("/zones/" + this.id)
          .then((response) => {
            this.original = { ...response.data };
            response.data.alternatives = [];

            this.form = { ...response.data };

            this.original?.alternatives?.map((item) => {
              this.form.alternatives.push({
                value: item.value,
                label: item.label,
              });
            });
          })
          .catch(() => {
            this.$router.replace({ name: "zones-index" });
          }),
        api
          .get("/areas", {
            params: {
              page: 0,
              size: 100,
              sortCol: "code",
              sortDir: "asc",
            },
          })
          .then((response) => {
            this.areas = [...response.data.results];
          }),
      ]).then(() => {
        this.loading = false;
      });
    },

    loadAreas() {
      api
        .get("/areas", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.areas = [...response.data.results];
          this.loading = false;
        });
    },

    loadAltZones() {
      api
        .get("/zones", {
          params: {
            page: 0,
            size: 100,
            sortCol: "code",
            sortDir: "asc",
          },
        })
        .then((response) => {
          this.fullzones = [...response.data.results];

          let filtered = this.fullzones.filter(
            (filter) =>
              filter.areaId == this.form.areaId &&
              filter.id != this.id &&
              filter.enabled &&
              filter.polygon,
          );

          filtered.map((item) => {
            this.zones.push({ value: item.id, label: item.code });
          });
        });
    },

    async loadStations() {
      await api
        .get("/stations", {
          params: {
            size: 100,
          },
        })
        .then((response) => {
          this.fullstations = [...response.data.results];

          let filtered = this.fullstations.filter(
            (filter) =>
              filter.zoneId == this.form.id && filter.enabled && filter.polygon,
          );

          filtered.map((item) => {
            this.stations.push({ value: item.id, label: item.code });
          });
        });
    },

    submit() {
      if (this.editMode) {
        this.form.alternatives =
          this.form.alternatives == null ? undefined : this.form.alternatives;
        this.form.taxistations =
          this.form.taxistations == null ? undefined : this.form.taxistations;
        api
          .put("/zones/" + this.id, this.form)
          .then(() => {
            this.toast.success("Guardat correctament.");
            this.$router.replace({ name: "zones-index" });
          })
          .catch((e) => {
            this.toast.error("No s'ha pogut guardar.");
          });
      } else {
        this.form.alternatives =
          this.form.alternatives == null ? undefined : this.form.alternatives;
        this.form.taxistations =
          this.form.taxistations == null ? undefined : this.form.taxistations;
        api
          .post("/zones", this.form)
          .then(() => {
            this.toast.success("Creat correctament.");
            this.$router.replace({ name: "zones-index" });
          })
          .catch(() => {
            this.toast.error("No s'ha pogut crear.");
          });
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
