<template>
  <div
    :class="[
      'p-2 flex justify-between rounded-md shadow',
      isStnext ? 'bg-green-400' : 'bg-blue-300',
    ]"
  >
    <div class="w-full flex flex-col justify-between items-start">
      <div class="px-2 flex items-center justify-center text-xl font-extrabold">
        {{ station.code }}
      </div>

      <div
        class="mt-2 flex gap-x-2 gap-y-1 flex-wrap bg-white text-xl font-bold rounded justify-center"
      >
        <div
          v-for="(stationTaxi, taxiId) in stationTaxis"
          :key="taxiId"
          class="grid place-items-center px-2 py-1"
        >
          {{ stationTaxi.taxi.code }}
        </div>
      </div>
    </div>

    <!-- <div class="flex items-center">
      <button
        v-if="!isStationed"
        class="w-12 h-12 flex items-center justify-center bg-emerald-500 rounded-md"
        @click="join"
      >
        <LogoutIcon class="w-6 h-6" />
      </button>

      <button
        v-if="isStationedHere"
        class="w-12 h-12 flex items-center justify-center bg-red-500 rounded-md"
        @click="leave"
      >
        <LoginIcon class="w-6 h-6" />
      </button>
    </div> -->
  </div>
</template>

<script>
import { LoginIcon, LogoutIcon } from "@heroicons/vue/outline";
import InlineSvg from "vue-inline-svg";
import { mapState } from "vuex";
import Connection from "@/ws/Connection";

export default {
  name: "StationItem",
  components: {
    LoginIcon,
    LogoutIcon,
    InlineSvg,
  },
  props: ["station", "stationTaxis", "isStationed", "isStnext"],
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
    }),

    isStationedHere() {
      return this.stationTaxis[this.taxi.id] != null;
    },
  },
  methods: {
    join() {
      if (Connection.isReady()) {
        Connection.send({
          type: "Stations",
          action: "Join",
          stationId: this.station.id,
        });
      }
    },

    leave() {
      if (Connection.isReady()) {
        Connection.send({
          type: "Stations",
          action: "Leave",
        });
      }
    },
  },
};
</script>
