<template>
  <div class="space-y-4 min-w-min">
    <Datatable
      url="/taxislog"
      :columns="columns"
      :autorefresh="true"
      :initial-sort="{ col: 'createdAt', dir: 1 }"
      :pageSize="100"
      :actions-enabled="false"
      :filterEvent="openFilterModal"
      :filters="filters"
    />
  </div>
</template>

<script>
import Datatable from "@/components/Datatable/Datatable";
import Modal from "@/modals/Modal";
import TaxisLogFilter from "./TaxisLogFilter.vue";
import PositionMap from "./PositionMap.vue";

export default {
  name: "TaxisLogTable",
  components: {
    Datatable,
  },
  data() {
    return {
      search: "",
      filters: null,
      filterModalValues: null,
      columns: [
        {
          key: "serviceNumber",
          label: "generic.service",
        },
        {
          key: "createdAt",
          label: "services.date",
          customRender: (value) => {
            return this.formatDate(value.createdAt);
          },
        },
        {
          key: "createdAt",
          label: "services.time",
          customRender: (value) => {
            return this.formatTimeShort(value.createdAt);
          },
        },
        {
          key: "status",
          label: "services.status",
          customRender: (value) => {
            switch (value.status) {
              case "droppedoff":
              case "free":
                return "free";
            }

            return value.status;
          },
          translate: true,
        },
        {
          key: "driverName",
          label: "drivers.driver",
        },
        {
          key: "taxiCode",
          label: "generic.taxi",
        },
        {
          key: "stationCode",
          label: "generic.station",
        },
        {
          key: "zoneCode",
          label: "generic.zone",
        },
        {
          key: "areaCode",
          label: "generic.area",
        },
        {
          key: "latitude",
          label: "services.position",
          customRender: (value) => {
            return value.latitude && value.longitude
              ? `[${value.latitude}, ${value.longitude}]`
              : "";
          },
          clickEvent: (value) => {
            if (value.latitude && value.longitude) {
              Modal.open(PositionMap, [value.latitude, value.longitude]);
            }
          },
          customClass: "font-semibold cursor-pointer",
        },
      ],
    };
  },
  methods: {
    openFilterModal(row) {
      Modal.open(TaxisLogFilter, {
        row: row,
        modalValues: this.filterModalValues,
      }).then((data) => {
        this.filters = data.filters;
        this.filterModalValues = data.modalValues;
      });
    },
  },
};
</script>
