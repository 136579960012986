<template>
  <div class="rounded-md shadow" :style="{ color: color }">
    <div
      v-if="this.station.code == 'P10 - PORTA MURADA' && this.p10 == true"
      class="grid mt-3 parpadea"
    >
      <div class="row-start-7">
        <ExclamationIcon style="color: #b91c1c" />
      </div>
      <div class="row-start-6 text-black"><spna>x</spna></div>
      <div class="row-start-5 text-black"><spna>x</spna></div>
    </div>
    <div v-else class="flex flex-col-reverse">
      <template v-for="index in 7" :key="index">
        <div style="max-height: 12px">
          <span v-if="getCount() >= index">
            <MinusSmIcon class="w-6 h-6 stroke-[6px]" />
          </span>
          <span class="text-black" v-else>X</span>
        </div>
      </template>
    </div>
    <div class="">
      <div class="ml-1">
        {{ station.code.substr(1, 3) }}
      </div>
    </div>
    <div class="">
      <div class="ml-1">
        {{ services }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  LoginIcon,
  LogoutIcon,
  MinusSmIcon,
  ExclamationIcon,
} from "@heroicons/vue/outline";
import InlineSvg from "vue-inline-svg";
import { mapState } from "vuex";
import Connection from "@/ws/Connection";

export default {
  name: "StationItem",
  components: {
    LoginIcon,
    LogoutIcon,
    MinusSmIcon,
    ExclamationIcon,
    InlineSvg,
  },
  data() {
    return {
      count: 0,
    };
  },
  props: ["station", "stationTaxis", "isStationed", "color", "services", "p10"],
  mounted() {
    if (this.stationTaxis) {
      this.count = Object.values(this.stationTaxis).length;
    }
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
    }),

    isStationedHere() {
      return this.stationTaxis[this.taxi.id] != null;
    },
  },
  methods: {
    getCount() {
      this.count = Object.values(this.stationTaxis).length;
      return this.count;
    },
    join() {
      if (Connection.isReady()) {
        Connection.send({
          type: "Stations",
          action: "Join",
          stationId: this.station.id,
        });
      }
    },

    leave() {
      if (Connection.isReady()) {
        Connection.send({
          type: "Stations",
          action: "Leave",
        });
      }
    },
  },
};
</script>
<style>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
