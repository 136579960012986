<template>
  <template></template>
</template>

<script>
import Modal from "@/modals/Modal";
const L = window.L;

export default {
  name: "PlaceMarker",
  props: ["map", "place"],
  emits: ["update:place", "delete:place"],
  data() {
    return {
      marker: null,
    };
  },
  watch: {
    place: {
      handler(place) {
        if (!this.marker) return;
        this.marker.setTooltipContent(place.name);
        this.marker.setLatLng({
          lat: place.latitude,
          lng: place.longitude,
        });
      },
    },
  },
  created() {
    this.mapDraw();
  },
  beforeUnmount() {
    this.marker?.remove();
  },
  methods: {
    mapDraw() {
      const marker = L.marker([this.place.latitude, this.place.longitude], {
        alt: "place",
        riseOnHover: true,
        draggable: true,
        autoPan: true,
        bubblingMouseEvents: false,
      });

      marker.bindContextMenu({
        contextmenuItems: [
          {
            text: "Editar",
            index: 0,
            callback: this.edit,
          },
          {
            text: "Eliminar",
            index: 1,
            callback: this.del,
          },
          {
            index: 2,
            separator: true,
          },
        ],
      });

      marker.on("dragend", (event) => {
        const position = event.target.getLatLng();

        this.$emit("update:place", {
          ...this.place,
          latitude: position.lat,
          longitude: position.lng,
        });
      });

      const tooltip = L.tooltip({
        permanent: true,
      });

      tooltip.setContent(this.place.name);

      marker.bindTooltip(tooltip);

      marker.addTo(this.map);

      this.marker = marker;
    },

    edit() {
      Modal.openPlaceMarkerModal({ place: this.place })
        .then(({ place }) => {
          if (!place.name) return;
          this.$emit("update:place", place);
        })
        .catch(() => {});
    },

    del() {
      this.$emit("delete:place", this.place);
    },

    centerMap() {
      this.map.panTo({
        lat: this.place.latitude,
        lng: this.place.longitude,
      });
    },
  },
};
</script>
