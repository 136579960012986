<template>
  <div class="space-y-4 min-w-min">
    <Datatable
      url="/servicelog"
      :columns="columns"
      :autorefresh="true"
      :initial-sort="{ col: 'createdAt', dir: 1 }"
      :pageSize="100"
      :actions-enabled="false"
      :filterEvent="openFilterModal"
      :filters="filters"
    />
  </div>
</template>

<script>
import Datatable from "@/components/Datatable/Datatable";
import Modal from "@/modals/Modal";
import ServicesLogFilter from "./ServicesLogFilter.vue";
import PositionMap from "./PositionMap.vue";

export default {
  name: "ServicesLogTable",
  components: {
    Datatable,
  },
  data() {
    return {
      search: "",
      filters: null,
      filterModalValues: null,
      columns: [
        {
          key: "serviceNumber",
          label: "generic.service",
        },
        {
          key: "createdAt",
          label: "services.date",
          customRender: (value) => {
            return this.formatDate(value.createdAt);
          },
        },
        {
          key: "createdAt",
          label: "services.time",
          customRender: (value) => {
            return this.formatTimeShortSeconds(value.createdAt);
          },
        },
        {
          key: "status",
          label: "services.status",
          customRender: (value) => {
            console.log(value.status);
            switch (value.status) {
              case "ASSIGNED":
                return "assigned";
              case "REASSIGNED":
                return "reassigned";
              case "ACCEPTED":
                return "accepted";
              case "INPROGRESS":
                return "doing";
              case "FINALIZED":
                return "done";
            }

            return "waiting";
          },
          translate: true,
        },
        {
          key: "taxiCode",
          label: "generic.taxi",
        },
        {
          key: "level",
          label: "status.level",
        },
        {
          key: "dropoffName",
          label: "form.dropoff",
        },
        {
          key: "zoneCode",
          label: "generic.zone",
        },
        {
          key: "zoneAltCode",
          label: "generic.zoneAlt",
        },
        {
          key: "latitude",
          label: "services.position",
          customRender: (value) => {
            return value.latitude && value.longitud
              ? `[${value.latitude}, ${value.longitud}]`
              : "";
          },
          clickEvent: (value) => {
            if (value.latitude && value.longitud) {
              Modal.open(PositionMap, [value.latitude, value.longitud]);
            }
          },
          customClass: "font-semibold cursor-pointer",
        },
      ],
    };
  },
  methods: {
    openFilterModal(row) {
      Modal.open(ServicesLogFilter, {
        row: row,
        modalValues: this.filterModalValues,
      }).then((data) => {
        this.filters = data.filters;
        this.filterModalValues = data.modalValues;
      });
    },
  },
};
</script>
