<template>
  <div class="mt-3 w-full flex justify-between">
    <div v-if="status === 'accepted'" class="w-2/3">
      <button
        @click="pickup"
        class="w-full flex items-center justify-center h-24 gap-1 font-semibold text-3xl px-4 py-1 bg-green-200 rounded-md disabled:bg-transparent disabled:text-gray-500"
      >
        <UserIcon class="w-6 h-6" />
        <translation value="button.mark-pickup" />
      </button>
    </div>
    <div v-if="status === 'pickedup'" class="w-2/3">
      <button
        @click="dropoff"
        class="w-full flex items-center justify-center h-24 gap-1 font-semibold text-3xl px-4 py-1 bg-purple-600 rounded-md text-white"
      >
        <FlagIcon class="w-6 h-6" />
        <translation value="button.mark-dropoff" />
      </button>
    </div>
    <div class="w-1/3">
      <router-link
        :to="{ name: 'driver-services-current' }"
        class="w-full h-24 flex items-center justify-center font-semibold text-xl gap-1 bg-green-500"
      >
        <ArrowsExpandIcon class="w-6 h-6" />
        <translation value="DETALLS" />
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Connection from "@/ws/Connection";
import {
  ArrowsExpandIcon,
  ChevronLeftIcon,
  FlagIcon,
  UserIcon,
} from "@heroicons/vue/outline";
import Translation from "@/translations/Translation";

export default {
  name: "ServiceTileButtons",
  components: {
    ChevronLeftIcon,
    FlagIcon,
    UserIcon,
    ArrowsExpandIcon,
    Translation,
  },
  computed: {
    ...mapState({
      taxi: (state) => state.taxi,
      serviceTaxi: (state) => state.serviceTaxi,
    }),

    taxiState() {
      return this.taxi?.state;
    },

    service() {
      return this.serviceTaxi?.service;
    },

    assignment() {
      return this.serviceTaxi?.assignment;
    },

    status() {
      return this.serviceTaxi?.assignment?.status;
    },
  },
  methods: {
    ...mapActions(["pickup", "dropoff"]),
  },
};
</script>
