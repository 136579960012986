<template>
  <div class="w-[95vw] max-w-3xl bg-white p-4 rounded-lg flex flex-col">
    <div class="flex justify-between items-center">
      <div>
        <h2 class="font-semibold text-gray-700">
          <translation value="services.position" />
        </h2>
      </div>

      <button class="flex items-center" @click="modal.reject">
        <XIcon class="w-6 h-6 text-gray-500" />
      </button>
    </div>
    <div class="p-2 text-gray-700">
      <div class="w-full h-[300px]" ref="possitionMap"></div>
    </div>
  </div>
</template>

<script>
const L = window.L;
import { useToast } from "vue-toastification";
import { XIcon } from "@heroicons/vue/outline";
import Translation from "@/translations/Translation.vue";

export default {
  name: "PositionMap",
  components: {
    XIcon,
    Translation,
  },
  props: ["modal"],
  setup() {
    return {
      toast: useToast(),
    };
  },
  data() {
    return {
      coordenades: this.modal.payload,
      map: null,
    };
  },
  computed: {},
  mounted() {
    this.map = L.map(this.$refs.possitionMap, {
      center: this.coordenades,
      zoom: 20,
      // attributionControl: false,
      contextmenu: true,
      contextmenuWidth: 140,
    });
    this.map.getRenderer(this.map).options.padding = 10;
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
    }).addTo(this.map);
    L.circle(this.coordenades, {
      color: "#1c8760",
      fillColor: "#2ab885",
      fillOpacity: 1,
      radius: 2,
    }).addTo(this.map);
  },
  methods: {},
};
</script>
